import {
  CMSFieldType as FieldType,
  CMSFieldTextStyle as TextStyle,
  CMSSimpleTextFieldType as TextFieldType,
  CMSField,
} from '@interfaces/models/cms/campaign-hero';

/*
 * TODO: Unfortunately, SCSS doesn't permit direct use of var(--variables)
 * to modify hover & active states based on API text colors.
 * As a workaround, we calculate hover and active rgba values in JavaScript
 * and apply them using CSS custom variables (var(--variables)) in our SCSS.
 */

export const generateCMSFieldStyles = (fieldType: string, fieldStyle: TextStyle, data?: CMSField['data']) => {
  const convertedFieldType =
    fieldType === TextFieldType.Tag || fieldType === TextFieldType.Subtitle || fieldType === TextFieldType.Title
      ? `${fieldType}Field`
      : `${fieldType}`;
  const textColor = fieldStyle?.textColor;
  const textAlignment = fieldStyle?.alignment;
  const commonStyles = {
    color: textColor || 'inherit',
    '--textColor': textColor,
    '--textAlignment': textAlignment,
    borderColor: textColor,
    textTransform: fieldStyle?.uppercaseText ? 'uppercase' : 'inherit',
  };

  const commonActionFieldStyles = {
    borderColor: textColor || 'inherit',
    hoverColor: textColor ? `${textColor}1A` : 'inherit',
    activeColor: textColor ? `${textColor}26` : 'inherit',
    backgroundColor: data?.backgroundColor || '',
  };

  /*
   * TODO: For margin, we are currently in discussions with the design team
   * to establish consistent margin and padding based on font sizes for each field.
   * In the future, we aim to avoid hardcoding these values in JS.
   */
  const fieldStyles = {
    [FieldType.Tag]: { margin: '0' },
    [FieldType.Title]: { margin: '16px' },
    [FieldType.CTA]: {
      margin: '24px',
      ...commonActionFieldStyles,
    },
    [FieldType.Subtitle]: { margin: '12px' },
    [FieldType.Chips]: {
      margin: '24px',
      ...commonActionFieldStyles,
    },
  };

  const stylesForFieldType = fieldStyles[convertedFieldType];

  if (stylesForFieldType) {
    const styles: Record<string, string> = {
      ...commonStyles,
    };

    for (const key in stylesForFieldType) {
      styles[`--${fieldType}-${key}`] = stylesForFieldType[key];
    }
    return styles;
  }

  return {};
};
