import React, { useState } from 'react';
import clsx from 'clsx';
import Button from '@components/atoms/button/button';
import { useTranslation } from 'next-i18next';
import { WizardStepType } from '@interfaces/models/mySizes';
import GuideDrawer, { CategorySizeStandard } from '../guide-drawer/guide-drawer';
import styles from './footer.module.scss';

export interface MySizeFooterProps {
  handleNext: () => void;
  lastStep: boolean;
  hasChecked: boolean;
  lastStepCallback: () => void;
  className?: string;
  allAvailableSteps: WizardStepType[];
  sizeId: string;
  drawerCustomClass?: string;
}

const getUniverseIdBySizeId = (sid: string, allAvailableSteps: WizardStepType[]) => {
  if (!sid) {
    return '';
  }
  for (const step of allAvailableSteps) {
    const tmp = step.size_categories.find((s) => s.id === sid);
    if (tmp !== undefined) {
      return step.universe.ids[0];
    }
  }
  return '';
};

const MySizesFooter: React.FC<MySizeFooterProps> = ({
  handleNext,
  lastStep,
  hasChecked,
  lastStepCallback,
  className,
  allAvailableSteps,
  sizeId,
  drawerCustomClass,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [sizeStandard, setSizeStandard] = useState('International');

  const handleDrawer = (open) => {
    setOpen(open);
  };

  const setSizeGuideLocale = (map: CategorySizeStandard, sizeId: string) => {
    setSizeStandard(map[sizeId]);
  };

  return (
    <>
      <div className={clsx(styles.footer, className)}>
        <div className={styles.footerContent}>
          <div className={styles.footerItem1}>
            <span className={styles.international}>{sizeStandard}</span>&nbsp;
            <button
              className={styles.guide}
              onClick={() => handleDrawer(true)}
              data-cy="my-sizes-footer-size-guide-button"
            >
              {t('PLP.MYSIZES_DIALOG.SIZE_GUIDE')}
            </button>
          </div>
          <Button
            onClick={() => {
              handleNext();
              if (lastStep && lastStepCallback) {
                lastStepCallback();
              }
            }}
            className={clsx(styles.footerBtn, hasChecked ? styles.checked : styles.unchecked)}
            variant={hasChecked ? 'none' : 'outline'}
            size="large"
            data-cy="my-sizes-footer-next-action-button"
          >
            {lastStep
              ? t('PLP.MYSIZES_DIALOG.CREATE_BUTTON.SAVE_CONTINUE')
              : hasChecked
                ? t('PLP.MYSIZES_DIALOG.CREATE_BUTTON.NEXT')
                : t('PLP.MYSIZES_DIALOG.CREATE_BUTTON.SKIP')}
          </Button>
        </div>
      </div>
      <GuideDrawer
        open={open}
        handleDrawer={handleDrawer}
        sizeId={sizeId}
        universeId={getUniverseIdBySizeId(sizeId, allAvailableSteps)}
        drawerCustomClass={drawerCustomClass}
        setSizeGuideLocale={setSizeGuideLocale}
      />
    </>
  );
};

export default MySizesFooter;
