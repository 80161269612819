import React, { useCallback } from 'react';
import useOnReveal from '@hooks/use-on-reveal';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import clsx from 'clsx';
import CatalogProductFeed from '@components/catalog/catalog-product-feed/catalog-product-feed';
import ProductSlider from '@components/molecules/product-slider/product-slider';
import useRecentlyViewedProducts from '@hooks/product/use-recently-viewed-products';
import { useBucket } from '@context/bucket.context';
import ProductBlock from '../product-block/product-block';
import styles from './recently-viewed.module.scss';

type RecentlyViewedProps = {
  uuid: string;
  productType: string;
  displayTitle: string;
  arrangement: string;
  subtitle?: string;
  sectionIdx: string;
};

const RecentlyViewed: React.FC<RecentlyViewedProps> = ({
  displayTitle: title,
  subtitle,
  arrangement,
  productType,
  uuid: sectionId,
  sectionIdx,
}): React.JSX.Element => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { recentlyViewedProducts } = useRecentlyViewedProducts();
  const hasRecentlySeenProducts = recentlyViewedProducts.length > 3;
  const isSlider = arrangement === 'slider';
  const isSliderHorizontal = arrangement === 'horizontal_slider';
  const { isFeatureEnabled } = useBucket();
  const isSliderHPEnabled = isFeatureEnabled((f) => f.sliderHomePage, true);

  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        action: 'product',
        category: 'cms_content_view',
        label: sectionId ?? productType,
        property: `${sectionId}_${productType}`,
      });
    }, [productType, sectionId, sectionIdx, sendAnalyticEvent]),
    0.5,
    false,
  );

  if (!hasRecentlySeenProducts) {
    return null;
  }

  return (
    <>
      {isSliderHPEnabled && isSliderHorizontal ? (
        <section
          ref={containerRef}
          className={styles.recently__viewed}
        >
          <ProductBlock
            products={recentlyViewedProducts?.slice(0, 12)}
            title={title}
            subTitle={subtitle}
            showAuthGuard={true}
            productCardProps={{
              showProductTags: true,
              showSellerBadge: true,
              showProductSize: true,
              showProductExpressDelivery: true,
              showProductLocation: true,
              showProductDirectShipping: true,
              showDiscountPrice: true,
              pageType: 'cms_page',
              moduleType: 'recently-viewed',
            }}
          />
        </section>
      ) : (
        <section
          ref={containerRef}
          className={styles.recently__viewed}
        >
          <div className={clsx('innerContainer', styles.recently__viewed__header)}>
            <h2 className={clsx(styles.recently__viewed__header__title, 'vc-title-m')}>{title}</h2>
            <p className={clsx(styles.recently__viewed__header__subtitle, 'vc-text-m')}>{subtitle}</p>
          </div>
          <div className="innerContainer">
            {isSlider ? (
              <ProductSlider
                products={recentlyViewedProducts?.slice(0, 12)}
                productCardProps={{
                  showProductTags: true,
                  showSellerBadge: true,
                  showProductSize: true,
                  showProductExpressDelivery: true,
                  showProductLocation: true,
                  showProductDirectShipping: true,
                  showDiscountPrice: true,
                  pageType: 'cms_page',
                  moduleType: 'recently-viewed',
                }}
              />
            ) : (
              <CatalogProductFeed
                products={recentlyViewedProducts?.slice(0, 12)}
                productCardProps={{
                  pageType: 'cms_page',
                  moduleType: 'recently-viewed',
                }}
              />
            )}
          </div>
        </section>
      )}
    </>
  );
};

export default RecentlyViewed;
