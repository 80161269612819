import xss, { IFilterXSSOptions, IWhiteList, getDefaultWhiteList } from 'xss';

const getWhiteListWithStyleAttr = (customWhiteList: IWhiteList | undefined) => {
  const defaultWhiteList = getDefaultWhiteList();
  return Object.entries(customWhiteList || defaultWhiteList)?.reduce((whiteList, [tag, defaultAtts]) => {
    whiteList[tag] = [...defaultAtts, 'style'];
    return whiteList;
  }, {});
};

const sanitizer = (dirtyHTML: string, options?: IFilterXSSOptions, bypassSecurityTrustStyle?: boolean): string => {
  const filterXssOption = bypassSecurityTrustStyle
    ? { ...options, whiteList: getWhiteListWithStyleAttr(options?.whiteList) }
    : options;
  return xss(dirtyHTML, filterXssOption);
};

export default sanitizer;
