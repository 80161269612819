import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import useWindowSize from '@hooks/use-window-size';
import sanitizer from '@helpers/sanitizer';
import Icon from '../icon/icon';
import Button from '../button/button';
import styles from './toast-message.module.scss';

interface ToastMessageProps {
  id: string;
  title: string;
  subtitle?: string;
  duration?: number;
  show?: boolean;
  onClose?: (id: string) => void;
  maxToasts?: number;
  icon?: string;
  dataCy?: string;
  className?: string;
}

const ToastMessage: React.FC<ToastMessageProps> = (props) => {
  const {
    id,
    title,
    subtitle,
    duration = 3000,
    show = false,
    onClose,
    maxToasts = 3,
    icon = 'warning-20',
    dataCy = 'warning',
    className = '',
  } = props;
  const [isVisible, setIsVisible] = useState<boolean>(show);
  const toastMessageRef = useRef<HTMLDivElement>(null);
  const { isWiderThanLg } = useWindowSize();
  useEffect(() => {
    setIsVisible(show);
  }, [show]);

  // Dynamically adjust bottom position based on the number of toasts
  useEffect(() => {
    const toasts = document.querySelectorAll(`[data-cy^='toast_message_container_']`);
    if (toasts.length > 1) {
      const lastToast = toasts[toasts.length - 2] as HTMLElement;
      const lastBottom = lastToast?.getBoundingClientRect()?.height;
      const newBottom = lastBottom * (toasts?.length - 1);
      if (toastMessageRef.current) {
        const additionalOffset = isWiderThanLg ? '1.5rem' : '5.5rem';
        const toastOffset = `${(toasts?.length - 1 ?? 0) * 10}px`;

        toastMessageRef.current.style.bottom = `calc(${newBottom}px + ${additionalOffset} + ${toastOffset})`;
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && maxToasts) {
      // Limit the number of toast messages displayed
      const toasts = document.querySelectorAll(`[data-cy^='toast_message_container_']`);
      if (toasts.length > maxToasts) {
        setIsVisible(false);
      }
    }
  }, [isVisible, maxToasts]);

  useEffect(() => {
    if (isVisible) {
      // Auto-dismiss after the specified duration
      const timeout = setTimeout(() => {
        setIsVisible(false);
        if (onClose) {
          onClose(id);
        }
      }, duration);

      return () => clearTimeout(timeout);
    }
  }, [duration, isVisible, onClose, id]);

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) {
      onClose(id);
    }
  };

  return (
    <>
      {isVisible && (
        <div
          className={clsx(styles.toastMessage, className)}
          data-cy={`toast_message_container_${dataCy}`}
          ref={toastMessageRef}
        >
          <div className={styles.toastMessage__content}>
            <span className={styles.toastMessage__content__icon}>
              <Icon
                name={icon}
                data-cy={`toast_message_${dataCy}_icon}`}
              />
            </span>
            <div className={styles.toastMessage__content__label}>
              <div>
                <span
                  className={clsx(
                    'vc-text-m',
                    styles.toastMessage__content__label__title,
                    !!subtitle && 'vc-text-bold',
                  )}
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(title),
                  }}
                />{' '}
                {subtitle && (
                  <span className={clsx('vc-text-m', styles.toastMessage__content__label__subtitle)}>{subtitle}</span>
                )}
              </div>
            </div>
            <Button
              className={styles.toastMessage__content__close}
              onClick={handleClose}
            >
              <Icon
                name="close-16"
                data-cy="toast_message_close_icon"
              />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ToastMessage;
