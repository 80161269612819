import { ImagesService } from '@services/image-service';
import React, { useMemo } from 'react';
import { ImageComponent, withImageProps, VCImageProps } from '../vc-image';

export type SrcsetOptsType = {
  width: string;
  height: string;
  descriptor: string;
};

const CMS_IMAGE_SIZES = {
  edito: `(max-width: 375px) 320w, (max-width: 768px) 420w, (max-width: 990px) 780w, 1246w`,
};

const CMS_SIZES = [
  { width: '375', height: '328', descriptor: '320w' },
  { width: '768', height: '768', descriptor: '420w' },
  { width: '990', height: '860', descriptor: '780w' },
  { width: '1246', height: '910', descriptor: '1246w' },
];

function cmsImageLoader(srcSetOpts: SrcsetOptsType[]) {
  return ({ src, width, quality }): string => {
    /*
    options : 
    - crop // to defined how to
    - fit // TO BE DEFINED
    - h // 
    - q // use quality
    */
    const srcSets = srcSetOpts || CMS_SIZES;
    const option = srcSets.find((size) => width <= size.width) || srcSets[srcSets.length - 1];

    const cmsUrl = ImagesService.buildCmsUrl(src, {
      w: option.width,
      h: option.height,
      q: quality,
    });

    return cmsUrl;
  };
}

const CompWrap = (srcSetOpts: SrcsetOptsType[] | undefined): React.FunctionComponent => {
  return withImageProps(ImageComponent, cmsImageLoader(srcSetOpts), CMS_IMAGE_SIZES);
};

export default ({ srcSetOpts, ...restProps }: VCImageProps & { srcSetOpts?: SrcsetOptsType[] }) => {
  const Comp = useMemo(() => {
    return CompWrap(srcSetOpts);
  }, []);
  return React.createElement(Comp, restProps);
};
