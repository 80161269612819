import { User } from '@interfaces/models/user';
import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import { ApiResponse } from '@interfaces/api';
import Environment from '@config/index';
import { Product } from '@interfaces/models/product';
import { UserLikes } from '@interfaces/models/userLikes';
import { WelcomeVoucher } from '@interfaces/models/welcomeVoucher';

const BASE_URL = `${Environment.apiBaseUrl}/users/me`;

export const getUsersFollowedByCurrentlyAuthenticatedUser = async (): Promise<User['id'][]> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: { data },
  } = await axios.get<ApiResponse<User['id'][]>>(`${BASE_URL}/member-follows`, axiosConfig);
  return data;
};

export const getProductsLikedByCurrentlyAuthenticatedUser = async (): Promise<Product['id'][]> => {
  const axiosConfig = getAxiosConfig();
  const {
    data: {
      data: { products },
    },
  } = await axios.get<ApiResponse<UserLikes>>(`${BASE_URL}/likes/`, axiosConfig);
  return products ?? [];
};

export const getWelcomeVoucherForCurrentlyAuthenticatedUser = async (
  touchPoint: string,
  ldAnonymousId: string,
  serverAxiosRequestData?: ServerAxiosRequestData,
  productId?: string,
): Promise<WelcomeVoucher> => {
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);
  axiosConfig.params = {
    ...axiosConfig.params,
    touchpoint: touchPoint,
  };

  if (productId) {
    axiosConfig.params.productId = productId;
  }

  if (ldAnonymousId) {
    axiosConfig.headers = {
      ...axiosConfig.headers,
      'x-ld-anonymous-id': ldAnonymousId,
    };
  }

  const {
    data: { data },
  } = await axios.get<ApiResponse<WelcomeVoucher>>(`${BASE_URL}/welcome-voucher`, axiosConfig);
  return data;
};
