import { Product } from '@interfaces/models/product';
import useLocalStorage from '@hooks/use-local-storage';
import useProductShippingQuery from '@hooks/product/use-product-shipping-query';
import useUser from '@hooks/user/use-user';

const useRecentlyViewedProducts = () => {
  const { productShipping } = useProductShippingQuery();
  const { user } = useUser();

  const [recentlyViewedProducts, setRecentlyViewedProducts] = useLocalStorage<Product[]>(
    'localsave/recentlySeenProducts',
    [],
  );

  const addRecentlyViewedProduct = (product: Product) => {
    if (
      productShipping?.buyable &&
      !productShipping?.prohibited &&
      product?.seller?.id !== user?.id &&
      !product?.sold
    ) {
      setRecentlyViewedProducts((prevState: Product[]) => {
        const productId: Product['id'] = product.id;
        // If product already exists in 'recently seen', remove it, and add it back to start, limit max products to 12
        return [product, ...prevState.filter((product: Product) => product.id !== productId)].filter(
          (_, index) => index < 12,
        );
      });
    }
  };

  return {
    recentlyViewedProducts,
    addRecentlyViewedProduct,
  };
};

export default useRecentlyViewedProducts;
