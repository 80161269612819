import React, { FCWithChildren } from 'react';
import Loading from './loading';
import RespFeedback from './response-feedback';
import { RespStatusType } from './types';

type FeedbackWrapProps = {
  loading: boolean;
  respStatus: RespStatusType;
  title?: string;
  feedbackCallback: () => void;
};

const FeedbackWrap: FCWithChildren<FeedbackWrapProps> = (props) => {
  const { loading, respStatus, title, children, feedbackCallback } = props;

  const showResponseFeedback = respStatus !== 'none';

  return (
    <div>
      <Loading loading={loading}>
        {showResponseFeedback && (
          <RespFeedback
            respStatus={respStatus}
            title={title}
            feedbackCallback={feedbackCallback}
          />
        )}

        {respStatus === 'none' && children}
      </Loading>
    </div>
  );
};

export default FeedbackWrap;
