import Environment from '@config/index';
import { ShippingPlan } from '@interfaces/models/shipping-plan';
import { ApiResponse } from '@interfaces/api';
import { Preferences } from '@interfaces/models/preferences';
import { axios, getAxiosConfig } from '@config/axios';

const BASE_URL = Environment.apiBaseUrl;

export const getDirectShippingEligibility = async ({
  country,
}: Pick<Preferences, 'country'>): Promise<ShippingPlan> => {
  const axiosConfig = getAxiosConfig();
  axiosConfig.params = {
    ...axiosConfig.params,
    country,
  };

  const {
    data: { data },
  } = await axios.get<ApiResponse<ShippingPlan>>(`${BASE_URL}/shipping-plan/eligibility`, axiosConfig);

  return data;
};
