import React from 'react';
import Button from '@components/atoms/button/button';
import Link from '@components/atoms/link/link';
import { EditoPageCTA } from '@interfaces/models/editorialPage';
import styles from '../text-container.module.scss';

interface CTAElementProps {
  cta: EditoPageCTA['cta'];
  metadata: EditoPageCTA['_metadata'];
  index: number;
  bgColor: EditoPageCTA['cta_s_background_color'];
  titleColor: EditoPageCTA['cta_s_text_color'];
  handleOnClick: (cta: EditoPageCTA['cta'], _metadata: EditoPageCTA['_metadata'], index: number) => void;
}

const CTAElement = ({ cta, metadata, index, bgColor, titleColor, handleOnClick }: CTAElementProps) => (
  <div className={styles.textcontainer_cta_module_cta}>
    <Link href={cta?.href}>
      <Button
        variant="primary"
        size="medium"
        fullWidth
        notFullWidthQuery="md-up"
        style={bgColor && { background: bgColor, borderColor: bgColor }}
        onClick={handleOnClick.bind(null, cta, metadata, index)}
      >
        <span style={titleColor && { color: titleColor }}>{cta?.title}</span>
      </Button>
    </Link>
  </div>
);

export default CTAElement;
