import React, { useState } from 'react';
import { Product } from '@interfaces/models/product';
import sanitizer from '@helpers/sanitizer';
import { OfferItemCondition, Product as ProductSchemaType, ImageObject as ImageSchemaType } from 'schema-dts';
import { User } from '@interfaces/models/user';
import UserBadge from '@enums/userBadge';
import { ContentsPageMetadata } from '@interfaces/models/contentsPageMetadata';
import { ImagesService } from '@services/image-service';

type ProductSchemaProps = {
  product: Product;
  seoMetaData?: ContentsPageMetadata;
};

const CONDITION_NEVER_WORN_WITH_TAG_ID = '10';

const getGoogleShoppingCondition = (conditionId: string): OfferItemCondition => {
  return [CONDITION_NEVER_WORN_WITH_TAG_ID].includes(conditionId)
    ? 'https://schema.org/NewCondition'
    : 'https://schema.org/UsedCondition';
};

const getSeller = (seller: User) => {
  return {
    '@type': seller?.badges?.includes('pro' as UserBadge) ? 'Organization' : 'Person',
    name: seller?.firstname,
  };
};

const MEASUREMENTS_TO_INCLUDE = ['Height', 'Width', 'Depth'];

const getOptionalMeasurements = (product: Product) => {
  return Object.fromEntries(
    MEASUREMENTS_TO_INCLUDE.map((optionalMeasurement) => {
      const productMeasurement = product?.measurements?.find((measurement) => measurement.name === optionalMeasurement);
      if (!productMeasurement) {
        return [null, null];
      }
      return [
        [optionalMeasurement],
        {
          '@type': 'QuantitativeValue',
          value: productMeasurement.value,
          ...(!!product?.unit && {
            unitCode: product?.unit,
          }),
        },
      ];
    }).filter((item) => item[0] !== null),
  );
};

const createImageSchema = (product: Product): ImageSchemaType => ({
  '@type': 'ImageObject',
  url: product.path,
  image: ImagesService.buildUrl(product?.pictures?.[0]?.path, { w: 1024, h: 1024 }, true),
  name: product?.name,
  width: '1024',
  height: '1024',
});

const getDescription = (description: string, seoDesc: string) =>
  description?.length < 50 ? seoDesc || description : description;

const createProductSchema = (product: Product, seoMetaData?: ContentsPageMetadata): ProductSchemaType => ({
  '@context': 'https://schema.org/',
  '@type': 'Product',
  name: product?.name,
  sku: product?.id,
  image: createImageSchema(product),
  description:
    getDescription(product?.localizedDescription, seoMetaData?.description) ??
    getDescription(product?.description, seoMetaData?.description),
  ...(product?.id && {
    sku: product?.id,
  }),
  ...(product?.category && {
    category: product?.category?.localizedName,
  }),
  ...(product?.color && {
    color: product?.color?.localizedName,
  }),
  ...(product?.material && {
    material: product?.material?.localizedName,
  }),
  ...(product?.measurements && {
    material: product?.material?.localizedName,
  }),
  brand: {
    '@type': 'Brand',
    name: product?.brand?.name,
  },
  offers: {
    '@type': 'Offer',
    url: product.path,
    priceCurrency: product?.price?.currency ?? '',
    price: ((product?.price?.cents ?? 0) / 100).toString(),
    availability: product?.sold ? 'OutOfStock' : 'InStock',
    itemCondition: getGoogleShoppingCondition(product?.condition?.id),
    ...(!!product?.seller && {
      seller: getSeller(product?.seller),
    }),
  },
  ...getOptionalMeasurements(product),
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: 4.5,
    reviewCount: 44885,
  },
});

const ProductSchema: React.FC<ProductSchemaProps> = (props) => {
  const { product, seoMetaData } = props;

  const [schema] = useState(createProductSchema(product, seoMetaData));

  return (
    <script
      data-cy={`product-schema-${product.id}`}
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: sanitizer(JSON.stringify(schema)),
      }}
    />
  );
};

export default ProductSchema;
