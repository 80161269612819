import React, { FCWithChildren } from 'react';
import { createContext } from 'use-context-selector';
import { Campaign, CampaignBlock } from '@interfaces/models/campaign';

type CampaignContextValues = {
  campaign: Campaign & CampaignBlock;
};

export type CampaignProviderProps = {
  campaign: Campaign & CampaignBlock & { campaign_id: string };
};

const CampaignContext = createContext<CampaignContextValues>({} as never);

const CampaignProvider: FCWithChildren<CampaignProviderProps> = (props): React.JSX.Element => {
  const { campaign, children } = props;

  const value = {
    campaign,
  };

  return <CampaignContext.Provider value={value}>{children}</CampaignContext.Provider>;
};

export { CampaignProvider, CampaignContext };
