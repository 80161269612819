import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import Environment from '@config/index';
import { FAV_CONSTANTS } from '@constants/favorites';
import { ApiResponse } from '@interfaces/api';
import { HotFiltersPaginationStatsResponse, HotFiltersResponse } from '@interfaces/api/responses/hot-filters.response';
import { Product } from '@interfaces/models/product';
import { User } from '@interfaces/models/user';

export type SortOrder = 'price' | 'recentPriceDrop' | 'priceDesc' | 'date' | 'dateDesc' | null;

export const FAV_DEFAULT_SORT_ORDER = 'dateDesc';

type GetHotFiltersProductsPayload = {
  userId: User['id'];
  sortBy: string;
  hotFiltersQuery: {};
  paginationQuery: Partial<HotFiltersPaginationStatsResponse>;
};

type GetProductsPayload = {
  userId: User['id'];
  order: string;
  limit: number;
  offset: number;
};

const BASE_URL = `${Environment.apiBaseUrl}/users`;

export const getUsersFavoritesProducts = async (
  payload: GetProductsPayload,
  serverAxiosRequestData?: ServerAxiosRequestData,
): Promise<ApiResponse<Product[]>> => {
  const { userId, order, limit, offset } = payload;
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);

  axiosConfig.params = {
    ...axiosConfig.params,
    order,
    limit,
    offset,
  };

  const { data } = await axios.get<ApiResponse<Product[]>>(`${BASE_URL}/${userId}/liked-products`, axiosConfig);
  return data;
};

export const getUsersFavoritesProductsWithFilters = async (
  payload: GetHotFiltersProductsPayload,
  serverAxiosRequestData?: ServerAxiosRequestData,
): Promise<ApiResponse<HotFiltersResponse>> => {
  const { userId, sortBy, hotFiltersQuery, paginationQuery } = payload;

  const axiosConfig = getAxiosConfig(serverAxiosRequestData);

  axiosConfig.params = {
    ...axiosConfig.params,
  };

  const requestPayload = {
    filters: hotFiltersQuery,
    sortBy: sortBy ?? FAV_CONSTANTS.FAV_DEFAULT_SORT_ORDER,
    pagination: {
      offset: paginationQuery?.offset ?? FAV_CONSTANTS.FAV_DEFAULT_OFFSET,
      limit: paginationQuery?.limit ?? FAV_CONSTANTS.FAV_DEFAULT_LIMIT,
    },
  };

  const { data } = await axios.post<ApiResponse<HotFiltersResponse>>(
    `${BASE_URL}/${userId}/favorites`,
    requestPayload,
    axiosConfig,
  );
  return data;
};
