import React from 'react';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import Link from '@components/atoms/link/link';
import Button from '@components/atoms/button/button';
import { EditoPageCTA } from '@interfaces/models/editorialPage';
import { getCatalogDatalayerEntrypoint } from '@helpers/utils/catalog/catalog-tracking-utils';
import styles from '../visual-container.module.scss';

interface VisualContainerCTAProps {
  innerStyle: {
    backgroundColor?: string;
    borderColor?: string;
    color?: string;
  };
  cta: EditoPageCTA['cta'];
  tracking?: {
    contentTypeUid: string;
    sectionIdx: string;
    label: string;
    campaignBlockId?: string;
  };
}

const VisualContainerCTA = ({ innerStyle, cta, tracking }: VisualContainerCTAProps) => {
  const { contentTypeUid, sectionIdx, label, campaignBlockId } = tracking;
  const { sendAnalyticEvent, updateDataLayer } = useAnalyticEvents('cms_component');
  const handleOnClick = (e) => {
    if (cta?.href) {
      updateDataLayer(
        getCatalogDatalayerEntrypoint({
          source_category: 'cms_block',
          cms_block_id: campaignBlockId,
        }),
      );
      e.preventDefault();
      sendAnalyticEvent('cms_content_click', {
        category: 'cms_content_click',
        property: label,
        action: contentTypeUid,
        label: sectionIdx ?? '',
      });
      if (typeof window !== 'undefined') {
        window.location.assign(cta?.href);
      }
    }
  };

  return (
    <div className={styles.visualcontainer_cta_module_cta}>
      <Link href={cta?.href}>
        <Button
          variant="primary"
          size="medium"
          fullWidth
          notFullWidthQuery="md-up"
          style={innerStyle}
          onClick={handleOnClick}
        >
          {cta?.title}
        </Button>
      </Link>
    </div>
  );
};

export default VisualContainerCTA;
