import React from 'react';
import clsx from 'clsx';
import CampaignHeroBlock from '@components/molecules/cms/campaign-hero-block/campaign-hero-block';
import { CampaignHeroBlock as SaleBlock } from '@interfaces/models/cms/campaign-hero';
import styles from './campaign-hero.module.scss';

type CampaignHeroBlockProps = {
  componentID: string;
} & SaleBlock;

const CampaignHero: React.FC<CampaignHeroBlockProps> = (props): React.JSX.Element => {
  return (
    <section className={clsx('innerContainers', styles.campaignHero, styles[`campaignHero--${props.blockStyle}`])}>
      <CampaignHeroBlock {...props} />
    </section>
  );
};

export default CampaignHero;
