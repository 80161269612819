import React, { useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import PostContent from '@components/molecules/post-card/components/post-content';
import { PostBlock as IPostBlock } from '@interfaces/models/EditoPost';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './post-card.module.scss';

const PostCard: React.FC<IPostBlock> = (props): React.JSX.Element => {
  const {
    url,
    uid: postBlockId,
    blockImage,
    category,
    categoryTitle,
    categoryUri,
    date,
    modularBlocks,
    displayTitle: title,
    title: label,
    contentTypeUid: contentTypeUID,
    sectionIdx,
    posX,
    blockSubtitle: subTitle,
  } = props;

  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const blockId = `HP_${postBlockId}`;
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        label: sectionIdx ? `${posX}_${sectionIdx}` : category,
        property: `${postBlockId}_${label}`,
        value: sectionIdx ? '' : posX,
      });
    }, [contentTypeUID, category, label, posX, postBlockId]),
    0.5,
    false,
  );

  const trackPostCardClick = (e) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      label: `${posX}_${sectionIdx}`,
      property: `${postBlockId}_${label}`,
    });
    e.stopPropagation();
    setHpScrollPosition(blockId);
  };

  const postContentProps = {
    blockImage,
    modularBlocks,
    title,
    subTitle,
    date,
    category,
    categoryTitle,
    categoryUri,
  };

  return (
    <section
      ref={containerRef}
      id={blockId}
    >
      {url ? (
        <a
          href={url}
          onClick={trackPostCardClick}
          itemProp="url"
          className={clsx(styles.post, styles['post--link'])}
        >
          <PostContent {...postContentProps} />
        </a>
      ) : (
        <div className={styles.post}>
          <PostContent {...postContentProps} />
        </div>
      )}
    </section>
  );
};

export default PostCard;
