import React from 'react';
import CMSImages from '@components/atoms/vc-images/components/cms-image';
import clsx from 'clsx';
import { MerchandiseBlock } from '@interfaces/models/merchandise';
import styles from '../merchandise-block.module.scss';

interface MerchandiseImageProps {
  image: MerchandiseBlock['image'];
  blockLength: MerchandiseBlock['blockLength'];
  displayStyle: MerchandiseBlock['displayStyle'];
  imagesAspectRatio: MerchandiseBlock['imagesAspectRatio'];
}

const ImageRatioFormat: {
  [key in MerchandiseBlock['imagesAspectRatio']]: string;
} = {
  '1:1': '1_1',
  '4:3': '4_3',
  '4:5': '4_5',
};

const ImageProps: {
  [key in MerchandiseBlock['imagesAspectRatio']]: {
    width: number;
    height: number;
    sizes: string;
    srcSetOpts: { width: string; height: string; descriptor: string }[];
  };
} = {
  '1:1': {
    width: 140,
    height: 140,
    sizes: `(min-width: 800px) 440px, (min-width: 100px) 140px,`,
    srcSetOpts: [
      { width: '140', height: '140', descriptor: '420w' },
      { width: '440', height: '440', descriptor: '780w' },
    ],
  },
  '4:3': {
    width: 140,
    height: 105,
    sizes: `(min-width: 800px) 440px, (min-width: 100px) 140px,`,
    srcSetOpts: [
      { width: '140', height: '105', descriptor: '420w' },
      { width: '440', height: '505', descriptor: '780w' },
    ],
  },
  '4:5': {
    width: 140,
    height: 175,
    sizes: `(min-width: 800px) 440px, (min-width: 100px) 140px,`,
    srcSetOpts: [
      { width: '140', height: '175', descriptor: '420w' },
      { width: '440', height: '575', descriptor: '780w' },
    ],
  },
};

const MerchandiseImage = ({
  image,
  blockLength,
  displayStyle = 'slider',
  imagesAspectRatio = '1:1',
}: MerchandiseImageProps) => {
  const { filename: imgXName, url: imgPath, title: imgTitle } = image || {};

  if (!imgXName || !imgPath) {
    return null;
  }

  return (
    <CMSImages
      className={clsx(
        styles.merchandising__img,
        styles[`merchandising__img-${blockLength}`],
        styles[`merchandising__img--${displayStyle}`],
        styles[`merchandising__img--${ImageRatioFormat[imagesAspectRatio]}`],
      )}
      {...(ImageProps[imagesAspectRatio] || ImageProps['1:1'])}
      src={imgPath}
      alt={imgTitle}
      data-src={imgXName}
    />
  );
};

export default MerchandiseImage;
