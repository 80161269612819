import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import { CMSContextualAction, CMSContextualRedirection } from '@interfaces/models/cms/campaign-hero';
import sanitize from '@helpers/sanitizer';
import Button from '@components/atoms/button/button';
import Icon from '@components/atoms/icon/icon';
import useWindowSize from '@hooks/use-window-size';
import styles from './cms-contextual-modal.module.scss';

const ContextualModal: React.FC<{ data: CMSContextualRedirection; onTrackCTAClick?: (action: string) => void }> = ({
  data,
  onTrackCTAClick,
}): React.JSX.Element => {
  const { title, text, actions } = data;
  const [codeCopied, setCodeCopied] = useState(false);
  const { isWiderThanMd } = useWindowSize();

  const { t } = useTranslation();

  const handleClick = async (action: CMSContextualAction) => {
    onTrackCTAClick?.(action.actionType);

    if (action?.actionType === 'redirect') {
      window.location.href = action.value;
      return;
    }
    if (action?.actionType === 'copy') {
      await navigator.clipboard.writeText(action.value);
      setCodeCopied(true);
      return;
    }
  };
  return (
    <>
      <h3 className={clsx('vc-text-xl', styles.title)}>{title}</h3>
      <div
        className={clsx('vc-text-m', styles.content)}
        dangerouslySetInnerHTML={{ __html: sanitize(text) }}
      />
      <div className={styles.cta}>
        {actions?.map((action, index) => (
          <Button
            key={index}
            className={clsx(styles.cta__item, styles[`cta__item--${action.actionType}`])}
            variant={action.style}
            size={isWiderThanMd ? 'large' : 'medium'}
            fullWidth
            notFullWidthQuery="md-up"
            onClick={() => handleClick(action)}
          >
            {codeCopied && action?.actionType == 'copy' ? (
              <>
                <Icon
                  name="tick-default"
                  className={styles.cta__item__icon}
                />
                {t('CMS.COPY_TO_CLIPBOARD_FEEDBACK')}
              </>
            ) : (
              <>
                {action?.actionType == 'copy' && (
                  <Icon
                    name="duplicate-default"
                    className={styles.cta__item__icon}
                  />
                )}
                {action?.label}
              </>
            )}
          </Button>
        ))}
      </div>
    </>
  );
};

export default ContextualModal;
