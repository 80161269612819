import { ElasticSearchFacetsFields, ElasticSearchFilterKeys } from '@interfaces/models/elasticSearch';
import { invertMap } from '@helpers/utils/general';

export const searchProductFacetsToFiltersMap = new Map<ElasticSearchFacetsFields, ElasticSearchFilterKeys>([
  ['universe', 'universe.id'],
  ['brand', 'brand.id'],
  ['color', 'color.id'],
  ['watchMechanism', 'watchMechanism.id'],
  ['condition', 'condition.id'],
  ['region', 'region.id'],
  ['model', 'model.id'],
  ['categoryLvl0', 'categoryLvl0.id'],
  ['categoryLvl1', 'categoryLvl1.id'],
  ['categoryLvl2', 'categoryLvl2.id'],
  ['materialLvl0', 'materialLvl0.id'],
  ['materialLvl1', 'materialLvl1.id'],
]);

export const searchProductFiltersToFacetsMap = invertMap<ElasticSearchFilterKeys, ElasticSearchFacetsFields>(
  searchProductFacetsToFiltersMap,
);

export const filterToFacetRename = (filterGroupName: ElasticSearchFilterKeys): ElasticSearchFacetsFields => {
  return (searchProductFiltersToFacetsMap.get(filterGroupName) ?? filterGroupName) as ElasticSearchFacetsFields;
};

export const facetToFilterRename = (facetGroupName: ElasticSearchFacetsFields): ElasticSearchFilterKeys => {
  return (searchProductFacetsToFiltersMap.get(facetGroupName) ?? facetGroupName) as ElasticSearchFilterKeys;
};
