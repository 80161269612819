import React from 'react';
import { clamp } from '@helpers/utils/general';
import styles from './progress-bar.module.scss';

type ProgressBarProps = {
  value: number;
  color?: string;
} & React.HTMLProps<HTMLDivElement>;

const defaultColor = '#000000';
const isColorReg = /^#([0-9a-f]{3}){1,2}$/i;

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const { value, color = defaultColor } = props;

  const progressValue: number = clamp(value, 0, 100);

  return (
    <div className={styles.line}>
      <div
        className={styles.line__active}
        style={{
          width: `${progressValue}%`,
          backgroundColor: isColorReg.test(color) ? color : defaultColor,
        }}
      />
    </div>
  );
};

export default ProgressBar;
