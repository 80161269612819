import { FC } from 'react';

import CmsImage from '@components/atoms/vc-images/components/cms-image';
import { CMSField as FieldProps, CMSFieldType as FieldType } from '@interfaces/models/cms/campaign-hero';

import { useFieldStylesAndClasses } from '../cms-block-fields';

export const ImageField: FC<{
  data: FieldProps['data'];
  blockStyle?: string;
}> = ({ data, blockStyle }): JSX.Element => {
  const { url } = data;
  const { fieldStyles, fieldClass } = useFieldStylesAndClasses(FieldType.Image, data, blockStyle);

  return (
    <>
      <CmsImage
        style={fieldStyles}
        className={fieldClass}
        src={url}
        alt={data?.text}
        width={100}
        height={100}
        sizes="(max-width: 768px) 100vw, 60vw"
      />
    </>
  );
};
