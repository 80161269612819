import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import CloseButton from '@components/atoms/close-button/close-button';
import { createPortal } from 'react-dom';
import styles from './app-sheet.module.scss';

type AppSheetProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
};

const AppSheet = (props: AppSheetProps) => {
  const { open, onClose, children, title } = props;

  const ref = useRef<Element | null>(null);
  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    ref.current = document.querySelector<HTMLElement>('body');
    setIsMounted(true);
  }, []);

  return (
    isMounted &&
    ref.current &&
    createPortal(
      <>
        <div className={clsx(styles.appSheet, open && styles['appSheet--show'])}>
          <CloseButton handleClick={onClose} />
          <div className={clsx(styles.appSheet__title, 'vc-text-l')}>{title}</div>
          {children}
        </div>
        <div
          className={clsx(styles.appSheet__overlay, open && styles['appSheet__overlay--show'])}
          role={'button'}
          tabIndex={0}
          onClick={onClose}
          onKeyDown={onClose}
        />
      </>,
      ref.current,
    )
  );
};

export default AppSheet;
