import ToastMessage from '@components/atoms/toast-message/toast-message';
import React, { FCWithChildren, useMemo, useState } from 'react';
import { createContext } from 'use-context-selector';

type Toast = {
  id: string;
  title: string;
  subtitle?: string;
  duration?: number;
  toastMessageId?: number;
  timeoutId?: NodeJS.Timeout;
};

type ToastMessageContextValues = {
  showToastMessage: (title: string, subtitle?: string, duration?: number) => void;
  hideToastMessage: () => void;
  toastMessageId?: number;
  timeoutId?: NodeJS.Timeout;
};

const ToastMessageContext = createContext<ToastMessageContextValues>({} as never);

const ToastMessageProvider: FCWithChildren = (props): React.JSX.Element => {
  const { children } = props;
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [toasts, setToasts] = useState<Toast[]>([]);
  const [toastMessageId, setToastMessageId] = useState<number>(0);

  const handleCloseToastMessage = (id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    const toast = toasts.find((toast: Toast) => toast.id === id);
    if (toast && toast.timeoutId) {
      clearTimeout(toast.timeoutId); // Clear individual timer when closing a toast message
    }
    setToastMessageId((prevId) => (prevId > 0 ? prevId - 1 : 0));
  };

  const showToastMessage = (title: string, subtitle?: string, duration?: number) => {
    setIsVisible(true);
    const id = `toast_${toastMessageId}`;
    setToasts((prevNotifications: Toast[]) => [...prevNotifications, { id, title, subtitle, duration }]);
    setToastMessageId((prevId) => prevId + 1);
    if (duration && duration > 0) {
      const timeoutId = setTimeout(() => {
        handleCloseToastMessage(id);
      }, duration);

      // Store the timeout ID in the state
      setToasts((prevToasts) =>
        prevToasts.map((toast) => {
          if (toast.id === id) {
            return { ...toast, timeoutId };
          }
          return toast;
        }),
      );
    }
  };

  const hideToastMessage = () => {
    setToasts([]);
    // Clear all individual timers when hiding toast messages
    toasts.forEach((toast: Toast) => clearTimeout(toast.timeoutId));
    setToastMessageId(0);
  };

  const value = useMemo<ToastMessageContextValues>(() => {
    return {
      showToastMessage,
      hideToastMessage,
      toastMessageId,
    };
  }, [showToastMessage, hideToastMessage, toastMessageId]);

  return (
    <ToastMessageContext.Provider value={value}>
      {children}
      {toasts.map((toast: Toast) => (
        <ToastMessage
          key={toast.id}
          id={toast.id}
          title={toast.title}
          subtitle={toast.subtitle}
          duration={toast.duration}
          show={isVisible}
          onClose={() => handleCloseToastMessage(toast.id)}
        />
      ))}
    </ToastMessageContext.Provider>
  );
};

export { ToastMessageProvider, ToastMessageContext };
