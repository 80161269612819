import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import { Channel as IChannel } from '@interfaces/models/channel';
import { ContentStackPageNames } from '@enums/contentStack';
import ChannelBlock from '@components/molecules/channel-block/channel-block';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './channel.module.scss';

interface IChannelProps extends IChannel {
  pageType?: ContentStackPageNames;
}

const Channel: React.FC<IChannelProps> = ({
  uid,
  blocks: channelBlocks,
  contentTypeUid: contentTypeUID,
  title: label,
  sectionIdx,
  pageType,
}): React.JSX.Element => {
  const isGlobalHP = ContentStackPageNames.GLOBAL_HOME_PAGE === pageType;
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${uid}_${label}`,
        value: String(sectionIdx || ''),
      });
    }, [contentTypeUID, label, sectionIdx, uid]),
    0.5,
    false,
  );

  const vcChannelBlocks = useMemo(() => {
    return channelBlocks?.map((channelBlock, idx) => {
      const { uid, displayTitle: title } = channelBlock;

      return (
        <div
          key={`${title || ''}-${uid}`}
          className={styles.container__channel}
        >
          <ChannelBlock
            {...channelBlock}
            sectionIdx={sectionIdx}
            idx={idx}
          />
        </div>
      );
    });
  }, [channelBlocks]);

  if (!vcChannelBlocks?.length) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      style={{ display: 'inline' }}
    >
      <section
        className={clsx(
          'innerContainer',
          styles.channelContainer,
          `${channelBlocks?.length >= 5 ? styles.fiveChannel : ''}`,
        )}
        style={isGlobalHP ? { marginBottom: 0 } : {}}
      >
        <div className={styles.entriesWrapper}>{vcChannelBlocks}</div>
      </section>
    </div>
  );
};

export default Channel;
