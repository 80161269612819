import { useQuery } from '@tanstack/react-query';
import { GlobalQueryKeys } from '@enums/react-query-keys';
import { HeaderDataItem } from '@interfaces/models/header';
import { getFullHeader } from '@api/static';
import { usePreferences } from '@context/preferences.context';

const useHeaderQuery = (enabled: boolean) => {
  const { siteId, locale } = usePreferences();

  const { data: partialHeaderData } = useQuery<HeaderDataItem[]>({
    queryKey: [GlobalQueryKeys.PARTIAL_HEADER],
    cacheTime: Infinity,
  });

  const { data: headerData } = useQuery<HeaderDataItem[]>({
    queryFn: () => getFullHeader({ siteId, locale }),
    queryKey: [GlobalQueryKeys.HEADER],
    cacheTime: Infinity,
    enabled,
    initialData: partialHeaderData,
  });

  return {
    headerData,
  };
};

export default useHeaderQuery;
