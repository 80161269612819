import React from 'react';
import clsx from 'clsx';
import Drawer from '@components/atoms/drawer/drawer';
import useProductSalesConditionQuery from '@hooks/size-guide/use-product-sales-condition-query';
import SizeGuideModal from '@components/atoms/size-guide-wrapper/components/size-guide-modal/size-guide-modal';
import { Product } from '@interfaces/models/product';
import styles from './guide-drawer.module.scss';

export interface CategorySizeStandard {
  [val: number]: string;
}

interface GuideDrawerProps {
  handleDrawer: (isOpen: boolean) => void;
  open: boolean;
  universeId: Product['universe']['id'];
  sizeId: string;
  drawerCustomClass?: string;
  setSizeGuideLocale: (map: CategorySizeStandard, sizeId: string) => void;
}

const GuideDrawer: React.FC<GuideDrawerProps> = (props) => {
  const { handleDrawer, open, universeId, sizeId, drawerCustomClass } = props;

  const { sizeStandard } = useProductSalesConditionQuery();

  return (
    <Drawer
      onClose={() => handleDrawer(false)}
      position="left"
      open={open}
      hideBackdrop={false}
      customClass={clsx(styles.drawerContainer, drawerCustomClass)}
    >
      <div className={styles.guideContainer}>
        {sizeStandard && (
          <SizeGuideModal
            universeId={universeId}
            productCategoryId={sizeId}
            sizeGuideStandard={sizeStandard}
          />
        )}
      </div>
    </Drawer>
  );
};

export default GuideDrawer;
