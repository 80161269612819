import React from 'react';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import AppSheet from '@components/organisms/app-sheet/app-sheet';
import { useTranslation } from 'next-i18next';
import styles from './editing-app-sheet.module.scss';

interface EditingAppSheetProps {
  open: boolean;
  onDelete: () => void;
  onClose: () => void;
}

const EditingAppSheet: React.FC<EditingAppSheetProps> = ({ open, onDelete, onClose }) => {
  const { t } = useTranslation();

  return (
    <AppSheet
      onClose={onClose}
      title={t('PLP.MYSIZES_DIALOG.MORE_ACTIONS')}
      open={open}
    >
      <button
        className={clsx(styles.editingAppSheet__btn, 'vc-text-m')}
        onClick={onDelete}
        type="button"
      >
        <Icon
          name="trash-can"
          className={styles.editingAppSheet__deleteIcon}
        />
        {t('PLP.MYSIZES_DIALOG.DELETE_BUTTON')}
      </button>
    </AppSheet>
  );
};

export default EditingAppSheet;
