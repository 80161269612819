import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import Dialog from '@components/atoms/dialog/dialog';
import Icon from '@components/atoms/icon/icon';
import ToggleButton from '@components/catalog/toggle-button/toggle-button';
import TextSwatch from '@components/atoms/text-swatch/text-swatch';
import { MySizes as Sizes, SizeCatType, WizardStepType } from '@interfaces/models/mySizes';
import useWindowSize from '@hooks/use-window-size';
import EditingAppSheet from '@components/catalog/my-sizes/my-sizes/editing/editing-app-sheet/editing-app-sheet';
import SizeAccordion from '../size-accordion/size-accordion';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import FeedbackWrap from '../response-feedback/feedback-dialog';
import { RespStatusType } from '../response-feedback/types';
import MySizesFooter from '../footer/footer';
import UniverseTab from './universe-tab';
import { genId, genIds, iconMap, sizeTypes, universesMap } from './sizesMap';
import styles from './editing.module.scss';

interface EditingProps {
  dialogOpen: boolean;
  setDialogOpen: (isOpen: boolean) => void;
  options: WizardStepType[];
  onChange: (val: Sizes) => void;
  value: Sizes;
  dataFetching: boolean;
  respStatusType: RespStatusType;
  feedbackCallback: () => void;
  isWebview?: boolean;
}

// generate label of toggle-button
const genLabel = (value, sid) => {
  const sc = value.size_categories.filter((v) => v.id === sid) || [];
  return sc.length ? sc[0].sizes.map((s) => s.label) : [];
};

// generate options of toggle-button
const genSizeTypes = (options, index, value) => {
  if (!options || options.length <= index) {
    return [];
  }
  return options[index].size_categories.map((p) => {
    const label = genLabel(value, p.id).join(',');
    return {
      label: label,
      value: p.id,
      icon: iconMap[p.id],
    };
  });
};

// get selected size ids from value
const getSizeIds = (value, sid) => {
  const arr = value?.size_categories || [];
  const tmp = arr.filter((t) => t.id === sid);
  return tmp.length ? tmp[0].sizes.map((t) => t.id) : [];
};

const getUniverseLabelById = (options, ids) => {
  const tmp = options.find((p) => genId(p.universe.ids) === ids);
  return tmp !== undefined ? tmp.universe.label : '';
};

const getUniverseObjByUniverse = (options, universe) => {
  const tmp = options.find((p) => genId(p.universe.ids) === universe);
  return tmp !== undefined ? tmp.universe : {};
};

const getCatLabelById = (options, id) => {
  for (const p of options) {
    const tmp = p.size_categories.find((s) => s.id === id);
    if (tmp !== undefined) {
      return tmp.label;
    }
  }
  return '';
};

const Editing: React.FC<EditingProps> = ({
  dialogOpen,
  setDialogOpen,
  options,
  onChange,
  feedbackCallback,
  value,
  dataFetching,
  respStatusType,
  isWebview,
}) => {
  // 1, 2, 1-2 => index: 0, 1, 2 => woman, man, all
  const [universe, setUniverse] = useState(genId(value?.universe?.ids));
  const [womanSizeType, setWomanSizeType] = useState(sizeTypes[0][0]);
  const [manSizeType, setManSizeType] = useState(sizeTypes[1][0]);
  const [sizeTypeId, setSizeTypeId] = useState<string>(sizeTypes[0][0]);
  const [editValue, setEditValue] = useState(value);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDelConfirm, setOpenDelConfirm] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  // const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);
  const [respStatus, setRespStatus] = useState<RespStatusType>('success');
  const [isDelete, setIsDelete] = useState(false);
  const { t } = useTranslation();
  const { isWiderThanMd } = useWindowSize();

  useEffect(() => {
    setDialogLoading(dataFetching);
    setRespStatus(respStatusType);
  }, [dataFetching, respStatusType]);

  const handleUniverseChange = (val: number) => {
    setUniverse(universesMap[val]);
  };

  useEffect(() => {
    if (universe === '2') {
      setSizeTypeId(manSizeType);
      return;
    }
    setSizeTypeId(womanSizeType);
  }, [universe]);

  const handleWomanSizeTypeChange = (id: string) => {
    setSizeTypeId(id);
    setWomanSizeType(id);
  };
  const handleManSizeTypeChange = (id: string) => {
    setSizeTypeId(id);
    setManSizeType(id);
  };

  const womanSizes = useMemo(() => {
    if (!options || !options.length) {
      return [];
    }
    const opt = options[0].size_categories.filter((s) => s.id === womanSizeType);
    return opt.length ? opt[0].sizes : [];
  }, [womanSizeType, options]);

  const manSizes = useMemo(() => {
    if (!options || !options.length) {
      return [];
    }
    const opt = options[1].size_categories.filter((s) => s.id === manSizeType);
    return opt.length ? opt[0].sizes : [];
  }, [manSizeType, options]);

  // update editvalue
  const handleSizeChange = (val, item, checked, sizes, sizeType) => {
    let newValue;
    if (!editValue) {
      newValue = {
        size_categories: [],
        universe: {
          label: getUniverseLabelById(options, universe),
          ids: genIds(universe),
        },
      };
    }
    newValue = { ...editValue };
    let sizeCategories = [...newValue.size_categories];
    let tmp = sizeCategories.find((s) => s.id === sizeType);
    if (tmp === undefined) {
      tmp = {
        id: sizeType,
        label: getCatLabelById(options, sizeType),
        sizes: [],
      };
    } else {
      tmp = { ...tmp };
    }
    if (checked) {
      tmp.sizes = [...tmp.sizes];
      tmp.sizes.push(item);
    } else {
      tmp.sizes = tmp.sizes.filter((t) => t.id !== item.id);
    }
    sizeCategories = sizeCategories.filter((s) => s.id !== sizeType);
    sizeCategories.push(tmp);
    newValue.size_categories = sizeCategories;
    setEditValue(newValue);
  };

  // edit callback
  const saveCallback = () => {
    // filter the editvalue of specific universe
    const universeObj = getUniverseObjByUniverse(options, universe);
    editValue.universe = universeObj;
    const index = universesMap.findIndex((e) => e === universe);
    const sids = sizeTypes[index];
    if (universe !== '1-2') {
      editValue.size_categories = editValue.size_categories.filter((e) => sids.includes((e as SizeCatType).id));
    }
    // promise
    onChange(editValue);
    // setDialogOpen(false);
  };

  const openDeleteMysizesConfirmDialog = () => {
    setOpenDelConfirm(true);
  };

  // delete callback
  const handleDeleteMysizes = () => {
    setIsDelete(true);
    // promise
    onChange({
      universe: { ids: [] },
      size_categories: [],
    } as Sizes);
    // setDialogOpen(false);
    setOpenDelConfirm(false);
  };

  useEffect(() => {
    if (dialogOpen) {
      setEditValue(value);
      setUniverse(genId(value?.universe?.ids));
    }
  }, [dialogOpen]);

  return (
    <>
      <Dialog
        focusTrapActive={false}
        isOpen={dialogOpen}
        closeDialog={() => {
          feedbackCallback();
          setDialogOpen(false);
        }}
        styles={{
          content: {
            maxWidth: '654px',
            width: '100%',
            flexDirection: 'column',
            paddingBottom: '0.5rem',
            paddingTop: '1.7rem',
          },
        }}
        hasCloseIcon={!isWebview}
        hasCloseTxt
        customClass={styles.editingDialog}
      >
        <FeedbackWrap
          loading={dialogLoading}
          respStatus={respStatus}
          title={isDelete ? t('PLP.MYSIZES_DIALOG.DELETE_RESULT.TITLE') : undefined}
          feedbackCallback={() => {
            feedbackCallback();
            setDialogOpen(false);
          }}
        >
          <>
            <div className={styles.interests}>
              {isWiderThanMd && (
                <button
                  className={clsx(styles.delete, { [styles.show]: openDelete })}
                  onMouseDown={openDeleteMysizesConfirmDialog}
                  type="button"
                >
                  <Icon
                    name="trash-can"
                    className={styles.deleteIcon}
                  />
                  {t('PLP.MYSIZES_DIALOG.DELETE_BUTTON')}
                </button>
              )}
              <button
                className={clsx(styles.moreIcon, { [styles['moreIcon--webview']]: isWebview })}
                onClick={() => setOpenDelete(!openDelete)}
                onBlur={() => isWiderThanMd && setOpenDelete(false)}
                tabIndex={0}
              >
                <Icon name="more" />
              </button>
              <span>{t('PLP.MYSIZES_DIALOG.EDIT.TITLE')}</span>
            </div>

            <div className={styles.stepsContainer}>
              <UniverseTab
                value={universesMap.findIndex((e) => universe === e)}
                onChange={handleUniverseChange}
              />

              {(universe === '1' || universe === '1-2') && (
                <>
                  {/* woman's sizes type */}
                  <div className={styles.sizeTypeTitle}>{t('PLP.MYSIZES_DIALOG.WOMEN_SIZES')}</div>
                  <div className={styles.toggleBtnContainer}>
                    <ToggleButton
                      options={genSizeTypes(options, 0, editValue)}
                      value={womanSizeType}
                      onChange={(id) => handleWomanSizeTypeChange(id)}
                    />
                  </div>
                  {/* specific size type */}
                  <div className={styles.sizesContainter}>
                    <SizeAccordion />
                    <div className={styles.sizes}>
                      {womanSizes.map((item, index) => (
                        <TextSwatch
                          key={`${item.label}${index}`}
                          htmlId={`${index}`}
                          customClass={styles.textSwatchOverwrite}
                          checked={getSizeIds(editValue, womanSizeType).includes(item.id)}
                          onChange={(e, val?, checked?) => {
                            handleSizeChange(val, item, checked, womanSizes, womanSizeType);
                          }}
                          label={item.label}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}

              {(universe === '2' || universe === '1-2') && (
                <>
                  {/* man's sizes type*/}
                  <div className={styles.sizeTypeTitle}>{t('PLP.MYSIZES_DIALOG.MEN_SIZES')}</div>
                  <div className={styles.toggleBtnContainer}>
                    <ToggleButton
                      options={genSizeTypes(options, 1, editValue)}
                      value={manSizeType}
                      onChange={(id) => handleManSizeTypeChange(id)}
                    />
                  </div>
                  {/* specific size type */}
                  <div className={styles.sizesContainter}>
                    <SizeAccordion />
                    <div className={styles.sizes}>
                      {manSizes.map((item, index) => (
                        <TextSwatch
                          key={`${item.label}${index}`}
                          htmlId={`${index}`}
                          customClass={styles.textSwatchOverwrite}
                          checked={getSizeIds(editValue, manSizeType).includes(item.id)}
                          onChange={(e, val?, checked?) => {
                            handleSizeChange(val, item, checked, manSizes, manSizeType);
                          }}
                          label={item.label}
                        />
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>

            <MySizesFooter
              className={styles.footerStyleOverwrite}
              lastStep={true}
              lastStepCallback={saveCallback}
              hasChecked={true}
              handleNext={() => {}}
              allAvailableSteps={options}
              sizeId={sizeTypeId}
            />
          </>
        </FeedbackWrap>
      </Dialog>

      {!isWiderThanMd && (
        <EditingAppSheet
          open={openDelete}
          onDelete={() => {
            setOpenDelete(false);
            openDeleteMysizesConfirmDialog();
          }}
          onClose={() => setOpenDelete(false)}
        />
      )}

      <ConfirmDialog
        open={openDelConfirm}
        setOpen={setOpenDelConfirm}
        title={t('PLP.MYSIZES_DIALOG.DELETE_BUTTON')}
        content={t('PLP.MYSIZES_DIALOG.DELETE_CONFIRM')}
        confirmBtnTxt={t('PLP.MYSIZES_DIALOG.CONFIRM_DELETE')}
        onCancel={() => {
          setOpenDelConfirm(false);
        }}
        onConfirm={handleDeleteMysizes}
      />
    </>
  );
};

export default Editing;
