import React, { FCWithChildren } from 'react';
import { createContext } from 'use-context-selector';
import { RecentSearch } from '@hooks/search/use-recent-searches';
import useRecentSearchesWithCount from '@hooks/search/use-recent-searches-with-count';

type SearchBarRecentSearchesContextValues = {
  errorMessage: string;
  setErrorMessage: (message: string) => void;
  recentSearches: RecentSearch[];
  removeRecentSearchEntry: (id: string) => void;
  recentSearchMaximumCountLimit: number;
  saveRecentSearch: ({ recentSearchId, savedSearchId }: { recentSearchId: string; savedSearchId: string }) => void;
  unsaveRecentSearch: (id: string) => void;
};

const SearchBarRecentSearchesContext = createContext<SearchBarRecentSearchesContextValues>({} as never);

const SearchBarRecentSearchesProvider: FCWithChildren = (props): React.JSX.Element => {
  const { children } = props;

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const {
    recentSearches,
    removeRecentSearchEntry,
    recentSearchMaximumCountLimit,
    saveRecentSearch,
    unsaveRecentSearch,
  } = useRecentSearchesWithCount({
    doFetchCount: true,
  });

  return (
    <SearchBarRecentSearchesContext.Provider
      value={{
        errorMessage,
        setErrorMessage,
        recentSearches,
        removeRecentSearchEntry,
        recentSearchMaximumCountLimit,
        saveRecentSearch,
        unsaveRecentSearch,
      }}
    >
      {children}
    </SearchBarRecentSearchesContext.Provider>
  );
};

export { SearchBarRecentSearchesProvider, SearchBarRecentSearchesContext };
