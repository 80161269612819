import React from 'react';
import { GroupProps } from '@components/molecules/description/description';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from '../description.module.scss';

interface CTAItemProps {
  item: GroupProps;
  index: number;
  ctaTextColor: string;
  ctaBgColor: string;
  label: string;
  contentTypeUID: string;
  category: { category?: string };
}

type InnerStyleType = {
  color?: string;
  background?: string;
};

const CTAItem = ({ item, index, ctaTextColor, ctaBgColor, label, contentTypeUID, category }: CTAItemProps) => {
  const { url, ctaText, target } = item;
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');

  const innerStyle: InnerStyleType = {};

  if (ctaTextColor) {
    innerStyle.color = ctaTextColor;
  }

  if (ctaBgColor) {
    innerStyle.background = ctaBgColor;
  }

  const trackDescriptionCTAClick = (e) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      label: category?.category,
      property: label,
      value: String(index || ''),
    });

    e.stopPropagation();
  };

  return (
    <a
      target={target || '_self'}
      itemProp="url"
      href={url}
      onClick={(e) => trackDescriptionCTAClick(e)}
      className={styles.cta}
      style={innerStyle}
    >
      {ctaText}
    </a>
  );
};

export default CTAItem;
