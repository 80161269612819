import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { InnerFeedContext } from '@interfaces/models/elasticSearch';
import logger from '@helpers/utils/logger/client';
import useRecentSearchParams from '@hooks/catalog-context/use-recent-search-params';

const useInnerFeedContextParam = () => {
  const { isRecentSearch } = useRecentSearchParams();

  const { query } = useRouter();

  return useMemo(() => {
    if (query?.innerFeedContext) {
      if (query.innerFeedContext === InnerFeedContext.DealHunting) {
        return query.innerFeedContext;
      }
      logger.info(`Invalid innerFeedContext query parameter: ${query.innerFeedContext}`);
    }

    return isRecentSearch ? InnerFeedContext.RecentSearches : InnerFeedContext.GenericPLP;
  }, [isRecentSearch, query]);
};

export default useInnerFeedContextParam;
