import React, { useCallback, useMemo } from 'react';
import { IContentTypeEntries } from '@constants/metas/default';
import { ContentStackComponentKeys } from '@enums/contentStack';
import { getComponentByKey, getComponentKey, isSectionComponentType, buildFallbackComponents } from './components-map';

interface CMSContentContainerProps {
  sections: Array<String>;
  contentTypeEntry: IContentTypeEntries;
  elementStartingPosition?: number | undefined;
}

const CMSContentContainer: React.FC<CMSContentContainerProps> = ({ sections, contentTypeEntry }): React.JSX.Element => {
  let elementPosition = 0;
  const { fallback_components: fallbackComponentsDefs } = contentTypeEntry;

  const fallbackComponents = useMemo(() => {
    if (fallbackComponentsDefs?.length) {
      return buildFallbackComponents(fallbackComponentsDefs);
    }

    return null;
  }, [fallbackComponentsDefs]);

  const pageType = contentTypeEntry?.contentTypeId;
  const getSectionComponent = useCallback(getComponentByKey, []);

  const renderComponentSections = useCallback(
    (componentSections, sectionKey?) => {
      if (isSectionComponentType(sectionKey)) {
        return componentSections?.map((contentSection) => {
          const sectionName = Object.keys(contentSection)?.[0];

          if (!sectionName) {
            return null;
          }

          const nestedComponentSections =
            contentSection?.[sectionName]?.reference ||
            contentSection?.[sectionName]?.[sectionName] ||
            contentSection?.[sectionName] ||
            [];

          return renderComponentSections(nestedComponentSections, sectionName);
        });
      }

      const isHorizontalSlider = componentSections?.data?.arrangement === 'horizontal_slider';

      if (componentSections.data?.productType === 'recently_viewed' && !isHorizontalSlider) {
        componentSections.type = 'product_recently_viewed';
      }

      if (sectionKey === ContentStackComponentKeys.CAMPAIGN) {
        if (Array.isArray(componentSections) && !componentSections[0].blocks) {
          componentSections[0].blocks = componentSections[0]?.campaign_blocks ?? [];
          componentSections[0].categoryContainer = componentSections[0]?.category_container ?? [];
        } else {
          componentSections.blocks = componentSections?.campaign_blocks ?? [];
          componentSections.categoryContainer = componentSections?.category_container ?? [];
        }
      }

      const SectionComponent = getSectionComponent(sectionKey ?? componentSections?.type);
      if (!SectionComponent) {
        return null;
      }

      return (Array.isArray(componentSections) ? componentSections : [componentSections])?.map(
        (componentProps, idx) => {
          elementPosition++;
          const currentElementPosition = elementPosition;
          const cmpData = componentProps?.data ?? componentProps;
          const key = getComponentKey(sectionKey || componentProps?.type, cmpData.uid, idx);
          return (
            <SectionComponent
              key={key}
              {...{ pageType }}
              componentID={key}
              {...cmpData}
              fallbackComponents={fallbackComponents}
              sectionIdx={String(currentElementPosition ?? '')}
            />
          );
        },
      );
    },
    [elementPosition, pageType, getSectionComponent, fallbackComponents],
  );

  const cmsContainers = useMemo(() => {
    if (!contentTypeEntry) {
      return null;
    }

    if (!contentTypeEntry?.components) {
      // Old API response version
      return sections?.map((sectionKey) => {
        if (!sectionKey || !contentTypeEntry?.[sectionKey as string]) {
          return null;
        }

        return renderComponentSections(contentTypeEntry?.[sectionKey as string], sectionKey);
      });
    }

    return contentTypeEntry?.components?.map((type) => {
      if (!contentTypeEntry?.components) {
        return null;
      }
      return renderComponentSections(type);
    });
  }, [sections, contentTypeEntry, renderComponentSections]);

  return <>{cmsContainers}</>;
};

export default CMSContentContainer;
