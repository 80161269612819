import React from 'react';
import clsx from 'clsx';
import Icon from '@components/atoms/icon/icon';
import styles from './card-image.module.scss';

type CardImageProps = {
  onClick: () => void;
  imageUrl: string;
  title?: string;
  icon?: string;
} & React.HTMLProps<HTMLButtonElement>;

const CardImage: React.FC<CardImageProps> = (props) => {
  const { onClick, imageUrl = '', title, icon } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.card}
    >
      <div
        className={styles.card__image}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      />
      {title && <span className={clsx(styles.card__title, 'vc-text-xl')}>{title}</span>}
      {icon && (
        <Icon
          name={icon}
          className={styles.card__icon}
        />
      )}
    </button>
  );
};

export default CardImage;
