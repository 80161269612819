import { useQuery } from '@tanstack/react-query';
import { GlobalQueryKeys } from '@enums/react-query-keys';
import { DirectShipping } from '@interfaces/models/direct-shipping';
import { ShippingPlan } from '@interfaces/models/shipping-plan';
import { getDirectShippingEligibility } from '@api/shipping';
import { usePreferences } from '@context/preferences.context';

const useDirectShippingEligibilityQuery = () => {
  const { country } = usePreferences();

  const { data: directShippingEligibility } = useQuery<ShippingPlan>({
    queryKey: [GlobalQueryKeys.DIRECT_SHIPPING_ELIGIBILITY, country],
    staleTime: Infinity,
    queryFn: () => getDirectShippingEligibility({ country }),
  });

  const isUserDirectShippingEligible: boolean =
    directShippingEligibility?.shippingPlan?.some((plan: DirectShipping) => plan?.directShipping) ?? false;

  return {
    isUserDirectShippingEligible,
  };
};

export default useDirectShippingEligibilityQuery;
