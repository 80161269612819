export const FAV_CONSTANTS = {
  FAV_DEFAULT_LIMIT: 12,
  FAV_DEFAULT_SORT_ORDER: 'dateDesc',
  FAV_DEFAULT_OFFSET: 0,
  FAV_SCREEN_CONTEXT: {
    env_template: 'my_favourites',
    screen_name: '/my_favourites',
    screen_category: 'my_saved_items',
    screen_subcategory: 'my_favourites',
    pageUrl: '/my_favourites',
  },
};
