import Icon from '@components/atoms/icon/icon';
import { SearchContext } from '@context/search.context';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import dynamic from 'next/dynamic';
import styles from '../search-bar.module.scss';

const ClearInputButton = dynamic(() => import('./clear-button'));

interface SearchButtonProps {
  setIsSearchHeaderOpen: (value: React.SetStateAction<boolean>) => void;
}

const SearchButton: React.FC<SearchButtonProps> = (props) => {
  const { setIsSearchHeaderOpen } = props;

  const { t } = useTranslation('default-layout');
  const searchQuery = useContextSelector(SearchContext, (v) => v.searchQuery);
  const { sendAnalyticEvent } = useAnalyticEvents('search_modal');

  return (
    <div
      className={clsx(
        styles.searchbar__headerContainer,
        searchQuery !== '' && styles['searchbar__headerContainer--active'],
      )}
    >
      <button
        data-cy="search_button"
        type="button"
        onClick={() => {
          setIsSearchHeaderOpen(true);
          sendAnalyticEvent('open_search');
        }}
        tabIndex={0}
        className={clsx(styles.searchbar__modalButton, searchQuery !== '' && styles['searchbar__modalButton--filled'])}
      >
        <Icon
          className={styles.searchbar__modalButtonIcon}
          name="search"
        />
        {searchQuery !== '' ? searchQuery : t('HEADER.SEARCH.INPUT_PLACEHOLDER')}
      </button>
      {searchQuery !== '' && <ClearInputButton className={styles.searchbar__clearButton} />}
    </div>
  );
};

export default SearchButton;
