import React from 'react';
import clsx from 'clsx';
import TextSwatch from '@components/atoms/text-swatch/text-swatch';
import { SizeCatType, SizeObjType } from '@interfaces/models/mySizes';
import Environment from '@config/environment';
import SizeAccordion from '../size-accordion/size-accordion';
import styles from './steps.module.scss';

export type StepSizeProps = {
  sizeData: SizeCatType;
  changeHandler: (val: string, item: SizeObjType, checked: boolean, sizeData: SizeCatType) => void;
} & React.HTMLProps<HTMLButtonElement>;

const StepSize: React.FC<StepSizeProps> = ({ sizeData, changeHandler }) => {
  return (
    <div className={styles.step}>
      <div className={styles.titleContainer}>
        <div className={styles.stepFigureContainer}>
          <figure className={styles.stepFigure}>
            <img
              className={styles.stepImage}
              src={`${Environment.assetsPath}/images/personalization/category-${sizeData.id}.png`}
              alt=""
            />
          </figure>
        </div>

        <div className={clsx(styles.title, 'vc-text-xl')}>{sizeData.label}</div>
      </div>

      <div className={styles.sizesContainter}>
        <SizeAccordion customClass={styles.sizesContainter__accordion} />
        <div className={styles.sizes}>
          {sizeData?.sizes?.map((item, index) => (
            <TextSwatch
              key={`${sizeData.label}${index}`}
              htmlId={`${index}`}
              customClass={styles.textSwatchOverwrite}
              checked={false}
              onChange={(e, val?, checked?) => {
                changeHandler(val, item, checked, sizeData);
              }}
              label={item.label}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StepSize;
