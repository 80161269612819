import React, { useCallback } from 'react';
import useOnReveal from '@hooks/use-on-reveal';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import { ChannelBlock as IChannelBlock } from '@interfaces/models/channel';
import CMSImages from '@components/atoms/vc-images/components/cms-image';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { getCatalogDatalayerEntrypoint } from '@helpers/utils/catalog/catalog-tracking-utils';
import styles from './channel-block.module.scss';

const ChannelBlock: React.FC<IChannelBlock> = (props): React.JSX.Element => {
  const {
    image,
    url,
    displayTitle: title,
    title: label,
    contentTypeUid: contentTypeUID,
    idx,
    category,
    uid: channelBlockId,
    sectionIdx,
  } = props;

  const { sendAnalyticEvent, updateDataLayer } = useAnalyticEvents('cms_component');
  const trackingLabel = `${String(idx + 1)}_${sectionIdx}`;
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const blockId = `HP_${channelBlockId}`;
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        label: trackingLabel,
        property: `${channelBlockId}_${label}`,
      });
    }, [contentTypeUID, category?.category, label, idx, channelBlockId]),
    0.5,
    false,
  );

  if (!url) {
    return null;
  }

  const { filename: imgXName, url: imgPath, title: imgTitle } = image || {};

  const trackChannelBlockClick = (e) => {
    updateDataLayer(
      getCatalogDatalayerEntrypoint({
        source_category: 'cms_block',
        cms_block_id: channelBlockId,
      }),
    );
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      label: trackingLabel,
      property: `${channelBlockId}_${label}`,
    });
    setHpScrollPosition(blockId);
    e.stopPropagation();
  };

  return (
    <div
      ref={containerRef}
      className={styles.channel}
      id={blockId}
    >
      <a
        href={url}
        onClick={trackChannelBlockClick}
        target={'_self'}
        className={styles.channel__a}
      >
        <div className={styles.channel__img__container}>
          <CMSImages
            src={imgPath}
            className={styles.channel__img}
            data-src={imgXName}
            srcSetOpts={[
              { width: '64', height: '64', descriptor: '420w' },
              { width: '75', height: '75', descriptor: '780w' },
            ]}
            type="edito"
            alt={imgTitle}
            width={640}
            height={640}
          />
        </div>
        <p className={styles.channel__p}>{title}</p>
      </a>
    </div>
  );
};

export default ChannelBlock;
