import React from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import CardImage from '@components/catalog/my-sizes/card-image/card-image';
import Environment from '@config/index';

import styles from './steps.module.scss';

// "1" = woman | "2" = men | "1-2" = men & woman
export type PersonalizationUniverse = 0 | 1 | 2 | undefined;

export type ImageCard = {
  title: string;
  icon: string;
  imageUrl: string;
  universe: PersonalizationUniverse;
  ids: string[];
};

export type StepOneProps = {
  onSelect: (universe: ImageCard) => void;
} & React.HTMLProps<HTMLButtonElement>;

const StepOne: React.FC<StepOneProps> = (props) => {
  const { onSelect } = props;
  const { t } = useTranslation();

  const cards = [
    {
      title: t('PLP.MYSIZES_DIALOG.WOMEN_SIZES'),
      icon: 'arrow-head-left-thick',
      imageUrl: `${Environment.cdnFolder}/assets/images/mySize/universe-1.webp`,
      universe: 0,
      ids: ['1'],
    },
    {
      title: t('PLP.MYSIZES_DIALOG.MEN_SIZES'),
      icon: 'arrow-head-left-thick',
      imageUrl: `${Environment.cdnFolder}/assets/images/mySize/universe-2.webp`,
      universe: 1,
      ids: ['2'],
    },
    {
      title: t('PLP.MYSIZES_DIALOG.WOMEN_MEN_SIZES'),
      icon: 'arrow-head-left-thick',
      imageUrl: `${Environment.cdnFolder}/assets/images/mySize/universe-1-2.webp`,
      universe: 2,
      ids: ['1', '2'],
    },
  ];

  const handleClick = (universe: ImageCard) => {
    onSelect(universe);
  };

  return (
    <div className={styles.step}>
      <div className={styles.titleContainer}>
        <div className={clsx(styles.title, 'vc-text-xl')}>{t('PERSO.FILTER_NOT_SET.TITLE')}</div>

        <div className={clsx(styles.subTitle, 'vc-text-m')}>{t('PERSO.FILTER_NOT_SET.SUBTITLE')}</div>
      </div>
      <div
        className={clsx(styles.container, styles.firstStep)}
        data-cy="mysizes-step-one"
      >
        {(cards || []).map((card: ImageCard) => (
          <CardImage
            key={card.universe}
            title={t(card.title)}
            icon={card.icon}
            imageUrl={card.imageUrl}
            onClick={() => handleClick(card)}
          />
        ))}
      </div>
    </div>
  );
};

export default StepOne;
