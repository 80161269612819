import React, { useState } from 'react';
import clsx from 'clsx';

import { Product } from '@interfaces/models/product';
import ISizeGuide from '@components/size-guide/size-guide';

import styles from './size-guide-modal.module.scss';

type SizeGuideModalProps = {
  universeId: Product['universe']['id'];
  productCategoryId: Product['category']['id'];
  sizeGuideStandard: Product['size']['standard'];
  // If size is present, we can color the row and the cell in the table
  sizeGuideSize?: Product['size']['size'];
};

const SizeGuideModal: React.FC<SizeGuideModalProps> = (props): React.JSX.Element => {
  const [title, setTitle] = useState('');

  return (
    <>
      <div className={clsx('vc-modal__title', styles.sizeGuide__title)}>{title}</div>
      <div className={clsx('vc-modal__content', styles.sizeGuide__modalContent)}>
        <ISizeGuide
          {...props}
          onTitleChange={setTitle}
        />
      </div>
    </>
  );
};

export default SizeGuideModal;
