import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import style from './toggle-button.module.scss';

export type OptionType = {
  label: string;
  value: string;
  icon?: string | React.JSX.Element;
};

type ToggleButtonProps = {
  options: OptionType[];
  value: string;
  onChange: (value: string) => void;
} & React.HTMLProps<HTMLDivElement>;

const ToggleButton: React.FC<ToggleButtonProps> = (props): React.JSX.Element => {
  const { options, value, onChange } = props;

  const optionRefs = useRef<HTMLButtonElement[]>([]);

  const optionValues = useMemo(() => options.map((e) => e.value), [options]);

  const handleArrowKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (!(e.code === 'ArrowLeft' || e.code === 'ArrowRight')) {
      return;
    }

    let curIndex: number = optionValues.indexOf(value);

    if (e.code === 'ArrowLeft') {
      curIndex = curIndex - 1 < 0 ? options.length - 1 : curIndex - 1;
    } else {
      curIndex = curIndex + 1 > options.length - 1 ? 0 : curIndex + 1;
    }
    onChange(optionValues[curIndex]);
    optionRefs.current[curIndex].focus();
  };

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        role="tabpanel"
        onKeyDown={(e) => handleArrowKeyPress(e)}
        className={style['toggle-button-container']}
      >
        {options.map((opt) => (
          <button
            type="button"
            key={opt.value}
            ref={(elem) => {
              optionRefs.current.push(elem);
            }}
            onClick={() => onChange(opt.value)}
            className={clsx(style['toggle-button'], {
              [style['toggle-button__active']]: value === opt.value,
            })}
          >
            <div className={style['toggle-button-icon']}>
              {typeof opt.icon === 'string' ? (
                <img
                  src={opt.icon}
                  alt="button-icon"
                />
              ) : (
                opt.icon
              )}
            </div>
            <p className={style['toggle-button-text']}>{opt.label || '-'}</p>
          </button>
        ))}
      </div>
    </>
  );
};

export default ToggleButton;
