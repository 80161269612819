import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MySizes as Sizes, WizardStepType } from '@interfaces/models/mySizes';
import { useContextSelector } from 'use-context-selector';
import { UserContext } from '@context/user.context';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { RespStatusType } from '@components/catalog/my-sizes/my-sizes/response-feedback/types';
import AddMySizesDialog from '@components/catalog/my-sizes/my-sizes/add-my-sizes-dialog/add-my-sizes-dialog';
import Editing from '@components/catalog/my-sizes/my-sizes/editing/editing';
import logger from '@helpers/utils/logger/client';
import { sendAppEvent } from '@helpers/utils/send-app-event';
import { useQuery } from '@tanstack/react-query';
import { MySizesQueryKeys } from '@enums/react-query-keys';
import { ApiResponse } from '@interfaces/api';
import { getSearchPreferencesOptions } from '@api/search-preferences';
import useUser from '@hooks/user/use-user';

interface DialogWrapperProps {
  dialogOpen: boolean;
  setDialogOpen: (v: boolean) => void;
  canActivateSizes: boolean;
  isWebview?: boolean;
}

const DialogWrapper = ({ dialogOpen, setDialogOpen, canActivateSizes, isWebview }: DialogWrapperProps) => {
  const { isAuthenticated: isUserLoggedIn } = useUser();
  const sizes = useContextSelector(UserContext, (v) => v.userSizes);
  const setUserSizes = useContextSelector(UserContext, (v) => v.setUserSizes);
  const editUserSizes = useContextSelector(UserContext, (v) => v.editUserSizes);
  const areUserSizesLoading = useContextSelector(UserContext, (v) => v.areUserSizesLoading);
  const userSizesError = useContextSelector(UserContext, (v) => v.userSizesError);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [respStatus, setRespStatus] = useState<RespStatusType>('none');
  const newSizesRef = useRef(null);
  const actionRef = useRef('');
  const { sendAnalyticEvent } = useAnalyticEvents('my_preferences');

  const doesUserHaveSizes = useMemo<boolean>(() => {
    return sizes?.universe?.ids.length > 0;
  }, [sizes]);

  const getSizeId = (currentStep: number, universe: number, allAvailableSteps: WizardStepType[]) => {
    if (!allAvailableSteps.length) {
      return '';
    }

    const category = allAvailableSteps[universe];
    const step = category.size_categories[currentStep - 1];

    return step.id;
  };

  const handleMySizeChange = async (currentSizes: Sizes) => {
    sendAppEvent('onboardingWriteStart', 'Drum Roll');
    const data = await editUserSizes(currentSizes);
    const isDeleteMsg = currentSizes.size_categories.length === 0 && currentSizes.universe.ids.length === 0;
    if (data) {
      sendAppEvent(isDeleteMsg ? 'onboardingDeleteDone' : 'onboardingWriteDone', 'Success!');
      // Send successfully update event to apps;
    } else {
      sendAppEvent('onboardingWriteFail', 'An error has occurred');
      // Send failed update event to apps;
    }
    return data;
  };

  const handleUpdateSizes = () => {
    if (newSizesRef.current && actionRef.current === 'update') {
      setUserSizes(newSizesRef.current);
      sendAnalyticEvent('edit_preferences_confirmation');
    }
  };

  useEffect(() => {
    if (actionRef.current === 'update') {
      if (!areUserSizesLoading) {
        setRespStatus(userSizesError ? 'error' : 'success');
      }
    }
  }, [userSizesError, areUserSizesLoading]);

  const { data, isLoading: isLoadingUserPreferenceOptions } = useQuery<ApiResponse<WizardStepType[]>>({
    queryKey: [MySizesQueryKeys.SEARCH_PREFERENCE_OPTION],
    queryFn: () => getSearchPreferencesOptions(),
    onSuccess: () => {
      setRespStatus('none');
    },
    onError: (err) => {
      setRespStatus('error');
      logger.error(err, 'Error occurred while fetching');
    },
    initialData: { data: [] },
    enabled: dialogOpen,
  });

  const allAvailableSteps = useMemo<WizardStepType[]>(
    () => (data?.data as ApiResponse<WizardStepType[]>)?.data ?? [],
    [data],
  );

  useEffect(() => {
    if (dialogOpen) {
      actionRef.current = '';
    } else {
      setCurrentStep(0);
    }
  }, [dialogOpen]);

  const dataFetching = isLoadingUserPreferenceOptions || areUserSizesLoading;

  if (!isUserLoggedIn) {
    return null;
  }

  if (!doesUserHaveSizes) {
    return (
      <AddMySizesDialog
        {...{
          dialogOpen,
          allAvailableSteps,
          actionRef,
          newSizesRef,
          handleUpdateSizes,
          getSizeId,
          dataFetching,
          respStatus,
          setDialogOpen,
          handleMySizeChange,
          setCurrentStep,
          currentStep,
          isWebview,
        }}
      />
    );
  }

  if (canActivateSizes) {
    return (
      <Editing
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        options={allAvailableSteps}
        value={sizes}
        feedbackCallback={handleUpdateSizes}
        onChange={(val) => {
          actionRef.current = 'update';
          handleMySizeChange(val).then((data) => {
            if (data) {
              newSizesRef.current = data;
            }
          });
        }}
        dataFetching={dataFetching}
        respStatusType={respStatus}
        isWebview={isWebview}
      />
    );
  }

  return null;
};

export default DialogWrapper;
