import { useAnalytics } from '@context/analytics.context';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import TagContainer from '@enums/tagContainer';
import { ProductAddToFavourite } from '@interfaces/models/internal/analytics-event/product-event';
import { pickSearchContextAttributes } from '@helpers/utils/catalog/catalog-tracking-utils';
import useUser from '@hooks/user/use-user';
import { useRouter } from 'next/router';
import useProfileQuery from '@hooks/profile/use-profile-query';

export const useTrackProductLikeAnalytics = ({
  product,
  position,
  parentPosition,
  pageType,
  moduleType,
  alertTracking,
  productFeedCategory,
}) => {
  const { isUserProfile } = useProfileQuery();
  const { user } = useUser();
  const router = useRouter();
  const { sendEvent } = useAnalytics();
  const analyticEvents = {
    alerts: useAnalyticEvents('alerts').sendAnalyticEvent,
    product: useAnalyticEvents('product').sendAnalyticEvent,
    catalog: useAnalyticEvents('catalog').sendAnalyticEvent,
    profile: useAnalyticEvents('profile_page').sendAnalyticEvent,
    favorites: useAnalyticEvents('favorites').sendAnalyticEvent,
  };

  const { favoritesUserSlug } = router?.query as {
    favoritesUserSlug: string;
  };

  const isUserFavoritesPage = favoritesUserSlug?.[0] === user?.id;
  const isProfileFavoritesPage = pageType === 'profile-favorites';
  const profileItemType = isUserProfile ? 'my' : 'member';
  const isCMSPage = pageType === 'cms_page' || pageType === 'homepage';

  const sharedPayload = {
    product_id: String(product?.id || ''),
    seller_id: String(product?.seller?.id || ''),
    product_universe: product?.universe?.name ?? '',
    product_category: product?.category?.name ?? '',
    product_sub_category: product.subcategory?.name ?? '',
    product_brand: product?.brand?.name ?? '',
    product_unit_price_ati: ((product?.price?.cents ?? 0) / 100 ?? '').toString(),
    product_country: product?.seller?.country ?? '',
    product_position: String(position ?? ''),
    currency: product.price?.currency ?? '',
    product_card_tag: product?.tags?.join(',') ?? '',
  };

  const trackProductAnalytics = (eventType, eventAction, additionalPayload) => {
    const payload = {
      ...sharedPayload,
      event: eventType,
      category: 'like',
      action: eventAction,
      ...additionalPayload,
    };

    if (eventAction === 'add_from_favourites') {
      sendEvent(
        {
          type: 'event',
          container: TagContainer.Analytics,
          payload: {
            event: 'productAddToFavorite',
            addToFavoriteType: pageType,
            product_brand: product?.brand?.name,
            product_category: product?.category?.name,
            product_univers: product?.universe?.name,
            product_id: product?.id,
          } as ProductAddToFavourite,
        },
        {
          type: 'add_to_Fav',
          container: TagContainer.Media,
        } as never,
      );
    }

    if (pageType === 'cms_page' || pageType === 'homepage') {
      sendEvent({
        type: 'event',
        payload: {
          event: eventType,
          ...payload,
          product_card_tag: product.tags?.join(',') ?? '',
          product_feed_category: moduleType,
          product_feed_position: parentPosition,
        },
      });
    } else if (pageType === 'alerts') {
      analyticEvents.alerts(eventType, {
        ...payload,
        product_card_tag: alertTracking.alertStatus,
        product_feed_category: alertTracking.alertTitle,
        product_unit_price_ati: ((product?.price?.cents ?? 0) / 100).toString(),
        product_position: String(product?.position),
      });
    } else if (pageType === 'favourite') {
      analyticEvents.favorites(eventType, {
        ...payload,
        product_feed_category: moduleType || (isUserFavoritesPage ? 'my_favourites' : 'member_favourites'),
      });
    } else if (pageType === 'catalog') {
      analyticEvents.catalog(eventAction, {
        ...payload,
        label: product.id,
        ...pickSearchContextAttributes([
          'keyword',
          'index_name',
          'are_filters_active',
          'filters_details',
          'catalog_brand',
          'catalog_universe',
          'catalog_category',
          'catalog_subcategory',
          'search_query_id',
          'catalog_version',
          'nb_results',
        ]),
      });
    } else if (pageType === 'product_page' || pageType === 'hero-pdp') {
      analyticEvents.product(eventType, {
        ...payload,
        label: product.id,
        product_feed_category: productFeedCategory || moduleType || '',
      });
    } else if (pageType === 'profile-favorites' || pageType === 'profile-items-for-sale') {
      const product_feed_category = isProfileFavoritesPage
        ? moduleType || `${profileItemType}_favourites`
        : `${profileItemType}_items_for_sale`;

      analyticEvents.profile(eventType, {
        ...payload,
        product_feed_category,
      });
    }
  };

  const trackOnLikeProduct = () => {
    const eventType = isCMSPage ? 'pdp_add_favourites' : 'prd_add_favourites';
    trackProductAnalytics(eventType, 'add_from_favourites', {});
  };

  const trackOnUnlikeProduct = () => {
    const eventType = isCMSPage ? 'pdp_remove_favourites' : 'prd_remove_favourites';
    trackProductAnalytics(eventType, 'remove_from_favourites', {});
  };

  return { trackOnLikeProduct, trackOnUnlikeProduct };
};

export default useTrackProductLikeAnalytics;
