import React, { FCWithChildren } from 'react';
import Spinner from '@components/atoms/spinner/spinner';
import styles from './response-feedback.module.scss';

interface LoadingProps {
  loading: boolean;
}

const Loading: FCWithChildren<LoadingProps> = ({ loading, children }) => {
  return (
    <div className={styles.loadingContainerWrap}>
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spinner />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Loading;
