import { useMemo } from 'react';
import { useContextSelector } from 'use-context-selector';
import { UserContext } from '@context/user.context';
import { usePreferences } from '@context/preferences.context';
import {
  mapCountryToTheSizeType,
  parseURLHashes,
  parseURLQueryParameters,
} from '@helpers/utils/catalog/catalog-url-helpers';
import { getMySizesState } from '@hooks/catalog-context/use-mysizes-state';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { getMySizesPayload } from '@hooks/catalog-context/use-fetch-products';
import SearchService, { ProductSearchHeaders } from '@services/search-service';
import { useQuery } from '@tanstack/react-query';
import { useBucket } from '@context/bucket.context';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { parseProducts } from '@helpers/utils/catalog/catalog-parsing-utils';
import logger from '@helpers/utils/logger/client';
import { ProductCatalogQueryKeys } from '@enums/react-query-keys';
import useUser from '@hooks/user/use-user';
import { AxiosHeaders, AxiosRequestHeaders } from 'axios';
import { getUserToken } from '@helpers/utils/catalog/catalog-tracking-utils';

interface UseCatalogProducts {
  cta?: {
    title: string;
    href: string;
  };
  isPersonalized: boolean;
  productFeedIndex?: 'ng-products-slave-recent' | 'ng-product-master';
  productType: string;
  maxProductCount?: number;
  isSliderHorizontal?: boolean;
}

const useProductCatalogProducts = ({
  cta,
  isPersonalized,
  productFeedIndex,
  productType,
  maxProductCount,
  isSliderHorizontal,
}: UseCatalogProducts) => {
  const userSizes = useContextSelector(UserContext, (v) => v.userSizes);
  const { user } = useUser();
  const { isFeatureEnabled } = useBucket();
  const isSliderEnabled = isFeatureEnabled((f) => f.sliderHomePage, true);
  const { country, currency, language, regionName } = usePreferences();

  const params = useMemo(() => {
    const { sortBy = 'relevance', query = '' } = cta?.href ? parseURLQueryParameters(cta?.href) : {};
    if (productFeedIndex && productType === 'we-love') {
      return {
        sortBy: productFeedIndex === 'ng-products-slave-recent' ? ('recency' as const) : ('relevance' as const),
        query,
      };
    }
    return { sortBy, query };
  }, [cta, productFeedIndex, productType]);

  const applicableSizeIds = useMemo(() => (isPersonalized ? [1, 2] : []), [isPersonalized]);

  const filters = useMemo(() => parseURLHashes(cta?.href).filters, [cta, applicableSizeIds]);

  const mySizesState = useMemo(
    () => getMySizesState(applicableSizeIds, userSizes, false),
    [applicableSizeIds, userSizes],
  );

  const requestPayload: Partial<ElasticSearch> = useMemo(
    () => ({
      q: params.query,
      pagination: {
        offset: 0,
        limit: isSliderEnabled && isSliderHorizontal ? maxProductCount ?? 5 : 5,
      },
      sortBy: params.sortBy as ElasticSearch['sortBy'],
      filters: filters,
      locale: {
        country,
        currency,
        language,
        sizeType: mapCountryToTheSizeType(regionName),
      },
      mySizes: getMySizesPayload(mySizesState),
    }),
    [
      params.query,
      params.sortBy,
      isSliderEnabled,
      maxProductCount,
      filters,
      country,
      currency,
      language,
      regionName,
      mySizesState,
    ],
  );

  const headers: AxiosRequestHeaders = new AxiosHeaders({
    [ProductSearchHeaders.userToken]: getUserToken(user?.id),
  });

  const { data, isLoading, isError } = useQuery<ElasticSearchResponse>({
    queryKey: [ProductCatalogQueryKeys.GET_PRODUCT_CATALOG, requestPayload, headers],
    queryFn: () => SearchService.productSearch(requestPayload, { headers }),
    onError: (err) => logger.error(err, 'Error fetching products!'),
    // waits for request of products until FF has loaded, to prevent duplicate requests
    enabled: typeof isSliderEnabled !== 'undefined',
  });

  const products = useMemo(() => {
    if (data?.items?.length > 0) {
      return parseProducts({ queryResult: data, country, currency, language });
    }
    return [];
  }, [currency, data, country, language]);

  return { products, isLoading, isError };
};

export default useProductCatalogProducts;
