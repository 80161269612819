import React from 'react';
import Accordion from '@components/catalog/accordion/accordion';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import styles from './size-accordion.module.scss';

const SizeAccordion = ({ customClass }: { customClass?: string }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      icon={
        <img
          alt="icon"
          src="https://assets-pp-images.vestiairecollective.com/assets/images/personalization/idea.png"
        />
      }
      type="warning"
      header={t('PLP.MYSIZES_DIALOG.TIP_TITLE')}
      customClass={clsx(styles.sizeAccordionWrap, customClass)}
    >
      {t('PLP.MYSIZES_DIALOG.TIP_CONTENT')}
    </Accordion>
  );
};

export default SizeAccordion;
