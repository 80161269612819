import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import styles from './close-button.module.scss';

interface CloseButtonProps {
  handleClick: Dispatch<SetStateAction<boolean>>;
  customClass?: string;
}

const CloseButton: React.FC<CloseButtonProps> = ({ handleClick, customClass }) => {
  const { t } = useTranslation();

  return (
    <button
      className={clsx(styles.closeButton, customClass)}
      aria-label={t('MODAL.BUTTON.CLOSE')}
      onClick={() => handleClick(false)}
      tabIndex={0}
      type="button"
      data-cy="close_button"
    />
  );
};

export default CloseButton;
