import { ElasticSearchFacet } from '@interfaces/api/responses/elasticSearch';
import { SortByMethod } from '@interfaces/models/catalogFilter';

export const sortAlphabetically = (itemA: ElasticSearchFacet['name'], itemB: ElasticSearchFacet['name']): number => {
  return itemB > itemA ? -1 : 1;
};

const sortNumerically = (itemA: ElasticSearchFacet['name'], itemB: ElasticSearchFacet['name']): number => {
  return parseInt(itemA) - parseInt(itemB);
};

// Sort clothing filter (XS to XXXXXL)
const sortClothingSizes = (itemA: ElasticSearchFacet['name'], itemB: ElasticSearchFacet['name']): number => {
  const order = [
    'xxxs',
    'xxs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    'xxl',
    'xxxl',
    'xxxxl',
    'xxxxxl',
    'xxxxxxl',
    'xxxxxxxl',
    'xxxxxxxxl',
    'xxxxxxxxxl',
  ];
  const parseItem: Function = (item: ElasticSearchFacet['name']) => {
    const [sizeNumber, symbol] = item.match(/\D+|\d+/g);

    // symbol can return undefined if no number is present in string
    return (!Boolean(symbol) ? item : `${'x'.repeat(Number(sizeNumber) - 1)}${symbol}`).toLocaleLowerCase();
  };

  return order.indexOf(parseItem(itemA)) - order.indexOf(parseItem(itemB));
};

export const sortFacetItems = (type: SortByMethod, itemA: ElasticSearchFacet, itemB: ElasticSearchFacet): number => {
  switch (type) {
    case 'sortById':
      return sortNumerically(itemA.id, itemB.id);
    case 'sortByNumericValue':
      return sortNumerically(itemA.name, itemB.name);
    case 'sortClothingSizes':
      return sortClothingSizes(itemA.name, itemB.name);
    case 'sortByCount':
      return sortNumerically(String(itemB.count), String(itemA.count));
    case 'asc':
      return sortAlphabetically(itemA.name, itemB.name);
    default:
      return sortNumerically(itemA.name, itemB.name);
  }
};
