import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import _styles from './text-swatch.module.scss';
import filterRedesignStyles from './v2/text-swatch.module.scss';

interface TextSwatchInterface {
  checked: boolean;
  label: React.JSX.Element | string;
  htmlId: string;
  customClass?: string;
  onChange?: (event: Event, value?: string, checked?: boolean) => void;
  useFilterRedesignUi?: boolean;
}

type TextSwatchProps = TextSwatchInterface & Omit<React.HTMLProps<HTMLDivElement>, 'label'>;

const TextSwatch: React.FC<TextSwatchProps & Omit<React.HTMLProps<HTMLDivElement>, 'label'>> = (props) => {
  const { checked, label, htmlId, onChange, customClass, tabIndex = 0, useFilterRedesignUi, ...rest } = props;

  const [checkedState, setCheckedState] = useState(checked);
  const styles = useMemo(() => (useFilterRedesignUi ? filterRedesignStyles : _styles), [useFilterRedesignUi]);

  const handleChange = (event, label) => {
    onChange(event, label, !checkedState);
    setCheckedState(!checkedState);
  };

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <div
      key={htmlId}
      role="button"
      className={clsx(styles.textSwatch, customClass, checkedState && styles.checked)}
      onClick={(e) => handleChange(e, label)}
      onKeyPress={(e) => {
        if (e.code === 'Space') {
          handleChange?.(e, label);
        }
      }}
      tabIndex={tabIndex}
      {...rest}
    >
      {label}
    </div>
  );
};

export default TextSwatch;
