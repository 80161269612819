export const FAST_FASHION_EDITO_URL = '/journal/think-first-buy-second';

const BANNED_BRANDS = [
  'abercrombie & fitch',
  'american apparel',
  'benetton',
  'bershka',
  'calzedonia',
  'desigual',
  'disney',
  'gap',
  'h&m',
  'hollister',
  'intimissimi',
  'jennyfer',
  'mango',
  'monki',
  'old navy',
  'only',
  'ovs',
  'oysho',
  'piazza italia',
  'pull & bear',
  'reserved',
  'stradivarius',
  'tally weijl',
  'tom tailor',
  'u.s. polo assn.',
  'uniqlo',
  'urban outfitters',
  'vero moda',
  'weekday',
  'zara',
  'forever 21',
  'primark',
  'new look',
  'wallis',
  'misspap',
  'debenhams',
  'asos',
  'atmosphère',
  'boohoo',
  'burton',
  'christopher kane for topshop',
  'cider',
  'coast',
  'dorothy perkins',
  'fashion nova',
  'karen millen',
  'kate moss for topshop',
  'marques almeida topshop',
  'mary katrantzou for topshop',
  'miss selfridge',
  'missguided',
  'na-kd',
  'nasty gal',
  'oasis',
  'pretty little thing',
  'shein',
  'tezenis',
  'topman',
  'topshop',
  'topshop boutique',
  'topshop unique',
  'topshop x j.w. anderson',
  'warehouse',
  'comme des garcons x h&m',
  'erdem x h&m',
  'isabel marant pour h&m',
  'jeff koons for h&m',
  'jimmy choo for h&m',
  'karl lagerfeld pour h&m',
  'maison martin margiela pour h&m',
  'matthew williamson for h&m',
  'moschino for h&m',
  'simone rocha x h&m',
  "the vampire's wife x h&m",
];

export const checkFFBannedWords = (searchQuery: string): boolean => {
  if (!searchQuery) {
    return false;
  }
  return BANNED_BRANDS.includes(searchQuery.toLowerCase());
};
