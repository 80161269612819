/*
  NOTE: Since we directly call the V1 content stack, it returns snake case property names.
  However, on our end (FENX), we use camelCase for consistency. We change all the property names to camelCase
  to align with the new V2 API for CMS. Edito and Journal will be removing the snake case check,
  which was originally in place only for the web, to align with all platforms.
*/

const reverseMappingProps: { [key: string]: string } = {
  display_title: 'displayTitle',
  content_type_id: 'contentTypeId',
  cta_text_color: 'ctaTextColor',
  cta_background_color: 'ctaBackgroundColor',
  is_personalizable: 'isPersonalizable',
  product_feed_index: 'productFeedIndex',
  content_type_uid: 'contentTypeUid',
  cta_text: 'ctaText',
  cta_position: 'ctaPosition',
  text_color: 'textColor',
  background_color: 'backgroundColor',
  metadata: '_metadata', // Property name remains unchanged
  edito_page_reference: 'editoPageReference',
  file_size: 'fileSize',
  content_type: 'contentType',
  body_background: 'bodyBackground',
  campaignBlocks: 'blocks', // Property name remains unchanged
  modular_blocks: 'modularBlocks',
  block_image: 'blockImage',
  block_subtitle: 'blockSubtitle',

  // Favorites Empty State CMS Block
  max_product_count: 'maxProductCount',
  min_product_count: 'minProductCount',
  product_type: 'productType',
  header_title: 'headerTitle',
  header_icon_url: 'headerIconUrl',
  header_description: 'headerDescription',
  header_cta_url: 'headerCtaUrl',
  header_cta_title: 'headerCtaTitle',
  header_cta_deeplink: 'headerCtaDeeplink',
};

const convertValueKeys = (val, converter: (key: string, obj) => string) => {
  if (typeof val !== 'object' || val === null) {
    return val;
  }

  if (Array.isArray(val)) {
    return val.map((v) => convertValueKeys(v, converter));
  }

  const convertedEntries = Object.entries(val).map(([key, value]) => {
    return [converter(key, val), convertValueKeys(value, converter)];
  });

  return Object.fromEntries(convertedEntries);
};

export const convertResponseData = (response) => {
  return (
    convertValueKeys(response, (key) => {
      return reverseMappingProps[key] || key;
    }) ?? {}
  );
};
