import { useDeviceSize } from '@context/device-size.context';

type WindowSize = {
  height: number;
  width: number;
};

type UseWindowSizeReturn = {
  windowSize: WindowSize;
  isWiderThanXs: boolean;
  isWiderThanSm: boolean;
  isWiderThanMd: boolean;
  isWiderThanLg: boolean;
  isWiderThanXl: boolean;
  isWiderThanMdAndSmallerThanLg: boolean;
  hasWindowSizeBeenSet: boolean;
};

const DEVICE_SIZES = {
  xs: 0,
  sm: 375,
  md: 768,
  lg: 1024,
  xl: 1440,
};

const useWindowSize = (): UseWindowSizeReturn => {
  const { windowSize, hasWindowSizeBeenSet } = useDeviceSize();

  const { width } = windowSize;

  const isWiderThanXs: boolean = width >= DEVICE_SIZES.xs;
  const isWiderThanSm: boolean = width >= DEVICE_SIZES.sm;
  const isWiderThanMd: boolean = width >= DEVICE_SIZES.md;
  const isWiderThanLg: boolean = width >= DEVICE_SIZES.lg;
  const isWiderThanXl: boolean = width >= DEVICE_SIZES.xl;
  const isWiderThanMdAndSmallerThanLg: boolean = width >= DEVICE_SIZES.md && width < DEVICE_SIZES.lg;

  return {
    hasWindowSizeBeenSet,
    windowSize,
    isWiderThanXs,
    isWiderThanSm,
    isWiderThanMd,
    isWiderThanLg,
    isWiderThanXl,
    isWiderThanMdAndSmallerThanLg,
  };
};

export default useWindowSize;
