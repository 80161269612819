import React from 'react';
import ReactPlayer, { YouTubePlayerProps as IYouTubePlayerProps } from 'react-player/youtube';

const YouTubePlayer: React.FC<IYouTubePlayerProps> = ({ url, ...rest }) => {
  return (
    <ReactPlayer
      {...{ url }}
      width="100%"
      height="100%"
      {...rest}
    />
  );
};

export default YouTubePlayer;
