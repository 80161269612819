import React from 'react';
import Icon from '@components/atoms/icon/icon';
import clsx from 'clsx';
import { CampaignCTA as CampaignCTAProps } from '@interfaces/models/campaign';
import Chip, { ChipFieldProps } from '@components/atoms/cms/chip/chip';
import useOnReveal from '@hooks/use-on-reveal';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from '../campaign-block.module.scss';
interface RevealChipsEventOnScrollProps {
  children: React.ReactNode;
  onReveal: () => void;
}

type CampaignCTAChipProps = {
  onClick: (e, chip: ChipFieldProps, chipXPos) => void;
} & CampaignCTAProps;

const RevealChipsEventOnScroll: React.FC<RevealChipsEventOnScrollProps> = ({ children, onReveal }) => {
  const { containerRef } = useOnReveal(onReveal);
  return <span ref={containerRef}>{children}</span>;
};

const CampaignCTA = ({
  ctaPosition,
  title,
  subTitle,
  ctaText,
  isHeroVersion,
  isSingle,
  categoryContainer,
  sectionIdx,
  onClick,
}: CampaignCTAChipProps) => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  if (!ctaPosition) {
    return null;
  }

  const sendChipsViewEvent = (chip: ChipFieldProps, chipXPos: number) => {
    const event = {
      category: 'cms_content_view',
      action: chip?.contentTypeUid,
      label: `${String(chipXPos + 1)}_${sectionIdx}`,
      property: `${chip.uid}_${chip.title}`,
    };
    sendAnalyticEvent('cms_content_view', event);
  };

  return (
    <div className={clsx(styles.campaign__title, isHeroVersion && styles.context_slider, styles.context)}>
      <h4 className={clsx(isHeroVersion ? styles.campaign__slider__h4 : styles.campaign__h4)}>{title}</h4>
      {isHeroVersion && isSingle && categoryContainer?.blocks?.length ? null : (
        <>
          {subTitle ? (
            <span className={clsx(isHeroVersion ? styles.campaign__slider__subtitle : styles.campaign__subtitle)}>
              {subTitle}
            </span>
          ) : null}
          <h5 className={clsx(isHeroVersion ? styles.campaign__slider__h5 : styles.campaign__h5)}>
            <span className={styles.campaign__slider__cta}>{ctaText}</span>
            {ctaText && (
              <Icon
                className={clsx(isHeroVersion ? styles.campaign__slider__arrow : styles.campaign__arrow)}
                name="arrow-right-long-20"
              />
            )}
          </h5>
        </>
      )}

      {isHeroVersion && isSingle && categoryContainer?.blocks?.length && (
        <div className={styles.campaign__slider__chip}>
          <div className={styles.campaign__slider__chip__wrapper}>
            {categoryContainer?.blocks?.map((chip, index) => (
              <RevealChipsEventOnScroll
                key={chip.uid}
                onReveal={() => sendChipsViewEvent(chip, index)}
              >
                <Chip
                  chip={chip}
                  key={chip.uid}
                  className={styles.campaign__slider__chip__block}
                  onChipClick={(e) => onClick?.(e, chip, index)}
                  dataCyIdentifier={`chipsField-chip-${chip.uid}`}
                />
              </RevealChipsEventOnScroll>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignCTA;
