import React, { useCallback, useMemo } from 'react';
import CampaignImg from '@components/molecules/campaign-block/components/campaign-img';
import clsx from 'clsx';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useOnReveal from '@hooks/use-on-reveal';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import {
  CampaignHeroBlock as CampaignHeroBlockProps,
  CMSBlockBackgroundStyle,
  CMSField,
  CMSFieldTextAlign,
  CMSFieldTextType,
  CMSField as FieldProps,
  CMSFieldType as FieldType,
} from '@interfaces/models/cms/campaign-hero';
import logger from '@helpers/utils/logger/client';
import { CampaignHeroFields } from './components/campaign-hero-fields';
import styles from './campaign-hero-block.module.scss';

// Fallback background and text color for fields
const DEFAULT_BACKGROUND_COLOR = '#E8E8E8';
const DEFAULT_TEXT_COLOR = '#000000';

// Fallback mapping of font sizes for different field types and breakpoints
const FONT_SIZE_MAPPING = {
  [FieldType.Tag]: {
    desktop: 'H16_M',
    tablet: 'H14_M',
    mobile: 'H14_M',
  },
  [FieldType.Title]: {
    desktop: 'A64_R',
    tablet: 'A48_R',
    mobile: 'A40_R',
  },
  [FieldType.Subtitle]: {
    desktop: 'H20_R',
    tablet: 'H16_R',
    mobile: 'H16_R',
  },
  [FieldType.CTA]: {
    desktop: 'H16_M',
    tablet: 'H16_M',
    mobile: 'H16_M',
  },
  [FieldType.Chips]: {
    desktop: 'H16_M',
    tablet: 'H16_M',
    mobile: 'H14_M',
  },
};

const fieldIsCTA = (field: CMSField) => field.fieldType === FieldType.CTA || field.fieldType === FieldType.Chips;
const fieldHasChips = (field: CMSField) => field.fieldType === FieldType.Chips;
const fieldIsCTAWithoutChips = (field: CMSField) => field.fieldType === FieldType.CTA;

const generateCampaignHeroStyles = (background: CMSBlockBackgroundStyle): object => {
  const backgroundStyles = {
    color: (bg: CMSBlockBackgroundStyle) => ({
      background: bg?.color || DEFAULT_BACKGROUND_COLOR,
    }),
    gradient: (bg: CMSBlockBackgroundStyle) =>
      bg?.gradient?.startColor && bg?.gradient?.endColor
        ? {
            background: `linear-gradient(${bg.gradient.angle}deg, ${bg.gradient.startColor}, ${bg.gradient.endColor})`,
          }
        : { background: DEFAULT_BACKGROUND_COLOR },
  };

  const selectedStyle = backgroundStyles[background?.backgroundType] || backgroundStyles.color;
  return selectedStyle(background);
};

const reorderFields = (fields: CMSField[]) => {
  const setFieldParams = (field: CMSField) => {
    const fieldStyle = field.data.fieldStyle || {};

    if (!fieldStyle.textColor) {
      fieldStyle.textColor =
        field.fieldType === FieldType.Chips
          ? field?.data?.chips?.[0]?.textColor || DEFAULT_TEXT_COLOR
          : DEFAULT_TEXT_COLOR;
    }

    if (!fieldStyle.fontSizeForDesktop) {
      fieldStyle.fontSizeForDesktop = FONT_SIZE_MAPPING[field.fieldType]?.desktop || '';
    }

    if (!fieldStyle.fontSizeForTablet) {
      fieldStyle.fontSizeForTablet = FONT_SIZE_MAPPING[field.fieldType]?.tablet || '';
    }

    if (!fieldStyle.fontSizeForMobile) {
      fieldStyle.fontSizeForMobile = FONT_SIZE_MAPPING[field.fieldType]?.mobile || '';
    }

    if (field.fieldType === FieldType.Chips) {
      fieldStyle.alignment = CMSFieldTextAlign.Center;
      fieldStyle.textType = CMSFieldTextType.Plain;
    }

    return {
      ...field,
      data: {
        ...field.data,
        fieldStyle: fieldStyle,
      },
    };
  };

  const fieldsWithParams = fields.map((field: CMSField) => setFieldParams(field));
  return fieldsWithParams;
};

const CampaignHeroBlock: React.FC<CampaignHeroBlockProps> = (props) => {
  const {
    backgroundStyle: background,
    fields,
    contentTypeUid: contentTypeUID,
    sectionIdx,
    uid: campaignHeroBlockUID,
    title: campaignHeroBlockLabel,
    url,
    blockStyle,
  } = props;
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const blockId = `HP_${String(campaignHeroBlockUID)}`;

  const findChipIndex = (dataArray, searchObject) => {
    const chipsField = dataArray.find((item) => item.fieldType === FieldType.Chips);
    const chipsArray = chipsField?.data?.chips;

    if (chipsArray) {
      return chipsArray.findIndex((chip) => Object.keys(searchObject).every((key) => chip[key] === searchObject[key]));
    }

    return -1;
  };

  const reorderedFields = useMemo(() => reorderFields(fields), [fields]);

  const createTrackingEvent = (data) => ({
    category: data?.eventType,
    action: data?.contentTypeUid || contentTypeUID,
    label: sectionIdx ?? '',
    property: `${data?.uid || campaignHeroBlockUID}_${data?.title || campaignHeroBlockLabel}`,
  });

  const trackEvent = (eventType, data, fieldType = '') => {
    const chipIndex = findChipIndex(reorderedFields, data);
    const event = createTrackingEvent({
      eventType,
      ...data,
      idx: chipIndex !== -1 ? chipIndex : sectionIdx,
      label: `${chipIndex}_${sectionIdx ?? ''}`,
    });

    sendAnalyticEvent(eventType, event);

    logger.info(`Tracking event sent for ${fieldType}`);
  };

  const trackContentView = useCallback(() => {
    trackEvent('cms_content_view', {}, '');
  }, [contentTypeUID, campaignHeroBlockUID, campaignHeroBlockLabel, sectionIdx]);

  const { containerRef } = useOnReveal(trackContentView, undefined, true);

  const trackContentClick = (fieldType, data) => {
    trackEvent('cms_content_click', data, fieldType);
  };

  const handleContentClick = (fieldType: string, data: FieldProps['data']): void => {
    if (!data?.url) {
      return;
    }
    trackContentClick(fieldType, data);
    if (typeof window !== 'undefined') {
      window.location.assign(data?.url);
    }
  };

  const handleFieldClick = (
    e: React.MouseEvent | React.KeyboardEvent,
    fieldType: FieldType,
    data: FieldProps['data'],
  ): void => {
    handleContentClick(fieldType, data);
    if (fieldType === FieldType.Icon) {
      sendAnalyticEvent('cms_content_click', {
        category: 'cms_content_click',
        action: `${contentTypeUID}_info_icon`,
        property: `${campaignHeroBlockUID}_${campaignHeroBlockLabel}`,
        label: sectionIdx ?? '',
      });
    }
    e.stopPropagation();
  };

  const handleContainerClick = (e?, data?: FieldProps['data']) => {
    if (e) {
      e.preventDefault();
      setHpScrollPosition(blockId);
    }
    if (data) {
      trackContentClick('containerField', data);
    }
    if (url && typeof window !== 'undefined') {
      window.location.assign(url);
    }
  };

  const trackCustomEvent = (eventName: string, categoryPrefix: string, data) => {
    sendAnalyticEvent(eventName, {
      category: `${categoryPrefix}${contentTypeUID}`,
      ...data,
    });
  };

  // check if the link applied to the whole block or on each of the chips
  const hasOnlyOneCTA = useMemo(() => {
    return fields.some(fieldIsCTAWithoutChips) && !fields.some(fieldHasChips);
  }, [fields]);

  const containerStyles = useMemo(() => {
    if (background?.backgroundType !== 'image') {
      return generateCampaignHeroStyles(background);
    }
    return {};
  }, [background]);

  const ctaField = reorderedFields.find(fieldIsCTA);

  const containerContent = useMemo(() => {
    return (
      <ConditionalWrapper
        condition={hasOnlyOneCTA}
        wrap={(children) => (
          <a
            id={blockId}
            href={ctaField.data?.url}
            onClick={(e) => handleContainerClick(e, ctaField.data)}
            onKeyDown={(e) => e.key === 'Enter' && handleContainerClick(e, ctaField.data)}
            className={clsx(
              styles.campaignHeroContainer,
              styles[`campaignHeroContainer--${blockStyle}`],
              'innerContainer',
            )}
            style={containerStyles}
            data-cy="campaignHeroLink"
          >
            {children}
          </a>
        )}
        defaultWrapper={(children) => (
          <div
            className={clsx(
              styles.campaignHeroContainer,
              styles[`campaignHeroContainer--${blockStyle}`],
              url && styles[`campaignHeroContainer--pointer`],
              'innerContainer',
            )}
            onClick={(e) => handleContainerClick(e, ctaField.data)}
            onKeyDown={(e) => e.key === 'Enter' && handleContainerClick(e, ctaField.data)}
            data-cy="campaignHeroContainer"
            style={containerStyles}
            role="link"
            tabIndex={0}
          >
            {children}
          </div>
        )}
      >
        <div
          className={styles.campaignHeroContainer__fields}
          data-cy="campaignHeroContainerFields"
          ref={containerRef}
          id={blockId}
        >
          <CampaignHeroFields
            fields={reorderedFields}
            onClick={handleFieldClick}
            blockStyle={blockStyle}
            onCustomEvent={trackCustomEvent}
            sectionIdx={sectionIdx}
          />
        </div>
        {/* Display background image if applicable */}
        {background?.backgroundType === 'image' && (
          <CampaignImg
            className={styles.campaignHeroContainer__image}
            image={background?.image}
          />
        )}
      </ConditionalWrapper>
    );
  }, [containerStyles, containerRef, background?.image]);

  return containerContent;
};

export default CampaignHeroBlock;
