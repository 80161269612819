import React from 'react';
import clsx from 'clsx';
import styles from './product-card-skeleton.module.scss';

const ProductCardSkeleton: React.FC = () => {
  return (
    <div
      role="presentation"
      className={styles.productCardSkeleton}
    >
      <div className={styles.header}>
        <div className={clsx(styles.skeletonIcon, styles.gradient)} />
      </div>
      <div className={clsx(styles.skeletonImage, styles.gradient)}></div>
      <div className={styles.skeletonContent}>
        <div className={clsx(styles.skeletonTitle, styles.gradient)} />
        <div className={clsx(styles.skeletonText, styles.gradient)} />
        <div className={clsx(styles.skeletonText, styles.gradient)} />
        <div className={clsx(styles.skeletonText, styles.gradient, styles.short)} />
      </div>
      <div className={styles.skeletonFooter}>
        <div className={clsx(styles.skeletonText, styles.gradient, styles.long)} />
      </div>
    </div>
  );
};

export default ProductCardSkeleton;
