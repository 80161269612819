import { useSessionStorage } from 'usehooks-ts';

const useRestoreHpScrollPosition = () => {
  const [hpScrollPosition, setHpScrollPosition] = useSessionStorage<string>('hpScrollPosition', null);

  const scrollWithDelay = (element) => {
    const t = setTimeout(() => {
      element.scrollIntoView({ block: 'center' });
      clearTimeout(t);
    }, 1000);
  };

  const scrollToCmsElementId = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (!!element) {
      scrollWithDelay(element);
    }
    setHpScrollPosition(null);
  };
  return {
    hpScrollPosition,
    setHpScrollPosition,
    scrollToCmsElementId,
  };
};

export default useRestoreHpScrollPosition;
