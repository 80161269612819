import React, { useEffect, useMemo, useState } from 'react';
import Image, { ImageLoader as NextImageLoader, ImageProps as NextImageProps, ImageLoaderProps } from 'next/image';
import clsx from 'clsx';
import { dequal } from 'dequal/lite';
import { CF_RESIZING_PREFIX, ImagesService } from '@services/image-service';
import Placeholder from '@components/atoms/vc-images/components/placeholder';
import Environment from '@config/environment';
import imageQuality from '@config/image';
import { datadogRum } from '@datadog/browser-rum-slim';
import styles from './vc-images.module.scss';

const BASE64_ENCODED_PLACEHOLDER_IMAGE =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAAAAAAD/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/wAALCAEAAQABAREA/8QAGwABAQADAQEBAAAAAAAAAAAAAAUBAgMEBgn/xAAtEAEAAgEDAgUDAgcAAAAAAAAAAQIDBBEhBTESEyIyQRRxgRUjM0JRU2Fykv/aAAgBAQAAPwD9RQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAGdmD/Abx/UAAAAAAAACeyZrdX1HSW4y4/Bft6Hj/Vuof3sf/Cto7Zs2PHmy54nfvtGztnraf4WXwSlZtZ1HFm8iJx3v/oo6WNXFInUWraZ+IjbZ3AAAAAAAAHm12njPp7UmOa8w+c5idlvo+Xx4LYpnmsqUREzG8cTxKXoa/Ua/Nqbe2k7VVJndgAAAAAAAAJjd851HTTp9VaNvTb1V/Lp03UeTnrMzxbiVnW5vI0t8sd9vDH3lrocEYNPSs+6fd95egAAAAAAAABP61hnLgjNWN5oixPhmIieNt1mmaOoRpsXxtOXJ+OIUIpP809u33bbSwAAAAAAAADW1IvW1LRxMbPmM9JxZr4ZjmsrXRcE48Pm273mJ/EcOvUdTODFGSvzxH3+EqOp66J38yPyu4rRfHW8TMxaImN2wAAAAAAAASnazpn1OeuXFeKTPvUK464sUY6fHDlqdLGqw+VMvBHQr7/u5/QqUrFKVpXtWNoZAAAAAAAABq2rWdvFLETO+7ffeNpagAAAAAAAADO/wwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA//9k=';

export const IMAGE_SIZES = {
  MOBILE: 767,
  TABLET: 1023,
  DESKTOP: 1459,
};

export type VCImageProps = NextImageProps & {
  disableLazyLoad?: boolean;
  type?: string;
  wrapperProps?: React.HTMLProps<HTMLDivElement>;
  shouldUseAssetImage?: boolean;
  sizesOverwrite?: number[];
  forceImgLazyLoadingCb?: () => boolean;
};

type LoaderWithExtraArg = (p: ImageLoaderProps, q?: number[]) => string;

type LazyLoadingWrapProps = {
  forceImgLazyLoadingCb: () => boolean;
  placeholder: React.ReactNode;
};

const LazyLoadingWrap: React.FCWithChildren<LazyLoadingWrapProps> = (props) => {
  const { forceImgLazyLoadingCb, children, placeholder } = props;
  if (!forceImgLazyLoadingCb) {
    return <>{children}</>;
  }
  const shouldLoadImg = forceImgLazyLoadingCb();
  return shouldLoadImg ? <>{children}</> : <>{placeholder}</>;
};

export const ImageComponent: React.FC<VCImageProps> = (props) => {
  const {
    src,
    height,
    width,
    alt,
    className,
    sizes,
    disableLazyLoad,
    forceImgLazyLoadingCb,
    wrapperProps = {},
    ...rest
  } = props;
  const { className: wrapperClassName, ...wrapperRest } = wrapperProps;

  const [hasErrorOccurred, setHasErrorOccurred] = useState<boolean>(false);

  const defaultImageUrl = useMemo<string>(() => {
    return ImagesService.buildImgUrl({
      path: '/notFound/placeholder.jpg',
      options: { w: width, h: height },
      imageType: 'default',
    });
  }, [width, height]);

  const imageProps: VCImageProps = {
    src,
    alt,
    width,
    height,
    sizes: sizes || (width ? `${width}px` : ''),
    loading: disableLazyLoad ? 'eager' : 'lazy',
    className: clsx(styles.image, className),
    ...rest,
  };

  const placeholderImageProps = {
    className,
    src: defaultImageUrl,
    width,
    height,
    alt,
    setImageError: setHasErrorOccurred,
  };

  // Reset error on src change
  useEffect(() => {
    if (src) {
      setHasErrorOccurred(false);
    }
  }, [src]);

  useEffect(() => {
    if (hasErrorOccurred) {
      datadogRum.addError('Image loading failed', {
        imageSrc: src,
      });
    }
  }, [hasErrorOccurred]);

  return (
    <div
      className={clsx(styles.imageContainer, wrapperClassName)}
      {...wrapperRest}
    >
      {hasErrorOccurred && <Placeholder {...placeholderImageProps} />}
      {!hasErrorOccurred && (
        <LazyLoadingWrap
          forceImgLazyLoadingCb={forceImgLazyLoadingCb}
          placeholder={<Placeholder {...placeholderImageProps} />}
        >
          <Image
            onError={() => setHasErrorOccurred(true)}
            {...imageProps}
            placeholder="blur"
            blurDataURL={BASE64_ENCODED_PLACEHOLDER_IMAGE}
          />
        </LazyLoadingWrap>
      )}
    </div>
  );
};

export const withImageProps = (
  Component: React.FC<VCImageProps>,
  loaderFn: NextImageLoader | LoaderWithExtraArg,
  imageSizes = {},
) => {
  const ImageComp = ({ type, sizesOverwrite, ...props }: VCImageProps) => {
    const sizes = type && imageSizes[type];

    return (
      <Component
        {...props}
        sizes={sizes || props.sizes}
        loader={sizesOverwrite ? (args) => loaderFn(args, sizesOverwrite) : loaderFn}
      />
    );
  };

  return React.memo(ImageComp, (prevProps, nextProps) => dequal(prevProps, nextProps));
};

const loaderFn =
  (shouldUseAssetImage: boolean) =>
  ({ src, width, quality }): string => {
    const baseHost = shouldUseAssetImage ? Environment.assetsPath : Environment.imagesBaseUrl;
    const imageResizePrefix = shouldUseAssetImage ? CF_RESIZING_PREFIX : ImagesService.getResizingPrefix(src);
    const url = `${baseHost}${imageResizePrefix}/w=${width || 500},q=${quality || imageQuality},f=auto,${src}`;

    return url;
  };

const CompWrap = (shouldUseAssetImage = false): React.FunctionComponent => {
  return withImageProps(ImageComponent, loaderFn(shouldUseAssetImage));
};

export default ({ shouldUseAssetImage, ...restProps }: VCImageProps) => {
  const Comp = useMemo(() => {
    return CompWrap(shouldUseAssetImage);
  }, []);
  return React.createElement(Comp, restProps);
};
