import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import { useTranslation } from 'next-i18next';
import useRestoreHpScrollPosition from '@hooks/cms/use-restore-hp-scroll-position';
import Button from '@components/atoms/button/button';
import PostItem from '@components/organisms/post-list/components/post-item';
import { PostList as IPostList } from '@interfaces/models/EditoPost';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';

import styles from './post-list.module.scss';

const PostList: React.FC<IPostList> = ({
  uid,
  sectionIdx,
  title: label,
  displayTitle: title,
  editoPageReference,
  contentTypeUid: contentTypeUID,
}): React.JSX.Element => {
  const { t } = useTranslation();
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { setHpScrollPosition } = useRestoreHpScrollPosition();
  const blockId = `HP_${contentTypeUID}`;
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${uid}_${label}`,
        label: sectionIdx ?? '',
      });
    }, [contentTypeUID, label, sectionIdx, uid]),
    0.5,
    false,
  );

  const postBlocks = useMemo(() => {
    if (!editoPageReference || editoPageReference.length < 1) {
      return null;
    }

    return editoPageReference?.slice(0, 4)?.map((blockPost, index) => {
      const elemKey = `vc-post-` + index;
      return (
        <PostItem
          key={elemKey}
          blockPost={blockPost}
          sectionIdx={sectionIdx}
          index={index}
          dataCy={`journal_block${index + 1}`}
        />
      );
    });
  }, [editoPageReference]);

  const handleOnClickCTA = () => {
    setHpScrollPosition(blockId);
    window.location.href = '/journal/';
  };

  if (!postBlocks?.length) {
    return null;
  }

  return (
    <section
      ref={containerRef}
      className={styles.listPost}
      id={blockId}
    >
      <div className="innerContainer">
        {title && <h2 className={clsx(styles.listPost__title, 'vc-title-m')}>{title}</h2>}

        <ul
          className={styles.listPost__list}
          data-cy="journal_blockList"
        >
          {postBlocks}
        </ul>

        <Button
          className={styles.listPost__cta}
          variant="primary"
          size="medium"
          onClick={handleOnClickCTA}
          fullWidth
          notFullWidthQuery="md-up"
        >
          {t('HOME.EDITO.MAIN_CTA')}
        </Button>
      </div>
    </section>
  );
};

export default PostList;
