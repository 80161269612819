import React, { FCWithChildren, useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { BrandSearchResponse, BrandSearchResult } from '@interfaces/api/responses/brand-search-response';
import SearchService from '@services/search-service';
import { usePreferences } from '@context/preferences.context';
import { useQuery } from '@tanstack/react-query';
import { GlobalQueryKeys } from '@enums/react-query-keys';
import { useRouter } from 'next/router';

type SearchContextValues = {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  popularSearches: BrandSearchResult[];
  suggestions: BrandSearchResult[];
  brands: BrandSearchResult[];
  isSearchHeaderOpen: boolean;
  setIsSearchHeaderOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const SearchContext = createContext<SearchContextValues>({} as never);

const SearchProvider: FCWithChildren = (props) => {
  const { children } = props;

  const { query } = useRouter();
  const { country, currency, language } = usePreferences();

  const search = (query?.q ?? '') as string;

  const [searchQuery, setSearchQuery] = useState<string>(search);
  const [autoSuggestSearchQuery, setAutoSuggestSearchQuery] = useState<string>(search);
  const [isSearchHeaderOpen, setIsSearchHeaderOpen] = useState<boolean>(false);

  // Default list shown if search query is empty
  const { data: popularSearches } = useQuery<BrandSearchResponse, Error, BrandSearchResult[]>({
    queryKey: [GlobalQueryKeys.SEARCH_POPULAR_BRANDS],
    queryFn: () =>
      SearchService.brandSearch({
        locale: {
          country,
          currency,
          language,
        },
        suggestions: true,
        limit: {
          brands: 9,
          suggestions: 8,
        },
      }),
    enabled: isSearchHeaderOpen,
    cacheTime: 60 * 60 * 1000,
    select: (result) => result?.suggestions ?? [],
    initialData: {
      suggestions: [],
    },
  });

  // List of suggestions that fit user's search query
  const { data: brandSearchResponse } = useQuery<BrandSearchResponse>({
    queryKey: [GlobalQueryKeys.SEARCH_SUGGESTIONS, autoSuggestSearchQuery],
    queryFn: () =>
      SearchService.brandSearch({
        q: autoSuggestSearchQuery,
        locale: {
          country,
          currency,
          language,
        },
        suggestions: true,
        brands: true,
      }),
    enabled: isSearchHeaderOpen && !!autoSuggestSearchQuery,
    initialData: {
      suggestions: [],
      brands: [],
    },
  });

  const suggestions: BrandSearchResult[] = brandSearchResponse?.suggestions ?? [];
  const brands: BrandSearchResult[] = brandSearchResponse?.brands ?? [];

  useEffect(() => {
    const handler = setTimeout(() => {
      setAutoSuggestSearchQuery(searchQuery);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  const value: SearchContextValues = {
    searchQuery,
    setSearchQuery,
    popularSearches,
    suggestions,
    brands,
    isSearchHeaderOpen,
    setIsSearchHeaderOpen,
  };

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export { SearchProvider, SearchContext };
