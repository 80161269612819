import React, { FCWithChildren } from 'react';
import Header from '@components/organisms/header/header';
import BottomNavigation from '@components/bottom-navigation/bottom-navigation';
import DynamicFooterWrapper from '@components/organisms/footer/dynamic-footer-wrapper';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import AccessibilityLink from '@components/molecules/accessibility-link/accessibility-link';
import { usePreferences } from '@context/preferences.context';
import { isWebview } from '@helpers/routing';
import { useRouter } from 'next/router';
import clsx from 'clsx';

type DefaultLayoutProps = {
  shouldShowBanner?: boolean;
};

const DefaultLayout: FCWithChildren<DefaultLayoutProps> = (props) => {
  const { children, shouldShowBanner } = props;

  const { query } = useRouter();
  const { language, siteId, locale } = usePreferences();
  const isWebView = isWebview(query);

  const shouldShowAccessibilityLink = language === 'us' && siteId === 6 && !isWebView;

  return (
    <div
      className={clsx(locale, shouldShowBanner && 'hasBanner', shouldShowAccessibilityLink && 'hasAccessibilityLink')}
    >
      <ConditionalWrapper
        condition={!isWebView}
        wrap={(children) => (
          <>
            {shouldShowAccessibilityLink && <AccessibilityLink />}
            <Header shouldShowBanner={shouldShowBanner} />
            {children}
            <BottomNavigation />
            <DynamicFooterWrapper />
          </>
        )}
      >
        {children}
      </ConditionalWrapper>
    </div>
  );
};

export default DefaultLayout;
