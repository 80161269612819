import React, { FC, useMemo } from 'react';
import clsx from 'clsx';

import { CMSField as FieldProps, CMSFieldType as FieldType } from '@interfaces/models/cms/campaign-hero';
import {
  CTAField,
  ChipsField,
  IconField,
  SimpleTextField,
  ImageField,
} from '@components/molecules/cms/cms-block-fields/components';

import styles from './campaign-hero-fields.module.scss';

interface CampaignHeroFieldsProps {
  fields: FieldProps[];
  blockStyle?: string;
  sectionIdx: string;
  onClick?: (
    e: React.MouseEvent | React.KeyboardEvent,
    fieldType: FieldProps['fieldType'],
    data: FieldProps['data'],
  ) => void;
  onCustomEvent?: (eventName: string, prefix: string, data) => void;
}

export const FieldComponentMapping = {
  [FieldType.SimpleTextField]: SimpleTextField,
  [FieldType.CTA]: CTAField,
  [FieldType.Chips]: ChipsField,
  [FieldType.Icon]: IconField,
  [FieldType.Image]: ImageField,
};
const fieldIsAvailable = (field: FieldProps): boolean => {
  // If Image field is empty, don't render it
  if (field.fieldType === FieldType.Image && !field.data?.url) {
    return false;
  }
  return FieldComponentMapping[field.fieldType] !== undefined;
};

export const fieldsToOrderedFields = (fields: FieldProps[]): FieldProps[][] => {
  const mappedFields = [[], [], []];
  const alignmentIndex = {
    left: 0,
    middle: 1,
    right: 2,
  };
  fields.map((field, index) => {
    // if field is not available, don't render it
    if (!fieldIsAvailable(field)) {
      return;
    }

    if (field.fieldType === FieldType.Image && field.columnAlignment === 'middle') {
      return index === 0
        ? mappedFields[alignmentIndex.left].push(field)
        : mappedFields[alignmentIndex.right].push(field);
    }

    return alignmentIndex[field.columnAlignment] >= 0
      ? mappedFields[alignmentIndex[field.columnAlignment]].push(field)
      : mappedFields[alignmentIndex.middle].push(field);
  });

  return mappedFields.filter((arr) => arr.length);
};

export const CampaignHeroFields: FC<CampaignHeroFieldsProps> = ({
  fields,
  onClick,
  blockStyle,
  sectionIdx,
  onCustomEvent,
}): React.JSX.Element => {
  const orderedFields = useMemo(() => fieldsToOrderedFields(fields), [fields]);

  const handleFieldClick = (
    e: React.MouseEvent | React.KeyboardEvent,
    fieldType: FieldProps['fieldType'],
    data: FieldProps['data'],
  ): void => {
    onClick?.(e, fieldType, data);
  };

  const getFields = (fields: FieldProps[]) => {
    return fields.map((field: FieldProps, index: number) => {
      const FieldComponent = FieldComponentMapping[field.fieldType];
      if (FieldComponent) {
        return (
          <FieldComponent
            {...field}
            key={`${field.fieldType}-${index}`}
            sectionIdx={sectionIdx}
            onClick={(e: React.MouseEvent | React.KeyboardEvent, data: FieldProps['data']) =>
              handleFieldClick(e, field.fieldType, data)
            }
            blockStyle={blockStyle}
            onCustomEvent={onCustomEvent}
          />
        );
      }
      return null;
    });
  };

  return (
    <div
      className={clsx(styles.campaignHeroFields, styles[`campaignHeroFields--${blockStyle}`], {
        [styles.campaignHeroFields__center]: orderedFields.length === 1 && blockStyle !== 'voucher',
      })}
    >
      {orderedFields.map((fields, index) => {
        if (!fields?.length) {
          return null;
        }
        const containerFullStyle = blockStyle === 'marketing_small' && fields.length > 1;
        return (
          <div
            key={index}
            className={clsx(
              styles.campaignHeroFields__container,
              styles[`campaignHeroFields__container--${blockStyle}`],
              {
                [styles.campaignHeroFields__center]: orderedFields.length === 1 && blockStyle !== 'voucher',
                [styles['campaignHeroFields__container--full']]: containerFullStyle,
              },
            )}
          >
            {getFields(fields)}
          </div>
        );
      })}
    </div>
  );
};
