import { usePreferences } from '@context/preferences.context';
import { useSessionStorage } from 'usehooks-ts';
import { useContextSelector } from 'use-context-selector';
import { UserContext } from '@context/user.context';
import { useCallback, useMemo } from 'react';
import { mapCountryToTheSizeType, parseSearchPreferences } from '@helpers/utils/catalog/catalog-url-helpers';
import { useBucket } from '@context/bucket.context';
import axios from 'axios';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { ApiResponse } from '@interfaces/api';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import Environment from '@config/environment';
import { useQueries } from '@tanstack/react-query';
import useRecentSearches, { RecentSearch } from '@hooks/search/use-recent-searches';
import logger from '@helpers/utils/logger/client';
import { RecentSearchesQueryKeys } from '@enums/react-query-keys';

const processNumber = (input: unknown, defaultValue: number) => {
  if (typeof input === 'number') {
    return input;
  } else {
    return defaultValue;
  }
};

const DEFAULT_CALLS_COUNT = 10;
const DEFAULT_MAXIMUM_COUNT_LIMIT = 99;

// TODO: remove doFetchCount prop as it's always true
const useRecentSearchesWithCount = ({ doFetchCount }: { doFetchCount: boolean }) => {
  const { recentSearches, setRecentSearches, ...rest } = useRecentSearches();
  const { country, currency, language, regionName } = usePreferences();
  const [recentSearchesFetched, setRecentSearchesFetched] = useSessionStorage<string[]>(
    'sessionsave/recentSearchesFetched',
    [],
  );
  const userSizes = useContextSelector(UserContext, (v) => v.userSizes);
  const useSizePreferences = useMemo(() => (userSizes ? parseSearchPreferences(userSizes) : null), [userSizes]);
  const { getFeature } = useBucket();
  const recentSearchCallsCount = processNumber(
    getFeature('recentSearchCallsCountWeb', DEFAULT_CALLS_COUNT)?.value,
    DEFAULT_CALLS_COUNT,
  );
  const recentSearchMaximumCountLimit = processNumber(
    getFeature('recentSearchMaximumCountLimitWeb', DEFAULT_MAXIMUM_COUNT_LIMIT)?.value,
    DEFAULT_MAXIMUM_COUNT_LIMIT,
  );

  const queryFn = useCallback(
    (recentSearch: RecentSearch) =>
      axios.post<ElasticSearch, ApiResponse<ElasticSearchResponse>>(
        `${Environment.apiProductSearchBaseUrl}/v1/product/search`,
        {
          pagination: {
            limit: 0,
            offset: 0,
          },
          q: recentSearch.payload.q,
          filters: {
            ...recentSearch.payload.filters,
            createdAt: {
              gte: recentSearch.createdAt,
            },
          },
          locale: {
            country,
            currency: currency,
            language: language,
            sizeType: mapCountryToTheSizeType(regionName),
          },
          mySizes:
            recentSearch.payload.mySizesEnabled && useSizePreferences
              ? {
                  isEnabled: true,
                  ['universe.id']: useSizePreferences.universeIds,
                  sizes: useSizePreferences.sizes,
                }
              : null,
        },
      ),
    [useSizePreferences],
  );

  useQueries({
    queries: recentSearches.slice(0, recentSearchCallsCount).map((recentSearch) => ({
      queryKey: [RecentSearchesQueryKeys.RECENT_SEARCHES_COUNT, recentSearch],
      queryFn: () => queryFn(recentSearch),
      staleTime: Infinity,
      onSuccess: (data) => {
        setRecentSearchesFetched((prevState: string[]) => [
          ...prevState,
          `${recentSearch.id}-${recentSearch.createdAt}`,
        ]);
        setRecentSearches((prevState: RecentSearch[]) =>
          prevState.map((search) => {
            if (search.id === recentSearch.id) {
              return {
                ...search,
                newItemsCount: data?.data?.paginationStats?.totalHits,
              };
            }
            return search;
          }),
        );
      },
      onError: (error) => {
        logger.error('Error fetching recent searches count', error);
      },
      enabled:
        doFetchCount &&
        !(recentSearch.newItemsCount > recentSearchMaximumCountLimit) && // if there are already more than 99 products, we don't need to fetch the count
        !recentSearchesFetched.includes(`${recentSearch.id}-${recentSearch.createdAt}`), // we only fetch the count once per session
    })),
  });

  return { recentSearches, recentSearchMaximumCountLimit, ...rest };
};

export default useRecentSearchesWithCount;
