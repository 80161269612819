class MobileDetectionService {
  public static isAndroid(): boolean {
    const isBrowser: boolean = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    if (isBrowser) {
      const userAgent: string = isBrowser && navigator.userAgent ? navigator.userAgent : '';
      return !!userAgent.match(/Android/i);
    }
    return false;
  }

  public static isIOS(): boolean {
    const isBrowser: boolean = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    if (isBrowser) {
      const userAgent: string = navigator.userAgent ? navigator.userAgent : '';
      return !!(
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPod/i) ||
        (navigator.maxTouchPoints > 2 && (userAgent.match(/MacIntel/i) || userAgent.match(/Macintosh/i)))
      );
    }
    return false;
  }

  public static isSafari(): boolean {
    const isBrowser = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    if (isBrowser) {
      const userAgent = navigator.userAgent || navigator.userAgent;
      return !!(userAgent.match(/safari/i) && !userAgent.match(/Chrome/i));
    }
    return false;
  }
}

export default MobileDetectionService;
