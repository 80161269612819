const genId = (arr: string[]) => arr?.join('-');
const genIds = (str: string) => str?.split('-');
import Environment from '@config/index';

// 0 ,1, 2
const universesMap = ['1', '2', '1-2'];

const iconMap = {
  '1': `${Environment.cdnFolder}/assets/icons/category-1.svg`,
  '3': `${Environment.cdnFolder}/assets/icons/category-3.svg`,
  '10': `${Environment.cdnFolder}/assets/icons/category-10.svg`,
  '21': `${Environment.cdnFolder}/assets/icons/category-21.svg`,
  '4': `${Environment.cdnFolder}/assets/icons/category-4.svg`,
};

const sizeTypes = [
  ['1', '3'],
  ['10', '21', '4'],
];

export { universesMap, iconMap, sizeTypes, genId, genIds };
