import React, { Dispatch, ImgHTMLAttributes, SetStateAction } from 'react';
import clsx from 'clsx';
import styles from '../vc-images.module.scss';

type PlaceholderProps = ImgHTMLAttributes<HTMLImageElement> & {
  setImageError?: Dispatch<SetStateAction<boolean>>;
};

const Placeholder: React.FC<PlaceholderProps> = (props) => {
  const { className, alt, setImageError, ...rest } = props;

  return (
    <img
      alt={alt}
      className={clsx(styles.image, className)}
      onError={() => setImageError(true)}
      {...rest}
    />
  );
};

export default Placeholder;
