import React, { useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { HeaderDataItem } from '@interfaces/models/header';
import Link from '@components/atoms/link/link';
import dynamic from 'next/dynamic';
import styles from '../main-navigation.module.scss';

const DesktopPopupColumn = dynamic(() => import('@components/molecules/main-nav/components/desktop-popup-column'), {
  ssr: false,
});

interface DesktopItemProps {
  headerDataItem: HeaderDataItem;
  show: boolean;
  onSelect: (id: number | null) => void;
  onClick: (label: string) => void;
  isMainNavigationOpen: boolean;
  setIsMainNavigationOpen: (isOpened: boolean) => void;
}

const DesktopItem: React.FC<DesktopItemProps> = ({
  headerDataItem,
  show,
  onSelect,
  onClick,
  isMainNavigationOpen,
  setIsMainNavigationOpen,
}) => {
  const hasChildren: boolean = !!headerDataItem?.content?.length;
  const isLink: boolean = headerDataItem?.url?.seo !== '#';
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debounceCallback = (func: () => void, delay: number) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(func, delay);
  };

  const handleMouseEnter = () => {
    const openItem = () => {
      onSelect(headerDataItem.id);
      setIsOpen(true);
    };
    if (isMainNavigationOpen) {
      openItem();
      return;
    }
    debounceCallback(() => {
      openItem();
      setIsMainNavigationOpen(true);
    }, 300);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutRef.current);
    onSelect(null);
    setIsOpen(false);
  };

  return (
    <li
      key={headerDataItem.id}
      onMouseEnter={handleMouseEnter}
      onFocus={() => onSelect(headerDataItem.id)}
      onMouseLeave={handleMouseLeave}
      onBlur={() => onSelect(null)}
      className={clsx({
        [styles.desktop__noChildren]: !hasChildren,
        [styles['mainNav__item--highlight']]: headerDataItem?.mnemonic === 'sale',
      })}
    >
      {isLink ? (
        <Link
          href={headerDataItem.url.seo}
          tabIndex={0}
          onClick={() => onClick(headerDataItem.localizedName)}
        >
          {headerDataItem.localizedName}
        </Link>
      ) : (
        <p tabIndex={-1}>{headerDataItem.localizedName}</p>
      )}
      {/* Popup */}
      {hasChildren && (
        <details open={isOpen}>
          <summary>
            <span />
          </summary>
          <CSSTransition
            classNames={{
              appear: styles['fade-enter'],
              appearActive: styles['fade-enter-active'],
              exit: styles['fade-exit'],
              exitActive: styles['fade-exit-active'],
            }}
            timeout={300}
            in={show}
            unmountOnExit
            mountOnEnter
          >
            <div className={styles.desktop__popup}>
              <div className="innerContainer">
                {/* Render columns */}
                {headerDataItem.content.map((link: HeaderDataItem) => (
                  <DesktopPopupColumn
                    headerDataItem={link}
                    key={link.id}
                    onClick={(label: string) => onClick(`${headerDataItem.localizedName}/${label}`)}
                  />
                ))}
              </div>
            </div>
          </CSSTransition>
        </details>
      )}
    </li>
  );
};

export default DesktopItem;
