import { useState, useMemo } from 'react';
import { usePreferences } from '@context/preferences.context';
import { useRouter } from 'next/router';
import Cookies from 'universal-cookie';
import { AppCookies } from '@enums/cookies';
import { getRegionIdByRegionName } from '@helpers/localization';
import { isPricingCalculatorPage, isWebview } from '@helpers/routing';
import MobileDetectionService from '@services/mobile-detection-service';
import { useDeviceSize } from '@context/device-size.context';
import { DeviceType } from '@enums/deviceType';
import Environment from '@config/index';

const osList = {
  isIos: MobileDetectionService.isIOS(),
  isAndroid: MobileDetectionService.isAndroid(),
};

const smartBannerCookieOpt = {
  path: '/',
  domain: Environment.cookieOptions.domain,
  expires: new Date(Date.now() + 30 * 86400 * 1000),
};

const useSmartBanner = () => {
  const { siteId, smartBannerClosed } = usePreferences();
  const { pathname, query } = useRouter();
  const isPCPage = isPricingCalculatorPage(pathname);
  const [smartBannerMannuallyClosed, setSmartBannerMannuallyClosed] = useState<boolean>(smartBannerClosed);
  const { deviceType } = useDeviceSize();
  const isWebView = isWebview(query);

  const shouldShowSmartBanner = useMemo(() => {
    const isKr = siteId === getRegionIdByRegionName('KR');
    // TODO: TEST IF ITS OK????
    const isNaverTraffic = query.utm_medium === 'naver_shopping' && query.utm_source === 'naver';
    // Hide smart banner on Pricing Calculator mWeb (isPCpage)
    const hideSmartBanner = (isKr && isNaverTraffic) || isPCPage || isWebView;

    // Should hide smart banner for KR site when traffic is from Naver and FF is enabled
    if (hideSmartBanner) {
      return false;
    }
    return !(deviceType === DeviceType.DESKTOP || smartBannerMannuallyClosed);
  }, [smartBannerMannuallyClosed]);

  const closeBanner = (): void => {
    setSmartBannerMannuallyClosed(true);

    const cookies = new Cookies();
    cookies.set(AppCookies.SMART_BANNER_CLOSED, true, smartBannerCookieOpt);
  };

  return {
    closeBanner,
    shouldShowSmartBanner,
    osList,
  };
};

export default useSmartBanner;
