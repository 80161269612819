import React from 'react';
import Environment from '@config/index';
import { useTranslation } from 'next-i18next';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './logo.module.scss';

const Logo: React.FC = () => {
  const { t } = useTranslation('default-layout');
  const { sendAnalyticEvent: sendProductAnalyticEvent } = useAnalyticEvents('navigation');
  return (
    <a
      href={`/`}
      className={styles.logo}
      data-cy="vcHeader_logo"
      tabIndex={0}
      onClick={() =>
        sendProductAnalyticEvent('navigation_header', {
          label: 'home',
          value: '1',
        })
      }
    >
      <img
        src={`${Environment.cdnFolder ?? ''}/assets/img/home/vestiairecollective_logo.svg?v=1`}
        alt={t('SEO.LOGO_ALT')}
        width={295}
        height={26}
        className={styles.logo__image}
      />
    </a>
  );
};

export default Logo;
