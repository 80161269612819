import { useQuery } from '@tanstack/react-query';
import { ProductPageQueryKeys } from '@enums/react-query-keys';
import { Shipping } from '@interfaces/models/shipping';
import { isShippable } from '@helpers/utils/products';

const useProductShippingQuery = () => {
  const { data: productShipping } = useQuery<Shipping>({
    queryKey: [ProductPageQueryKeys.PRODUCT_SHIPPING],
    staleTime: Infinity,
  });

  const isProductShippable = isShippable(productShipping);

  return {
    productShipping,
    isProductShippable,
  };
};

export default useProductShippingQuery;
