import React from 'react';
import PostCard from '@components/molecules/post-card/post-card';
import { PostBlock } from '@interfaces/models/EditoPost';
import styles from '../post-list.module.scss';

interface PostItemProps {
  blockPost: PostBlock;
  sectionIdx?: string;
  index: number;
  dataCy?: string;
}

const PostItem = ({ blockPost, sectionIdx, index, dataCy }: PostItemProps) => (
  <li
    className={styles.listPost__list__item}
    data-cy={dataCy}
  >
    <div className={styles.listPost__list__item__post}>
      <PostCard
        {...blockPost}
        sectionIdx={sectionIdx}
        posX={String(index + 1)}
      />
    </div>
  </li>
);

export default PostItem;
