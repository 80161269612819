import React from 'react';
import clsx from 'clsx';
import MerchandiseBlock from '@components/molecules/merchandise-block/merchandise-block';
import { MerchandiseBlock as IMerchandiseBlock } from '@interfaces/models/merchandise';
import styles from '../merchandise.module.scss';

interface MerchandisingItemProps {
  merchandisingBlock: IMerchandiseBlock;
  length: number;
  index: number;
}

const MerchandisingItem = ({ merchandisingBlock, length, index }: MerchandisingItemProps) => (
  <li className={clsx(styles.container__merchandising, styles['containerMerch-' + length])}>
    <MerchandiseBlock
      {...merchandisingBlock}
      idx={index}
      blockLength={length}
    />
  </li>
);

export default MerchandisingItem;
