import React, { useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/lazy';
import 'swiper/css/effect-fade';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { Campaign as ICampaign, CategoryContainer } from '@interfaces/models/campaign';
import styles from './campaign.module.scss';
import CampaignSwiper from './campaign-swiper';

type CampaignProps = {
  componentID: string;
  categoryContainer: CategoryContainer;
} & ICampaign;

const Campaign: React.FC<CampaignProps> = ({
  uid,
  blocks: campaignBlocks,
  componentID,
  title: label,
  contentTypeUid: contentTypeUID,
  sectionIdx,
  categoryContainer,
}): React.JSX.Element => {
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');

  // NAVIGATION
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${uid}_${label}`,
        label: sectionIdx ?? '',
      });
    }, [contentTypeUID, label, sectionIdx, sendAnalyticEvent, uid]),
    0.5,
    false,
  );

  if (!campaignBlocks?.length) {
    return null;
  }

  return (
    <section
      ref={containerRef}
      className={clsx(
        'innerContainer',
        styles.campaignContainerSlider,
        `${campaignBlocks?.length >= 5 ? styles['campaignContainerSlider--fiveChannel'] : ''}`,
      )}
      data-testid="campaignContainerList"
    >
      <CampaignSwiper
        campaignBlocks={campaignBlocks}
        componentID={componentID}
        categoryContainer={categoryContainer}
        sectionIdx={sectionIdx}
      />
    </section>
  );
};

export default Campaign;
