import { CatalogFilterConfiguration, FilterTopLevelGroup } from '@interfaces/models/catalogFilter';

/**
 * My Sizes
 * TaxFree (only US)
 * Universe
 * - Category (shown only when universe selected)
 * - - Sub category (shown only when category selected)
 * Designers
 * - Models (shown only when designer selected)
 * Shipped from
 * Duty-Free
 * Express Delivery
 * Direct Shipping
 * Seller badge
 * Colors
 * Price
 * All sizes (can be up to 17)
    "SIZE 1": "Women’s Size",
    "SIZE 2": "Jean size",
    "SIZE 3": "Women’s shoe size",
    "SIZE 4": "Men’s shoe size",
    "SIZE 5": "Jean size",
    "SIZE 6": "Child’s shoe size",
    "SIZE 7": "Accessories size",
    "SIZE 8": "Bra size",
    "SIZE 9": "Accessories size",
    "SIZE 10": "Men’s size",
    "SIZE 11": "Child’s size",
    "SIZE 12": "Waist measurement",
    "SIZE 13": "Glove size",
    "SIZE 14": "Hat size",
    "SIZE 15": "Ring size",
    "SIZE 16": "Waist measurement",
    "SIZE 17": "Glove size",
    "SIZE 18": "Hat size",
    "SIZE 19": "Suit size",
    "SIZE 20": "Shirt size",
    "SIZE 21": "Trousers size",
    "SIZE 22": "Swimwear size",
    "SIZE 23": "Polo size",
    "SIZE 24": "T-shirt size",
    "SIZE 25": "Top size",
 * Condition
 * Materials
 * Editor’s pick
 * Sold products
 * Reduced prices
 */

const filterGroupConfigurations: CatalogFilterConfiguration[] = [
  {
    type: 'switch',
    label: 'CATALOG.FILTERS.TAX_FREE',
    facet: 'dutyFree',
    sortBy: 'asc',
    filter: 'dutyFree',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.UNIVERSE',
    facet: 'universe',
    searchPlaceholder: 'CATALOG.FILTERS.SEARCH_PLACEHOLDER.UNIVERSE',
    sortBy: 'sortById',
    filter: 'universe.id',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.CATEGORY',
    facet: 'categoryLvl0',
    sortBy: 'asc',
    filter: 'categoryLvl0.id',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.SUBCATEGORY',
    facet: 'categoryLvl1',
    sortBy: 'asc',
    filter: 'categoryLvl1.id',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.SUB_SUBCATEGORY',
    facet: 'categoryLvl2',
    sortBy: 'asc',
    filter: 'categoryLvl2.id',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.BRANDS',
    facet: 'brand',
    searchPlaceholder: 'CATALOG.FILTERS.SEARCH_PLACEHOLDER.BRANDS',
    isSearchable: true,
    limit: 100,
    sortBy: 'asc',
    filter: 'brand.id',
    lazyLoadCount: 10,
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.MODELS',
    facet: 'model',
    isSearchable: true,
    limit: 100,
    sortBy: 'asc',
    filter: 'model.id',
    lazyLoadCount: 10,
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.LOCATION',
    facet: 'country',
    searchPlaceholder: 'CATALOG.FILTERS.SEARCH_PLACEHOLDER.COUNTRY',
    isSearchable: true,
    limit: 100,
    sortBy: 'asc',
    filter: 'country',
  },
  // Duty free, In UK,...
  // {
  //   type: 'item',
  //   label: 'CATALOG.FILTERS.LOCAL_COUNTRIES',
  //   facet: 'directShippingCountries',
  //   sortBy: 'asc',
  //   hasSustainable: true,
  //   itemText: ['REGULAR_FILTER_DS.SUSTAINABLE_LEAF.TEXT'],
  //   filter: 'directShippingCountries',
  // },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.LOCAL_COUNTRIES.PLACE_HOLDER',
    facet: 'localCountries',
    sortBy: 'asc',
    hasSustainable: true,
    itemText: ['REGULAR_FILTER_LOCAL.SUSTAINABLE_LEAF.TEXT'],
    filter: 'localCountries',
  },
  // Express delivery
  {
    type: 'item',
    label: 'DELIVERY_OPTS.EXPRESS',
    facet: 'stock',
    sortBy: 'asc',
    isSearchable: false,
    itemText: ['CATALOG.FILTERS.STOCK_24'],
    filter: 'stock',
  },
  // Direct shipping
  {
    type: 'item',
    label: 'CATALOG.FILTERS.DS',
    facet: 'directShippingEligible',
    sortBy: 'asc',
    hasSustainable: true,
    itemText: ['REGULAR_FILTER_DS.SUSTAINABLE_LEAF.TEXT'],
    filter: 'directShippingEligible',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.SELLERBADGE',
    facet: 'sellerBadge',
    sortBy: 'asc',
    filter: 'sellerBadge',
  },
  {
    type: 'item',
    label: 'PRODUCT.DESCRIPTION.BRAND_STORE',
    facet: 'isOfficialStore',
    sortBy: 'asc',
    itemText: ['CATALOG.FILTERS.BS_LABEL'],
    filter: 'isOfficialStore',
  },
  {
    type: 'color-swatch',
    label: 'CATALOG.FILTERS.COLORS',
    facet: 'color',
    showMoreLimit: 100,
    limit: 100,
    sortBy: 'asc',
    filter: 'color.id',
  },
  {
    type: 'price-range',
    label: 'CATALOG.FILTERS.PRICE.INPUT',
    facet: 'price',
    filter: 'price',
  },
  // Top level container for size filters
  {
    type: 'top-level-group',
    label: 'CATALOG.FILTERS.SIZES.TITLE',
    group: FilterTopLevelGroup.SIZE,
    facet: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 1": "Women’s Size"
    // EXCEPTION: international (US) and JP should be sorted by ClothingSize
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.1',
    facet: 'size1',
    limit: 20,
    sortBy: {
      default: 'sortByNumericValue',
      US: 'sortClothingSizes',
      KR: 'sortClothingSizes',
    },
    universeId: '1',
    filter: 'size1',
    id: '1',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 2": "Jean size" (women)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.2',
    facet: 'size2',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '1',
    filter: 'size2',
    id: '2',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 3": "Women’s shoe size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.3',
    facet: 'size3',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '1',
    filter: 'size3',
    id: '3',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 4": "Men’s shoe size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.4',
    facet: 'size4',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '2',
    filter: 'size4',
    id: '4',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 5": "Jean size" (men)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.5',
    facet: 'size5',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '2',
    filter: 'size5',
    id: '5',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 6": "Child’s shoe size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.6',
    facet: 'size6',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '3',
    filter: 'size6',
    id: '6',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 7": "Accessories size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.7',
    facet: 'size7',
    limit: 20,
    sortBy: 'asc',
    universeId: '1',
    filter: 'size7',
    id: '7',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 8": "Bra size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.8',
    facet: 'size8',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '1',
    filter: 'size8',
    id: '8',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 9": "Accessories size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.9',
    facet: 'size9',
    limit: 20,
    sortBy: 'sortById',
    universeId: '1',
    filter: 'size9',
    id: '9',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 10": "Men’s size"
    // EXCEPTION: KR should be sorted by NumericValue - NOT TRUE! - remains sorted by ClothingSize
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.10',
    facet: 'size10',
    limit: 20,
    sortBy: 'sortClothingSizes',
    universeId: '2',
    filter: 'size10',
    id: '10',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 11": "Child’s size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.11',
    facet: 'size11',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '3',
    filter: 'size11',
    id: '11',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 12": "Waist measurement"/"Belt size" (women)
    // EXCEPTION: international (US) should be sorted by ClothingSize
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.12',
    facet: 'size12',
    limit: 10,
    sortBy: {
      default: 'sortByNumericValue',
      US: 'sortClothingSizes',
    },
    universeId: '1',
    filter: 'size12',
    id: '12',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 13": "Glove size" (women)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.13',
    facet: 'size13',
    limit: 20,
    sortBy: 'sortClothingSizes',
    universeId: '1',
    filter: 'size13',
    id: '13',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 14": "Hat size" (women)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.14',
    facet: 'size14',
    limit: 20,
    sortBy: 'asc',
    universeId: '1',
    filter: 'size14',
    id: '14',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 15": "Ring size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.15',
    facet: 'size15',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '1',
    filter: 'size15',
    id: '15',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 16": "Waist measurement"/ "Belt size" (men)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.16',
    facet: 'size16',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '2',
    filter: 'size16',
    id: '16',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 17": "Glove size" (men)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.17',
    facet: 'size17',
    limit: 20,
    sortBy: 'sortClothingSizes',
    universeId: '2',
    filter: 'size17',
    id: '17',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 18": "Hat size" (men)
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.18',
    facet: 'size18',
    limit: 20,
    sortBy: 'asc',
    universeId: '2',
    filter: 'size18',
    id: '18',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 19": "Suit size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.19',
    facet: 'size19',
    limit: 20,
    sortBy: 'sortByNumericValue',
    universeId: '2',
    filter: 'size19',
    id: '19',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 20": "Shirt size"
    // EXCEPTION: international (US), JP, KR (wrong data in spreadsheet) should be sorted by ClothingSize
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.20',
    facet: 'size20',
    limit: 20,
    sortBy: {
      default: 'sortByNumericValue',
      US: 'sortClothingSizes',
      KR: 'sortClothingSizes',
    },
    universeId: '2',
    filter: 'size20',
    id: '20',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 21": "Trousers size"
    // EXCEPTION: KR should be sorted by NumericValue
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.21',
    facet: 'size21',
    limit: 10,
    sortBy: {
      default: 'sortClothingSizes',
      US: 'sortClothingSizes',
      KR: 'sortClothingSizes',
    },
    universeId: '2',
    filter: 'size21',
    id: '21',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    // "SIZE 22": "Swimwear size"
    // EXCEPTION: international (US) and JP, KR should be sorted by ClothingSize
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.22',
    facet: 'size22',
    limit: 20,
    sortBy: {
      default: 'sortByNumericValue',
      US: 'sortClothingSizes',
      KR: 'sortClothingSizes',
    },
    universeId: '1',
    filter: 'size22',
    id: '22',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    //  "SIZE 23": "Polo size"
    type: 'text-swatch',
    label: 'CATALOG.FILTERS.SIZE.23',
    facet: 'size23',
    limit: 10,
    sortBy: 'sortClothingSizes',
    universeId: '1',
    filter: 'size23',
    id: '23',
    group: FilterTopLevelGroup.SIZE,
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.CONDITION',
    facet: 'condition',
    sortBy: 'sortById',
    filter: 'condition.id',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.WATCH_MECHANISM',
    facet: 'watchMechanism',
    filter: 'watchMechanism.id',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.MATERIALS',
    facet: 'materialLvl0',
    sortBy: 'asc',
    filter: 'materialLvl0.id',
    child: {
      type: 'item',
      label: 'CATALOG.FILTERS.MATERIALS',
      facet: 'materialLvl1',
      sortBy: 'asc',
      filter: 'materialLvl1.id',
    },
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.EDITORPICKS',
    facet: 'editorPicks',
    filter: 'editorPicks',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.SOLD',
    facet: 'sold',
    itemText: ['CATALOG.FILTERS.SOLD.HIDE', 'CATALOG.FILTERS.SOLD.SHOW'],
    filter: 'sold',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.DEALS_ELIGIBLE',
    facet: 'dealEligible',
    itemText: ['CATALOG.FILTERS.DEALS_LABEL'],
    filter: 'dealEligible',
  },
  {
    type: 'item',
    label: 'CATALOG.FILTERS.DISCOUNT',
    facet: 'discount',
    filter: 'discount',
  },
];

export const filterUiOrder = [
  'dutyFree',
  'universe',
  'categoryLvl0',
  'categoryLvl1',
  'categoryLvl2',
  'brand',
  'model',
  'color',
  'condition',
  'size-top-level-group',
  'size1',
  'size2',
  'size3',
  'size4',
  'size5',
  'size6',
  'size7',
  'size8',
  'size9',
  'size10',
  'size11',
  'size12',
  'size13',
  'size14',
  'size15',
  'size16',
  'size17',
  'size18',
  'size19',
  'size20',
  'size21',
  'size22',
  'size23',
  'country',
  'price',
  'materialLvl0',
  'localCountries',
  'stock',
  'directShippingEligible',
  'watchMechanism',
  'editorPicks',
  'sellerBadge',
  'isOfficialStore',
  'sold',
  'discount',
  'dealEligible',
];

export const sortedFilterGroupConfiguration = filterGroupConfigurations.sort(
  (a, b) => filterUiOrder.indexOf(a.facet) - filterUiOrder.indexOf(b.facet),
);

export const sizeFilterGroupConfigurations = filterGroupConfigurations.filter(
  (x) => x.group === FilterTopLevelGroup.SIZE && x.type === 'text-swatch',
);

export default filterGroupConfigurations;
