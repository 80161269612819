import React, { FC } from 'react';
import {
  CMSField as FieldProps,
  CMSFieldType as FieldType,
  CMSSimpleTextFieldType as TextFieldType,
} from '@interfaces/models/cms/campaign-hero';
import sanitize from '@helpers/sanitizer';

import { useFieldStylesAndClasses } from '../cms-block-fields';

const RenderTextContent: FC<{
  fieldType: FieldType | TextFieldType;
  data: FieldProps['data'];
  blockStyle?: string;
}> = ({ fieldType, data, blockStyle }): React.JSX.Element => {
  const { fieldStyle, text } = data;
  const isHtml = fieldStyle.textType === 'html';
  const convertedFieldType =
    fieldType === TextFieldType.Tag || fieldType === TextFieldType.Subtitle || fieldType === TextFieldType.Title
      ? `${fieldType}Field`
      : `${fieldType}`;
  const { fieldStyles, fieldClass } = useFieldStylesAndClasses(convertedFieldType, data, blockStyle);

  return (
    <div
      className={fieldClass}
      style={fieldStyles}
      data-cy={`${fieldType}-text`}
    >
      {isHtml ? <span dangerouslySetInnerHTML={{ __html: sanitize(text) }} /> : <span>{text}</span>}
    </div>
  );
};

export const SimpleTextField: FC<
  FieldProps & {
    blockStyle?: string;
  }
> = ({ data, blockStyle }): JSX.Element => {
  return (
    <RenderTextContent
      fieldType={data?.simpleTextFieldType}
      data={data}
      blockStyle={blockStyle}
    />
  );
};
