import Dialog from '@components/atoms/dialog/dialog';
import FeedbackWrap from '@components/catalog/my-sizes/my-sizes/response-feedback/feedback-dialog';
import Button from '@components/atoms/button/button';
import Icon from '@components/atoms/icon/icon';
import { debounce } from '@helpers/utils/general';
import ProgressBar from '@components/atoms/progress-bar/progress-bar';
import Wizard from '@components/catalog/my-sizes/wizard/wizard';
import MySizesFooter from '@components/catalog/my-sizes/my-sizes/footer/footer';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useContextSelector } from 'use-context-selector';
import { UserContext } from '@context/user.context';
import { MySizes as Sizes, SizeCatType, SizeObjType, WizardStepType } from '@interfaces/models/mySizes';
import StepOne, { ImageCard } from '@components/catalog/my-sizes/my-sizes/steps/stepOne';
import StepSize from '@components/catalog/my-sizes/my-sizes/steps/stepSize';
import { RespStatusType } from '@components/catalog/my-sizes/my-sizes/response-feedback/types';
import clsx from 'clsx';
import styles from './add-my-sizes-dialog.module.scss';

interface AddMySizesDialogProps {
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogOpen: boolean;
  allAvailableSteps: WizardStepType[];
  actionRef: React.MutableRefObject<string>;
  newSizesRef: React.MutableRefObject<Sizes | null>;
  handleUpdateSizes: () => void;
  getSizeId: (currentStep: number, universe: number, allAvailableSteps: WizardStepType[]) => string;
  dataFetching: boolean;
  respStatus: RespStatusType;
  handleMySizeChange: (currentSizes: Sizes) => Promise<Sizes>;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  isWebview?: boolean;
}

const AddMySizesDialog = ({
  dialogOpen,
  allAvailableSteps,
  actionRef,
  newSizesRef,
  handleUpdateSizes,
  getSizeId,
  dataFetching,
  respStatus,
  setDialogOpen,
  handleMySizeChange,
  setCurrentStep,
  currentStep,
  isWebview,
}: AddMySizesDialogProps) => {
  const [wizardSteps, setWizardSteps] = useState([]);
  const [stepValue, setStepValue] = useState({});
  const stepValueRef = useRef({});
  const [curUniverse, setCurUniverse] = useState({} as ImageCard);

  const sizes = useContextSelector(UserContext, (v) => v.userSizes);
  const setUserSizes = useContextSelector(UserContext, (v) => v.setUserSizes);

  const sliderRef = useRef(null);
  const curUniverseRef = useRef({} as ImageCard);

  const handleNext = () => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.next();
  };

  const handlePrev = () => {
    if (!sliderRef.current) {
      return;
    }

    sliderRef.current.prev();
  };

  const handleSlideChangeCallback = (activeIndex: number) => {
    setCurrentStep(activeIndex);
  };

  // update step value
  const handleStepValueChange = (val: string, item: SizeObjType, checked: boolean, sizeData: SizeCatType) => {
    // can not use stepvalue,not updated yet.
    const newValue = { ...stepValueRef.current };

    if (typeof newValue[sizeData.label] === 'undefined') {
      newValue[sizeData.label] = {
        id: sizeData.id,
        label: sizeData.label,
        sizes: [],
      };
    } else {
      newValue[sizeData.label] = { ...stepValueRef.current[sizeData.label] };
      newValue[sizeData.label].sizes = [...newValue[sizeData.label].sizes];
    }

    if (checked) {
      newValue[sizeData.label].sizes.push(item);
    } else {
      newValue[sizeData.label].sizes = newValue[sizeData.label].sizes.filter((s) => s.id !== item.id);
    }

    setStepValue(newValue);
    stepValueRef.current = newValue;
  };

  const lastStep = useMemo(
    () =>
      typeof curUniverseRef.current.universe !== 'undefined' &&
      allAvailableSteps[curUniverseRef.current.universe].size_categories &&
      currentStep === allAvailableSteps[curUniverseRef.current.universe].size_categories.length,
    [currentStep, allAvailableSteps],
  );

  const hasChecked = useMemo(() => {
    if (typeof curUniverseRef.current.universe !== 'undefined') {
      const items = allAvailableSteps[curUniverseRef.current.universe]?.size_categories || [];
      const item = items[currentStep - 1];

      return item && stepValue[item.label]?.sizes.length > 0;
    } else {
      return false;
    }
  }, [stepValue, allAvailableSteps, currentStep]);

  // generate the data and pass to callback
  const lastStepCallback = (stepValue) => {
    const data = {
      filter: sizes.filter,
      size_categories: [],
      universe: {},
    };

    data.size_categories = Object.values(stepValue);
    data.universe = {
      ids: curUniverseRef.current.ids,
      label: curUniverseRef.current.title,
    };

    // promise
    actionRef.current = 'update';
    handleMySizeChange(data as Sizes).then((data) => {
      if (data) {
        newSizesRef.current = data;
      }
    });
  };

  const genWizardSteps = (currentUniverse: number) => {
    const items = allAvailableSteps[currentUniverse]?.size_categories || [];

    return items.map((item, i) => {
      return (
        <StepSize
          changeHandler={handleStepValueChange}
          key={`${item.id}-${i}-${currentUniverse}`}
          sizeData={item}
        />
      );
    });
  };

  const stepOne = [
    <StepOne
      key="pers-wiz-s1"
      onSelect={(e) => {
        curUniverseRef.current = e;
        setCurUniverse(e);
        setStepValue({});
        stepValueRef.current = {};
        setWizardSteps(genWizardSteps(e.universe));
      }}
    />,
  ];

  useEffect(() => {
    if (wizardSteps) {
      handleNext();
    }
  }, [wizardSteps]);

  return (
    <Dialog
      isOpen={dialogOpen}
      closeDialog={() => {
        if (actionRef.current === 'update' && newSizesRef.current) {
          setUserSizes(newSizesRef.current);
        }
        setDialogOpen(false);
      }}
      styles={{
        content: {
          maxWidth: '755px',
          width: '100%',
          flexDirection: 'column',
          padding: '0px',
        },
      }}
      key={`${dialogOpen}`}
      hasCloseIcon={!isWebview}
      hasCloseTxt
      customClass={styles.creatingDialog}
      focusTrapActive={false}
    >
      <FeedbackWrap
        loading={dataFetching}
        respStatus={respStatus}
        feedbackCallback={() => {
          handleUpdateSizes();
          setDialogOpen(false);
        }}
      >
        <>
          <div
            className={clsx(styles.add_my_sizes__wizard__backButtonAndPrograss, {
              [styles.add_my_sizes__wizard__backButtonAndPrograss__withCloseBtn]: !isWebview,
            })}
          >
            {!!currentStep && (
              <div className={styles.add_my_sizes__wizard__backButtonContainer}>
                <Button onClick={() => debounce(handlePrev)()}>
                  <Icon
                    name="arrow-head-left"
                    className={styles.add_my_sizes__wizard__backIcon}
                  />
                </Button>
              </div>
            )}
            <div className={styles.add_my_sizes__wizard__progressBarContainer}>
              <ProgressBar value={(currentStep * 100) / wizardSteps.length} />
            </div>
          </div>
          <Wizard
            ref={sliderRef}
            steps={[stepOne, ...wizardSteps]}
            slideChangeCallback={handleSlideChangeCallback}
          />
          {currentStep > 0 && (
            <MySizesFooter
              lastStep={lastStep}
              lastStepCallback={() => lastStepCallback(stepValue)}
              hasChecked={hasChecked}
              handleNext={handleNext}
              allAvailableSteps={allAvailableSteps}
              sizeId={getSizeId(currentStep, curUniverse.universe, allAvailableSteps)}
              drawerCustomClass={styles.drawerStylefix}
            />
          )}
        </>
      </FeedbackWrap>
    </Dialog>
  );
};

export default AddMySizesDialog;
