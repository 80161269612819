import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import sanitizer from '@helpers/sanitizer';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { EditoPageCTA } from '@interfaces/models/editorialPage';
import CTAElement from '../text-container/components/CTAElement';
import styles from './text-component.module.scss';

type TextComponentProps = {
  body: string;
  _metadata: { uid: string };
  label: string;
  body_s_title: string;
  componentID: string;
  sectionIdx?: string;
  ctas?: Array<EditoPageCTA>;
};

const TextComponent: React.FC<TextComponentProps> = (props) => {
  const { body, label, componentID, sectionIdx, _metadata, ctas } = props;
  const contentTypeUID = componentID ? componentID.split('-')[0] : '';
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${_metadata?.uid}_${label}`,
        label: sectionIdx ?? '',
      });
    }, [contentTypeUID, label, sectionIdx, _metadata?.uid]),
    0.5,
    false,
  );

  const handleOnClickCTA = (cta, meta, idx) => {
    sendAnalyticEvent('cms_content_click', {
      category: 'cms_content_click',
      action: contentTypeUID,
      property: `${meta?.uid}_${label}`,
      label: idx + 1 ? `${String(idx + 1)}_${sectionIdx}` : sectionIdx,
    });

    if (!cta?.href) {
      return null;
    }
    if (typeof window !== 'undefined') {
      window.location.assign(cta?.href);
    }
  };

  const ctaElements = useMemo(() => {
    if (!ctas?.length) {
      return null;
    }

    return (
      <div className={styles.textcontainer_cta_module}>
        {ctas?.map(({ cta, _metadata: meta, cta_s_background_color, cta_s_text_color }, index) => {
          const { uid } = meta || {};
          const key = uid + index;
          return (
            <CTAElement
              key={key}
              cta={cta}
              handleOnClick={handleOnClickCTA}
              metadata={meta}
              index={index}
              bgColor={cta_s_background_color}
              titleColor={cta_s_text_color}
            />
          );
        })}
      </div>
    );
  }, [ctas]);

  return (
    <section ref={containerRef}>
      <div className="innerContainer">
        {body && (
          <div
            className={clsx(styles.text, styles.wysiwyg)}
            dangerouslySetInnerHTML={{ __html: sanitizer(body, {}, true) }}
          />
        )}
        {ctaElements}
      </div>
    </section>
  );
};

export default TextComponent;
