import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import MerchandisingItem from '@components/organisms/merchandise/components/merchandising-item';
import { Merchandise as IMerchandise } from '@interfaces/models/merchandise';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import useWindowSize from '@hooks/use-window-size';
import styles from './merchandise.module.scss';

const Merchandise: React.FC<IMerchandise> = ({
  uid,
  displayTitle: title,
  bodyBackground,
  blocks: merchandisingBlocks,
  contentTypeUid: contentTypeUID,
  title: label,
  sectionIdx,
  displayStyle = 'slider',
  imagesAspectRatio = '1:1',
  itemTitleTextStyle,
  itemSubtitleTextStyle,
}) => {
  const { isWiderThanMd } = useWindowSize();
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${uid}_${label}`,
        label: sectionIdx ?? '',
      });
    }, [contentTypeUID, label, sectionIdx, uid]),
    0.5,
    false,
  );

  const merchandisingList = useMemo(() => {
    return merchandisingBlocks?.map((merchandisingBlock, index) => {
      const { displayTitle: title, uid } = merchandisingBlock;
      const blockLength = merchandisingBlocks?.length;
      const key = `${title || ''}-${uid}`;
      const merchBlock = {
        ...merchandisingBlock,
        displayStyle,
        imagesAspectRatio,
        itemTitleTextStyle,
        itemSubtitleTextStyle,
        sectionIdx,
      };

      return (
        <React.Fragment key={key}>
          <MerchandisingItem
            merchandisingBlock={merchBlock}
            length={blockLength}
            index={index}
          />
        </React.Fragment>
      );
    });
  }, [merchandisingBlocks]);

  if (!merchandisingList?.length) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      style={bodyBackground ? { background: bodyBackground } : {}}
    >
      <section className={clsx('innerContainer', styles.merchandisingContainer)}>
        {title ? (
          <h2 className={clsx('vc-title-m', styles.component_title, !isWiderThanMd && styles.title_spacing)}>
            {title}
          </h2>
        ) : null}
        <div
          className={clsx(styles.entriesWrapper, styles[`wrapperMerch-${merchandisingBlocks.length}`])}
          data-testid="miniCapmapignContainer"
        >
          <ul>{merchandisingList}</ul>
        </div>
      </section>
    </div>
  );
};

export default Merchandise;
