import React, { useCallback } from 'react';
import clsx from 'clsx';
import useOnReveal from '@hooks/use-on-reveal';
import YouTubePlayer from '@components/atoms/youtube-player/youtube-player';
import VideoPlayer from '@components/atoms/video-player/video-player';
import { VideoContainer as IVideoContainer } from '@interfaces/models/editorialPage';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import styles from './video-container.module.scss';

const VideoContainer: React.FC<IVideoContainer> = ({
  cs_video: fileVideoConfig,
  label,
  youtube_video_url: youtubeVideoUrl,
  componentID,
  sectionIdx,
  _metadata,
}) => {
  const contentTypeUID = componentID ? componentID.split('-')[0] : '';
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${_metadata?.uid}_${label}`,
        label: sectionIdx,
      });
    }, [contentTypeUID, label, sectionIdx, _metadata?.uid]),
    0.5,
    false,
  );

  const { url: fileVideoUrl } = fileVideoConfig || {};

  if (!youtubeVideoUrl && !fileVideoUrl) {
    return null;
  }

  const videoPlayer = () => {
    if (youtubeVideoUrl) {
      return <YouTubePlayer url={youtubeVideoUrl} />;
    } else if (fileVideoUrl) {
      return <VideoPlayer url={fileVideoUrl} />;
    }

    return null;
  };

  return (
    <section ref={containerRef}>
      <div className={clsx(styles.video, 'innerContainer')}>
        <div className={styles.video_wrapper}>
          <div className={styles.video_responsive}>{videoPlayer()}</div>
        </div>
      </div>
    </section>
  );
};

export default VideoContainer;
