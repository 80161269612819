import React, { useRef } from 'react';
import Button from '@components/atoms/button/button';
import { useTranslation } from 'next-i18next';
import { CartContext } from '@context/cart.context';
import { useAnalytics } from '@context/analytics.context';
import { useContextSelector } from 'use-context-selector';
import { useBucket } from '@context/bucket.context';
import dynamic from 'next/dynamic';
import Icon from '@components/atoms/icon/icon';
import styles from './cart.module.scss';

const FloatingCart = dynamic(() => import('@components/atoms/cart/floating-cart/floating-cart'), {
  ssr: false,
});

const PreviewBox = dynamic(() => import('@components/atoms/preview-box/preview-box'), {
  ssr: false,
});

const Cart: React.FC = () => {
  const { t } = useTranslation('default-layout');
  const { sendEvent } = useAnalytics();
  const { isFeatureEnabled } = useBucket();
  const cartUrl = useContextSelector(CartContext, (v) => v.cartUrl);
  const productsInCart = useContextSelector(CartContext, (v) => v.productsInCart);
  const hasFloatingCart = isFeatureEnabled((f) => !f.hideMiniCart, true);

  const isCartPreviewOpened = useContextSelector(CartContext, (v) => v.isCartPreviewOpened);
  const setIsCartPreviewOpened = useContextSelector(CartContext, (v) => v.setIsCartPreviewOpened);

  const cartRef = useRef<HTMLDivElement>(null);

  const closePreviewBox = (): void => {
    setIsCartPreviewOpened(false);
  };

  const cartAction = (): void => {
    if (hasFloatingCart) {
      setIsCartPreviewOpened(!isCartPreviewOpened);
    } else {
      window.location.assign(cartUrl);
    }
    sendEvent(
      {
        type: 'event',
        payload: {
          event: 'headerClick',
          button_name: 'cart',
        },
      },
      {
        type: 'event',
        payload: {
          event: 'navigation_header',
          category: 'navigation',
          action: 'header',
          label: 'cart',
          value: '6',
        },
      },
    );
  };

  return (
    <div
      className={styles.cart}
      ref={cartRef}
    >
      <Button
        aria-label={t('HEADER.NAVIGATION.CART_TITLE')}
        aria-haspopup="true"
        aria-expanded={isCartPreviewOpened}
        disableDefaultStyling
        onClick={cartAction}
        data-cy="floating_cart_button"
      >
        <Icon
          className={styles.cart__cartIcon}
          name="bag"
        />
        {/* Show the amount of items in the cart if there are any */}
        {productsInCart.length > 0 && (
          <span className={styles.cart__count}>{productsInCart.length > 99 ? '99+' : productsInCart.length}</span>
        )}
      </Button>
      {/* Opens a popup with a list of items in the cart */}
      {isCartPreviewOpened && (
        <PreviewBox
          isActive={isCartPreviewOpened}
          title={t('HEADER.NAVIGATION.CART_TITLE')}
          closePreviewBox={closePreviewBox}
          outsideClickRef={cartRef}
          isCart={true}
        >
          <div className={styles.cart__previewBoxContent}>
            <FloatingCart />
          </div>
        </PreviewBox>
      )}
    </div>
  );
};

export default Cart;
