import React, { FC, MouseEvent, KeyboardEvent } from 'react';

import { CMSField as FieldProps, CMSFieldType as FieldType } from '@interfaces/models/cms/campaign-hero';
import sanitize from '@helpers/sanitizer';
import Icon from '@components/atoms/icon/icon';
import Button from '@components/atoms/button/button';
import { useFieldStylesAndClasses } from '../cms-block-fields';

import styles from '../cms-block-fields.module.scss';

const newBlockStyles = {
  voucher: 'voucher',
  marketing_medium: 'marketing_medium',
  marketing_small: 'marketing_small',
};

const RenderCTAContent: FC<{
  fieldType: FieldType;
  data: FieldProps['data'];
  onClick?: (e: MouseEvent | KeyboardEvent, data: FieldProps['data']) => void;
  blockStyle?: string;
}> = ({ fieldType, data, onClick, blockStyle }): React.JSX.Element => {
  const { text, fieldStyle } = data;
  const isHtml = fieldStyle.textType === 'html';
  const { fieldStyles, fieldClass } = useFieldStylesAndClasses(FieldType.CTA, data, blockStyle);

  const handleClick = (e: MouseEvent | KeyboardEvent): void => {
    onClick?.(e, data);
  };

  return (
    <div
      className={fieldClass}
      style={fieldStyles}
      data-cy={`${fieldType}-button`}
    >
      {newBlockStyles[blockStyle] ? (
        <div
          className={styles.field__voucherCtaField}
          onClick={handleClick}
        >
          <div className={styles.field__voucherCtaField__text}>
            {isHtml ? <span dangerouslySetInnerHTML={{ __html: sanitize(text) }} /> : <span>{text}</span>}
          </div>
          <div className={styles.field__voucherCtaField__button}>
            <Icon
              className={styles.field__voucherCtaField__arrow}
              name="arrow-right-long-20"
            />
          </div>
        </div>
      ) : (
        <Button
          variant="rounded-secondary"
          size="large"
          fullWidth
          notFullWidthQuery="md-up"
          onClick={handleClick}
          className={styles['field--ctaField-button']}
        >
          {isHtml ? <span dangerouslySetInnerHTML={{ __html: sanitize(text) }} /> : <span>{text}</span>}
        </Button>
      )}
    </div>
  );
};

export const CTAField: FC<
  FieldProps & {
    onClick?: (e: MouseEvent | KeyboardEvent, data: FieldProps['data']) => void;
    blockStyle?: string;
  }
> = ({ data, onClick, blockStyle }): React.JSX.Element => {
  return (
    <RenderCTAContent
      data={data}
      fieldType={FieldType.CTA}
      onClick={onClick}
      blockStyle={blockStyle}
    />
  );
};
