import { useQuery } from '@tanstack/react-query';
import { ActiveDealQueryKeys } from '@enums/react-query-keys';
import { Deal } from '@interfaces/models/deal';
import { getActiveDeal } from '@api/deals';

const useActiveDealQuery = () => {
  const { data: activeDeal } = useQuery<Deal>({
    queryKey: [ActiveDealQueryKeys.ACTIVE_DEAL],
    staleTime: Infinity,
    queryFn: () => getActiveDeal()
  });

  return {
    activeDeal,
  };
};

export default useActiveDealQuery;
