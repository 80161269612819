import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Icon from '@components/atoms/icon/icon';
import Button from '@components/atoms/button/button';
import { RespStatusType } from './types';
import styles from './response-feedback.module.scss';

interface RespFeedbackProps {
  respStatus: RespStatusType;
  title?: string;
  feedbackCallback: () => void;
}

const respTxt = {
  success: {
    title: 'PLP.MYSIZES_DIALOG.SUCCESS_TITLE',
    content: 'PLP.MYSIZES_DIALOG.UPDATE_RESULT',
    btnTxt: 'PLP.MYSIZES_DIALOG.UPDATE_BUTTON',
  },
  error: {
    title: 'PLP.MYSIZES_DIALOG.ERROR_TITLE',
    content: 'PLP.MYSIZES_DIALOG.ERROR_CONTENT',
    btnTxt: 'PLP.MYSIZES_DIALOG.ERROR_BUTTON',
  },
};

const RespFeedback: React.FC<RespFeedbackProps> = ({
  respStatus,
  title,
  // content,
  // btnText,
  feedbackCallback,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.respContainer}>
      <div className={styles.respStatusContainer}>
        <Icon
          className={clsx(styles.respIcon, respStatus === 'error' && styles.error)}
          name={respStatus === 'success' ? 'check-full' : 'issue'}
        />
      </div>
      <div className={styles.respTitle}>{title ? title : t(respTxt[respStatus].title)}</div>
      <div className={styles.respContent}>{t(respTxt[respStatus].content)}</div>
      <div className={styles.respButton}>
        <Button
          variant="primary"
          size="large"
          className={styles.feedbackBtn}
          onClick={feedbackCallback}
        >
          {t(respTxt[respStatus].btnTxt)}
        </Button>
      </div>
    </div>
  );
};

export default RespFeedback;
