import React from 'react';
import { useTranslation } from 'next-i18next';

const AccessibilityLink: React.FC = () => {
  const { t } = useTranslation('default-layout');

  return (
    <a
      href="#"
      className="accessibilityTopLink UsableNetAssistive"
      onClick={() => global?.enableUsableNetAssistive()}
    >
      {t('ACCESSIBILITY.LINK.ENABLE')}
    </a>
  );
};

export default AccessibilityLink;
