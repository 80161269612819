import {
  CatalogPageURLBooleanFilters,
  CatalogPageURLNumericFilters,
  CatalogPageURLStringFilters,
  CatalogPageURLFilters,
  ElasticSearchBooleanFilterProperties,
  ElasticSearchFilterKeys,
  ElasticSearchSizes,
  ElasticSearchStringFilterProperties,
  ElasticSearchStringListFilterProperties,
} from '@interfaces/models/elasticSearch';
import filterGroupsConfiguration from '@config/catalog/filter-group-configurations';
import { FilterTopLevelGroup } from '@interfaces/models/catalogFilter';

/**
 * URL fragments
 */

export const catalogUrlBooleanFilters: CatalogPageURLBooleanFilters[] = [
  'dealsEligible',
  'seller.isOfficialStore',
  'stock',
  'localCountries',
  'dsEligible',
  'dutyFree',
];

export const catalogUrlNumericFilters: CatalogPageURLNumericFilters[] = [
  'gender',
  'category',
  'categoryParent',
  'subcategory',
  'material',
  'materialParent',
  'color',
  'brand',
  'condition',
  'region',
  'model',
  'pattern',
  'mechanism',
];

export const catalogUrlStringFilters: CatalogPageURLStringFilters[] = [
  'sold',
  'dsEligibleCountries',
  'sellerBadge',
  'price',
  'country',
  'discount',
  'priceMin',
  'priceMax',
  'editorPicks',
  ...[...Array(24)].map((e, i) => `size${i}` as CatalogPageURLStringFilters),
];

export const availableUrlFilterKeys: CatalogPageURLFilters[] = [
  ...catalogUrlStringFilters,
  ...catalogUrlNumericFilters,
  ...catalogUrlBooleanFilters,
];

/**
 * Filters
 */

export const booleanFilters: ElasticSearchBooleanFilterProperties[] = [
  'stock',
  'dealEligible',
  'isOfficialStore',
  'localCountries',
  'directShippingEligible',
  'dutyFree',
];

export const isBooleanFilterKey = (
  filterGroupName: ElasticSearchFilterKeys,
): filterGroupName is ElasticSearchBooleanFilterProperties =>
  booleanFilters.includes(filterGroupName as ElasticSearchBooleanFilterProperties);

export const stringFilters: ElasticSearchStringFilterProperties[] = ['directShippingCountries'];

export const stringListFilters: ElasticSearchStringListFilterProperties[] = [
  'brand.id',
  'model.id',
  'universe.id',
  'color.id',
  'condition.id',
  'region.id',
  'watchMechanism.id',
  'categoryLvl0.id',
  'categoryLvl1.id',
  'categoryLvl2.id',
  'materialLvl0.id',
  'materialLvl1.id',
  'pattern.id',
  'editorPicks',
  'sold',
  'country',
  'discount',
  'priceRange',
  'sellerBadge',
  'catalogLinksWithoutLanguage',
  ...[...Array(24)].map((e, i) => `size${i}` as ElasticSearchSizes),
];

export const isStringListFilterKey = (
  filterGroupName: ElasticSearchFilterKeys,
): filterGroupName is ElasticSearchStringListFilterProperties =>
  stringListFilters.includes(filterGroupName as ElasticSearchStringListFilterProperties);

export const availableFilterKeys: ElasticSearchFilterKeys[] = [
  ...booleanFilters,
  ...stringListFilters,
  ...stringFilters,
];

export const sizeFilterFacetFields = filterGroupsConfiguration
  .filter(
    (filterGroupConfiguration) =>
      filterGroupConfiguration.type !== 'top-level-group' &&
      filterGroupConfiguration.group === FilterTopLevelGroup.SIZE,
  )
  .map((filterGroupConfiguration) => filterGroupConfiguration.facet);
