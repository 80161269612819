import { axios, getAxiosConfig } from '@config/axios';
import Environment from '@config/index';
import { Negotiation } from '@interfaces/models/negotiation/negotiation';
import { NegotiationEventRequest } from '@interfaces/models/negotiation/negotiationEventRequest';
import { ApiResponse } from '@interfaces/api';
import { UserTransactionRole } from '@interfaces/models/userTransactionRole';

export class NegotiationsService {
  static apiBaseUrl = Environment.apiBaseUrl;

  static async listNegotiations(role: UserTransactionRole, withEvents: boolean): Promise<Negotiation[]> {
    const axiosConfig = getAxiosConfig();
    if (role) {
      axiosConfig.params.role = role;
    }
    if (withEvents) {
      axiosConfig.params.withEvents = withEvents;
    }
    const {
      data: { data },
    } = await axios.get<ApiResponse<Negotiation[]>>(`${this.apiBaseUrl}/negotiations`, axiosConfig);
    return data;
  }

  static async getNegotiation(id: Negotiation['id']): Promise<Negotiation | undefined> {
    const axiosConfig = getAxiosConfig();

    const {
      data: { data },
    } = await axios.get<ApiResponse<Negotiation | undefined>>(`${this.apiBaseUrl}/negotiations/${id}`, axiosConfig);

    return data || undefined;
  }

  static async addNegotiationEvent(
    id: Negotiation['id'],
    payload: NegotiationEventRequest,
  ): Promise<ApiResponse<Negotiation>> {
    const axiosConfig = getAxiosConfig();

    const { data } = await axios.post<ApiResponse<Negotiation>>(
      `${this.apiBaseUrl}/negotiations/${encodeURIComponent(String(id))}/events`,
      payload,
      axiosConfig,
    );
    return data;
  }

  static async executeFPD(
    id: Negotiation['id'],
    payload: NegotiationEventRequest | {},
  ): Promise<ApiResponse<Negotiation>> {
    const axiosConfig = getAxiosConfig();

    const { data } = await axios.post<ApiResponse<Negotiation>>(
      `${this.apiBaseUrl}/negotiations/${encodeURIComponent(String(id))}/flash-price-drop`,
      payload,
      axiosConfig,
    );
    return data;
  }
}
