import clsx from 'clsx';
import React from 'react';
import sanitizer from '@helpers/sanitizer';
import styles from './text-block.module.scss';

export type TextBlockProps = {
  title?: string;
  children: string;
} & React.HTMLProps<HTMLDivElement>;

const TextBlock: React.FC<TextBlockProps> = ({ title, children }) => {
  return (
    <>
      {!!title && <h3 className={clsx('vc-title-s', styles.title)}>{title}</h3>}
      {!!children && (
        <div
          className={clsx('vc-text-m', styles.main_text)}
          dangerouslySetInnerHTML={{
            __html: sanitizer(children),
          }}
        />
      )}
    </>
  );
};

export default TextBlock;
