import UserBadge from '@enums/userBadge';
import Environment from '@config/environment';
import { Product } from '@interfaces/models/product';
import { Shipping } from '@interfaces/models/shipping';

export const isVintage = (product: Product): boolean => {
  return !!product?.tags?.includes('vintage');
};

export const isProductSoldByAProfessionalSeller = (product: Product): boolean => {
  return !!product.seller?.badges?.includes(UserBadge.Pro);
};

export const isSellerOnVacation = (product: Product): boolean => {
  return product?.seller?.vacation?.active;
};

export const isShippable = (shipping: Shipping): boolean => {
  const covidSituation = shipping?.prohibited && shipping?.prohibitedReason === 'covid';
  const prohibitedRules = covidSituation || !shipping?.prohibited;
  const buyableRules = shipping?.buyable || (!shipping?.buyable && covidSituation);
  return buyableRules && prohibitedRules;
};

export const isAuthenticationMandatory = (product: Product): boolean => {
  return !product.flags?.includes('direct-shipping');
};

export function getDSALink(productID: string, email: string, local: string): string {
  let lang = 'en-gb';
  switch (local) {
    case 'de':
    case 'es':
    case 'fr':
    case 'it':
    case 'sv':
    case 'nl':
      lang = local;
      break;
    case 'hk':
      lang = 'zh-hk';
      break;
  }

  const qcUri = Environment.zendesk.dsaPath
    .replace('<langPlaceholder>', lang)
    .replace('<pIdPlaceholder>', productID)
    .replace('<userEmailPlaceholder>', email);
  return `${Environment.zendesk.baseUrl}${qcUri}`;
}
