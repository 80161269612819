import React from 'react';
import { FooterContentExtended } from '@interfaces/models/footer';
import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useIntersectionObserver } from 'usehooks-ts';
import { FooterQueryKeys } from '@enums/react-query-keys';
import { usePreferences } from '@context/preferences.context';
import { getFooter } from '@api/static';

const Footer = dynamic(() => import('@components/organisms/footer/footer'));

const defaultFooterContent: FooterContentExtended = {
  navigation: [],
  domainsLinks: [],
  legals: [],
  socials: [],
  showAccessibilityLink: true,
};

const DynamicFooterWrapper: React.FC = () => {
  const { siteId, locale } = usePreferences();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const shouldRenderFooter =
    useIntersectionObserver(containerRef, {
      freezeOnceVisible: true,
      threshold: 0.5,
      rootMargin: '0px 0px 700px 0px',
    })?.isIntersecting || false;

  const { data: footerData, isSuccess: hasLoadedFooter } = useQuery<FooterContentExtended>({
    queryKey: [FooterQueryKeys.FOOTER_DATA, siteId, locale],
    queryFn: () => getFooter({ siteId, locale }),
    initialData: defaultFooterContent,
    enabled: shouldRenderFooter,
  });

  return (
    <div
      ref={containerRef}
      style={{
        minHeight: 200,
      }}
    >
      {shouldRenderFooter && hasLoadedFooter && <Footer footerContent={footerData} />}
    </div>
  );
};

export default DynamicFooterWrapper;
