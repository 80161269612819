import { useRouter } from 'next/router';

const useRecentSearchParams = () => {
  const { query } = useRouter();
  const recentSearchDate = query?.searchDate;
  const isRecentSearch = !!recentSearchDate;
  const isSmartRecency = query?.smartRecency === '1';

  return { isRecentSearch, recentSearchDate, isSmartRecency };
};

export default useRecentSearchParams;
