import React from 'react';
import useProductCatalogProducts from '@hooks/catalog-product/use-product-catalog-products';
import useRecentlyViewedProducts from '@hooks/product/use-recently-viewed-products';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { Product } from '@interfaces/models/product';

export interface ProductQuery {
  arrangement?: string;
  isPersonalizable?: boolean;
  displayTitle?: string;
  productType: string;
  campaign_id?: number;
  ctaText: string;
  sectionIdx: string;
  url: string;
  isPersonalized: boolean;
  productFeedIndex?: 'ng-products-slave-recent' | 'ng-product-master';
  maxProductCount?: number;
  minProductCount?: number;
  fallback?: string;
  fallbackComponents?: { [uid: string]: React.ComponentType };
}

export interface ProductsData {
  products: Product[] | CatalogProduct[];
  isLoading?: boolean;
  isLocal?: boolean;
  isError?: boolean;
}

interface ProductsProviderProps {
  query?: ProductQuery;
}

export const ProductsContext = React.createContext<ProductsData>({ products: [] });

/**
 * Data source for products from SEAS.
 */
export const SeasProductsProvider: React.FC<React.PropsWithChildren<ProductsProviderProps>> = ({ query, children }) => {
  const cta = { href: query.url, title: query.ctaText };

  const isSliderHorizontal = query.arrangement === 'horizontal_slider';
  query.isPersonalized = query.isPersonalizable;

  const { products, isLoading, isError } = useProductCatalogProducts({
    cta,
    ...query,
    isSliderHorizontal,
  });
  const value = { products, isLoading, isError, isLocal: false };

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};

/**
 * Data source for local storage products.
 */
export const LocalProductsProvider: React.FC<React.PropsWithChildren<ProductsProviderProps>> = ({
  query,
  children,
}) => {
  const { recentlyViewedProducts: products } = useRecentlyViewedProducts();
  const maxProductsCount = query?.maxProductCount ?? 12;
  const value = {
    isLoading: false,
    isError: false,
    isLocal: true,
    products: products?.slice(0, maxProductsCount),
  };

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
};
