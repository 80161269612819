import logger from '@helpers/utils/logger/client';
/* eslint-disable @typescript-eslint/no-explicit-any */
// used for static methods of a class
const errorGuard = (funcName: string) => (target: any) => {
  if (!target || typeof target[funcName] !== 'function') {
    return;
  }
  const originFunc: (...arg: any[]) => Promise<any> = target[funcName];
  target[funcName] = (...args: any[]) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return originFunc.apply(target, args).catch((e) => {
      logger.error(e);
    });
  };
};

export default errorGuard;
