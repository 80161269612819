import React, { FCWithChildren, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import useOnClickOutside from '@hooks/browser-events/use-on-click-outside';
import Button from '@components/atoms/button/button';

import parentStyles from './drawer.module.scss';

export type PositionType = 'left' | 'right' | 'top' | 'bottom';

type DrawerProps = {
  onClose?: () => void;
  position?: PositionType;
  open: boolean;
  hideBackdrop?: boolean;
  customClass?: string;
} & React.HTMLProps<HTMLDivElement>;

const Drawer: FCWithChildren<DrawerProps> = ({
  onClose = () => {},
  position = 'right',
  open,
  hideBackdrop = true,
  children,
  customClass,
}) => {
  const DrawerRef = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleClose = () => {
    onClose();
    setIsOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Escape' && isOpen) {
      handleClose();
    }
  };

  useOnClickOutside(DrawerRef, () => {
    handleClose();
  });

  const { drawer, hidden } = parentStyles;

  return (
    <div
      role="button"
      className={clsx(drawer, !isOpen && hidden, customClass)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div
        ref={DrawerRef}
        className={clsx(parentStyles['drawer-container'], parentStyles[position])}
      >
        <Button
          id="close-drawer-btn"
          disableDefaultStyling
          className="vc-modal__closeBtn"
          onClick={handleClose}
          tabIndex={0}
        >
          <span className="vc-modal__closeBtn__crossLeft" />
          <span className="vc-modal__closeBtn__crossRight" />
        </Button>
        {children}
      </div>
      <div
        className={clsx(
          parentStyles['drawer-backdrop'],
          (hideBackdrop || !isOpen) && parentStyles['drawer-backdrop-hidden'],
        )}
      />
    </div>
  );
};

export default Drawer;
