import { useSessionStorage } from 'usehooks-ts';

interface UseRestorePageScrollPositionProps {
  storageKey?: string;
}

const useRestorePageScrollPosition = ({ storageKey }: UseRestorePageScrollPositionProps) => {
  const [scrollPosition, setScrollPosition] = useSessionStorage<string | null>(storageKey || 'scrollPosition', null);

  const scrollWithDelay = (element: HTMLElement): void => {
    const t = setTimeout(() => {
      element.scrollIntoView({ block: 'center' });
      clearTimeout(t);
    }, 1000);
  };

  const pollForElement = (
    getElement: () => HTMLElement | null,
    maxTries = 5,
    intervalDuration = 100,
    currentTry = 0,
  ) => {
    const element = getElement();

    if (element) {
      scrollWithDelay(element);
      setScrollPosition(null);
      return;
    }

    if (currentTry >= maxTries) {
      return;
    }

    // requestAnimationFrame for smooth polling and optimal rendering
    requestAnimationFrame(() => {
      setTimeout(() => {
        pollForElement(getElement, maxTries, intervalDuration, currentTry + 1);
      }, intervalDuration);
    });
  };

  const handleScrollToElement = (getElement: () => HTMLElement | null): void => {
    const element = getElement();
    if (element) {
      scrollWithDelay(element);
    } else {
      pollForElement(getElement);
    }
    setScrollPosition(null);
  };

  const scrollToElementById = (elementId: string): void => {
    handleScrollToElement(() => document.getElementById(elementId));
  };

  const scrollToElementByDataCy = (elementId: string): void => {
    handleScrollToElement(() => document.querySelector(`[data-cy^=${elementId}]`) as HTMLElement);
  };

  const scrollToElementByRef = (ref: React.RefObject<HTMLElement>): void => {
    handleScrollToElement(() => ref.current);
  };

  return {
    scrollPosition,
    setScrollPosition,
    scrollToElementById,
    scrollToElementByDataCy,
    scrollToElementByRef,
  };
};

export default useRestorePageScrollPosition;
