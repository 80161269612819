type OverrideFormattersShape = Record<
  string,
  {
    language: string;
    cleanupFn?: (s: string) => string;
  }
>;
const overrideFormatters: OverrideFormattersShape = {
  EUR: { language: 'es-ES' },
  USD: { language: 'en-EN' },
  GBP: { language: 'en-EN' },
  HKD: { language: 'en-EN' },
  JPY: { language: 'en-EN' },
  KRW: { language: 'en-EN' },
  DKK: { language: 'de-DE' },
  SEK: { language: 'de-DE' },
  PLN: { language: 'de-DE', cleanupFn: (s) => s.replace('PLN', 'zł') },
  CHF: {
    language: 'en-EN',
    cleanupFn: (s) => s.split(' ').reverse().join(' '),
  },
  SGD: {
    language: 'en-SG',
    cleanupFn: (s) => s.replace('$', 'S$'),
  },
  AUD: {
    language: 'en-EN',
    cleanupFn: (s) => s.replace('A$', 'AUD'),
  },
  CAD: {
    language: 'en-EN',
    cleanupFn: (s) => s.replace('CA$', 'CDN$'),
  },
};

const formatPrice = (price: number, currency: string, language: string | string[]): string => {
  const targetLanguage = overrideFormatters[currency]?.language || language;
  const formatter = new Intl.NumberFormat(targetLanguage, {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const formattedPrice = formatter
    .format(price)
    .replace(/\D00(?=\D*$)/, '')
    .replace(/[\u00A0\u202F]/g, ' ');
  return overrideFormatters[currency]?.cleanupFn?.(formattedPrice) || formattedPrice;
};

const getNumber = (price: string | number): number => {
  return typeof price === 'number' ? price : parseInt(price, 10);
};

const convertToCents = (price: string | number): number => {
  return getNumber(price) * 100;
};

const convertFromCents = (priceInCents: string | number): number => {
  return getNumber(priceInCents) / 100;
};

export { formatPrice, convertToCents, convertFromCents };
