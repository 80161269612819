import { useCallback } from 'react';
import useWindowSize from '@hooks/use-window-size';
import {
  CMSFieldTextStyle as TextStyle,
  CMSSimpleTextFieldType as TextFieldType,
} from '@interfaces/models/cms/campaign-hero';

// TODO: Campaign hero title has a larger size for larger screens (A64) as an exception.
const CHB_LARGER_DESKTOP_TITLE_FONT_SIZE = 'A64';

/*
 * We receive CSS styles from the API for our configurable block,
 * font styles like 'A40_M' based on different breakpoints and text styles (color, alignment, etc...).
 * However, our Design System doesn't have exact matches for these styles,
 * so we create custom classes to fit our Design System.
 */

const useGenerateFontClassesForField = (fieldType: string, textStyle: TextStyle) => {
  const windowSize = useWindowSize();

  const generateFontClassesForField = useCallback(() => {
    // TODO: Block-level checks for future use of original desktop sizes from API, except for campaign hero block title (constant exception).
    const customFontSizeForDesktop =
      fieldType === TextFieldType.Title ? CHB_LARGER_DESKTOP_TITLE_FONT_SIZE : textStyle?.fontSizeForDesktop;

    const fontSize = (() => {
      const { isWiderThanXl, isWiderThanLg, isWiderThanMd } = windowSize;

      if (isWiderThanXl || isWiderThanLg) {
        return customFontSizeForDesktop;
      } else if (isWiderThanMd) {
        return textStyle?.fontSizeForTablet;
      } else {
        return textStyle?.fontSizeForMobile;
      }
    })();

    const originalFontSize = fontSize?.toUpperCase();

    /* 
      Process the font size string from the API payload
      and generate font styles compatible with our design system.
      A - Albra (used for headings), H - Helvetica (used for normal texts)
    */
    const fontTypePrefix = originalFontSize?.startsWith('H')
      ? 'vc-text-'
      : originalFontSize?.startsWith('A')
      ? 'vc-heading-'
      : '';

    // Remove [Regular, Medium, Bold] from font variable
    const fontClasses = `${fontTypePrefix}${originalFontSize?.replace(/_[RMB]$/, '')}`;

    /* 
      Add an additional font style based on whether the font size string ends with '_M' (medium)
      or '_B' (bold), resulting in 'vc-text-medium' or 'vc-text-bold'.
    */
    const additionalClasses = originalFontSize?.endsWith('_M')
      ? 'vc-text-medium'
      : originalFontSize?.endsWith('_B')
      ? 'vc-text-bold'
      : '';

    const convertedFieldType =
      fieldType === TextFieldType.Tag || fieldType === TextFieldType.Subtitle || fieldType === TextFieldType.Title
        ? `${fieldType}Field`
        : `${fieldType}`;
    return `${fontClasses} ${additionalClasses} ${convertedFieldType}`;
  }, [windowSize, fieldType, textStyle]);

  return generateFontClassesForField;
};

export default useGenerateFontClassesForField;
