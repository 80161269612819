import { ElasticSearchFacetsFields, ElasticSearchFilterKeys } from '@interfaces/models/elasticSearch';
import { AvailableCountryISOCodes } from './country';

export type CatalogFilterConfigurationType =
  | 'color-swatch'
  | 'text-swatch'
  | 'price-range'
  | 'item'
  | 'top-level-group'
  | 'switch';

// type for sortBy property where we need to sort size data based on it's format - which changes based on isoCode
export type SortTypes = {
  [key in AvailableCountryISOCodes | 'default']: SortByMethod;
};

export type SortByMethod = 'sortById' | 'sortByNumericValue' | 'sortClothingSizes' | 'desc' | 'asc' | 'sortByCount';

export enum FilterTopLevelGroup {
  SIZE = 'size-top-level-group',
}

// TODO: Check with VC which of these are still actual
export interface CatalogFilterConfiguration {
  type: CatalogFilterConfigurationType;

  /**
   * Filter label.
   */
  label: string;

  child?: CatalogFilterConfiguration;

  /**
   * Name of the attributes for faceting.
   */
  facet?: ElasticSearchFacetsFields | FilterTopLevelGroup;

  /**
   * Whether filter component should display an input to search for facet values.
   */
  isSearchable?: boolean;

  /**
   * The label of the search input placeholder.
   */
  searchPlaceholder?: string;

  /**
   * How much facet values to get.
   */
  limit?: number;

  currency?: string;
  noCheck?: boolean;

  /**
   * Set limitation for number of filter items before show more button
   */
  showMoreLimit?: number;

  /**
   * Hide the facet when matching a certain condition
   */
  forceHide?: boolean;

  /**
   * Set type order that filter items are shown
   * ex: ['name:asc']
   */
  sortBy?: Partial<SortTypes> | SortByMethod;

  hasSustainable?: boolean;

  /**
   * Universe ID is typeId of category.
   */
  universeId?: string;

  /**
   * Label of the item when filter has one option item.
   */
  itemText?: string[];

  /**
   * Filter name to send on search API
   */
  filter?: ElasticSearchFilterKeys;

  /**
   * Group to bunch filters together in
   */
  group?: FilterTopLevelGroup;
  id?: string;

  // only lazy load a certain number of items before scroll
  lazyLoadCount?: number;
}
