import { Preferences } from '@interfaces/models/preferences';
import { AvailableCountryISOCodes } from '@interfaces/models/country';

export type ElasticSearch = {
  // Query string - Used in search service
  q?: string;
  sortBy?: 'relevance' | 'price-asc' | 'price-desc' | 'recency';
  pagination?: ElasticSearchPagination;
  // Fields represent requested model properties
  fields?: Partial<ElasticSearchProductCharacteristics[]>;
  filters?: Partial<
    ElasticSearchPriceFilters &
      ElasticSearchBooleanFilters &
      ElasticSearchStringListFilters &
      ElasticSearchCampaignIdFilters
  >;
  facets?: ElasticSearchFacets;
  locale: ElasticSearchLocale;
  mySizes?: ElasticSearchMySizes;
  options?: {
    innerFeedContext?: InnerFeedContext;
    lastSearchDate?: number;
  };
};

export enum InnerFeedContext {
  RecentSearches = 'recentSearches',
  GenericPLP = 'genericPLP',
  DealHunting = 'dealHunting',
}

export type ElasticSearchBooleanFilters = Record<ElasticSearchBooleanFilterProperties, boolean>;
export type ElasticSearchStringListFilters = Record<ElasticSearchStringListFilterProperties, string[]>;

// TODO: This type doesn't work, it needs to be replaced with the following:
// export type ElasticSearchFilterKeys =
//   | ElasticSearchBooleanFilterProperties
//   | ElasticSearchStringListFilterProperties
//   | ElasticSearchPriceFilters
//   | ElasticSearchCampaignIdFilters;
export type ElasticSearchFilterKeys = keyof ElasticSearch['filters'];

export type ElasticSearchPagination = {
  // Page number
  offset: number;
  // Amount of products you're requesting
  limit: number;
};

// List of product characteristics you can request from the API
export type ElasticSearchProductCharacteristics =
  | 'id'
  | 'name'
  | 'description'
  | 'brand'
  | 'model'
  | 'country'
  | 'stock'
  | 'price'
  | 'discount'
  | 'link'
  | 'sold'
  | 'likes'
  | 'editorPicks'
  | 'shouldBeGone'
  | 'seller'
  | 'directShipping'
  | 'local'
  | 'pictures'
  | 'colors'
  | 'size'
  | 'dutyFree';

// Available size ids
export type ElasticSearchSizeIds =
  | 0
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | 13
  | 14
  | 15
  | 16
  | 17
  | 18
  | 19
  | 20
  | 21
  | 22
  | 23;

export type ElasticSearchSizes = `size${ElasticSearchSizeIds}`;

// List of possible facets (filters) user can select from
export type ElasticSearchFacetsFields =
  | 'universe'
  | 'model'
  | 'brand'
  | 'country'
  | 'color'
  | 'price'
  | 'priceRange'
  | 'condition'
  | 'region'
  | 'sold'
  | 'stock'
  | 'discount'
  | 'watchMechanism'
  | 'directShippingEligible'
  | 'directShippingCountries'
  | 'localCountries'
  | 'dealEligible'
  | 'categoryLvl0'
  | 'categoryLvl1'
  | 'categoryLvl2'
  | 'sellerBadge'
  | 'isOfficialStore'
  | 'materialLvl0'
  | 'materialLvl1'
  | 'editorPicks'
  | 'dutyFree'
  | ElasticSearchSizes;

// List of filter properties that accept boolean as value
export type ElasticSearchBooleanFilterProperties =
  | 'stock'
  | 'dealEligible'
  | 'isOfficialStore'
  | 'localCountries'
  | 'directShippingEligible'
  | 'directShippingCountries'
  | 'dutyFree';

export type ElasticSearchStringFilterProperties = 'directShippingCountries';

// List of filter properties that accept string arrays as values
export type ElasticSearchStringListFilterProperties =
  | 'brand.id'
  | 'model.id'
  | 'universe.id'
  | 'color.id'
  | 'condition.id'
  | 'region.id'
  | 'watchMechanism.id'
  | 'categoryLvl0.id'
  | 'categoryLvl1.id'
  | 'categoryLvl2.id'
  | 'materialLvl0.id'
  | 'materialLvl1.id'
  | 'pattern.id'
  | 'editorPicks'
  | 'sold'
  | 'country'
  | 'discount'
  | 'priceRange'
  | 'seller.id'
  | 'sellerBadge'
  | 'catalogLinksWithoutLanguage'
  | ElasticSearchSizes;

const PriceFilterKeys = ['>=', '>', '<=', '<'] as const;
type PriceFilterKeysType = (typeof PriceFilterKeys)[number];
export function isPriceFilterKey(value: string): value is PriceFilterKeysType {
  return PriceFilterKeys.includes(value as PriceFilterKeysType);
}

// Special filter that must follow this custom notation
export type ElasticSearchPriceFilters = {
  price: Partial<Record<PriceFilterKeysType, number>>;
};

export type ElasticSearchCampaignIdFilters = {
  'campaign.id': string[];
};

// In this property you declare which product characteristics you want to receive
export type ElasticSearchFacets = {
  fields?: ElasticSearchFacetsFields[];
  stats?: ElasticSearchFacetStats[];
};

export type ElasticSearchFacetStats = 'price';

export type ElasticSearchLocale = Pick<Preferences, 'currency' | 'language'> & {
  country: AvailableCountryISOCodes;
  sizeType?: string;
};

export type ElasticSearchMySizes = {
  isEnabled: boolean;
  'universe.id': number[];
  sizes: {
    [key: string]: string[];
  };
};

// Filters encoded with '1'
export type CatalogPageURLBooleanFilters =
  | 'localCountries'
  | 'dsEligible'
  | 'stock'
  | 'dealsEligible'
  | 'seller.isOfficialStore'
  | 'dutyFree';

// Filters that can have multiple numeric values
export type CatalogPageURLNumericFilters =
  | 'gender'
  | 'category'
  | 'categoryParent'
  | 'subcategory'
  | 'material'
  | 'materialParent'
  | 'color'
  | 'brand'
  | 'condition'
  | 'region'
  | 'model'
  | 'pattern'
  | 'mechanism';

// Filters that can have multiple string values (e.g. 20%,...)
export type CatalogPageURLStringFilters =
  | 'sold'
  | 'dsEligibleCountries'
  | 'sellerBadge'
  | 'price'
  | 'country'
  | 'discount'
  | 'priceMin'
  | 'priceMax'
  | 'editorPicks'
  | ElasticSearchSizes;

// List of properties that can appear in the url
export type CatalogPageURLFilters =
  | CatalogPageURLBooleanFilters
  | CatalogPageURLNumericFilters
  | CatalogPageURLStringFilters;
