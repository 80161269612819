import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { invertMap } from '@helpers/utils/general';

export const catalogURLSortByParamToFilter = new Map<'0' | '1' | '2' | '3', ElasticSearch['sortBy']>([
  ['0', 'relevance'],
  ['1', 'price-asc'],
  ['2', 'price-desc'],
  ['3', 'recency'],
]);

export const catalogURLSortByFilterToParam = invertMap<ElasticSearch['sortBy'], '0' | '1' | '2' | '3'>(
  catalogURLSortByParamToFilter,
);

export const catalogURLParamToSortByRename = (sortBy: '0' | '1' | '2' | '3') => {
  return catalogURLSortByParamToFilter.has(sortBy) ? catalogURLSortByParamToFilter.get(sortBy) : sortBy;
};

export const sortByToCatalogURLParamRename = (sortBy: ElasticSearch['sortBy']) => {
  return catalogURLSortByFilterToParam.has(sortBy) ? catalogURLSortByFilterToParam.get(sortBy) : sortBy;
};
