import clsx from 'clsx';
import { CMSField as FieldProps, CMSFieldType as FieldType } from '@interfaces/models/cms/campaign-hero';
import useGenerateFontClassesForField from '@hooks/cms/use-cms-field-font-styles';
import { generateCMSFieldStyles } from '@helpers/utils/cms/cms-field-styles';

import styles from './cms-block-fields.module.scss';

export const useFieldStylesAndClasses = (fieldType: FieldType | string, data: FieldProps['data'], blockStyle = '') => {
  const { fieldStyle } = data;
  const generateFontClassesForField = useGenerateFontClassesForField(fieldType, fieldStyle);
  const fontClass = generateFontClassesForField();
  const fieldStyles = generateCMSFieldStyles(fieldType, fieldStyle, data);
  const fieldClass = clsx(
    styles.field,
    styles[`field--${fieldType}`],
    styles[`field--${fieldType}--${blockStyle}`],
    fontClass,
  );
  return { fieldStyles, fieldClass };
};
