import { useRouter } from 'next/router';
import { ProfileQueryKeys } from '@enums/react-query-keys';
import { UserProfile } from '@interfaces/models/userProfile';
import { useQuery } from '@tanstack/react-query';
import useUser from '@hooks/user/use-user';

const useProfileQuery = () => {
  const router = useRouter();
  const { profileSlug } = (router?.query as { profileSlug: string }) || {};
  const { user } = useUser();

  const { data: profileUser } = useQuery<UserProfile | null>({
    queryKey: [ProfileQueryKeys.PROFILE_USER],
    staleTime: Infinity,
  });
  const isUserProfile = profileSlug?.[0] === user?.id;
  const profile = isUserProfile ? user : profileUser;

  return {
    profile,
    isUserProfile,
  };
};

export default useProfileQuery;
