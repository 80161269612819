import React from 'react';
import CMSImages from '@components/atoms/vc-images/components/cms-image';
import { CampaignBlock } from '@interfaces/models/campaign';
import styles from '../campaign-block.module.scss';

const CampaignImg = ({ className, image }: { className?: string; image: CampaignBlock['image'] }) => {
  const { filename: imgXName, url: imgPath, title: imgTitle } = image || {};

  if (!imgXName || !imgPath) {
    return null;
  }

  return (
    <CMSImages
      className={styles.campaign__img}
      sizes="
          (min-width: 1200px) 1246px,
          (min-width: 768px) 768px,
          (min-width: 420px) 420px,
          (min-width: 100px) 375px
          "
      wrapperProps={{ className: `${styles.campaign__img__container} ${className ?? ''}` }}
      src={imgPath}
      srcSetOpts={[
        { width: '375', height: '328', descriptor: '320w' },
        { width: '420', height: '367', descriptor: '420w' },
        { width: '768', height: '768', descriptor: '780w' },
        { width: '1246', height: '910', descriptor: '1246w' },
      ]}
      alt={imgTitle}
      data-src={imgXName}
      width={375}
      height={328}
      priority
      disableLazyLoad
    />
  );
};

export default CampaignImg;
