import { useMemo, useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';
import { LocalizationQueryKeys } from '@enums/react-query-keys';
import { I18n as Localization, I18nLanguage } from '@interfaces/models/i18n';
import { Currency } from '@interfaces/models/currency';
import { Country } from '@interfaces/models/country';
import { getI18n } from '@api/static';
import { usePreferences } from '@context/preferences.context';
import { useBucket } from '@context/bucket.context';
import { MIGRATION_SITE_INFO } from '@config/localization';

const useLocalizationQuery = () => {
  const { siteId, locale } = usePreferences();
  const { isFeatureEnabled } = useBucket();

  const { data: localization } = useQuery<Localization>({
    queryKey: [LocalizationQueryKeys.LOCALIZATION],
    staleTime: Infinity,
    queryFn: () => getI18n({ siteId, locale }),
  });

  const updateSiteIdIfNecessary = useCallback(
    (countryList) => {
      return countryList.map((item) => {
        const regionCode = item.isoCode;
        const countryInfo = { ...item };

        if (MIGRATION_SITE_INFO[regionCode]) {
          const ffName = MIGRATION_SITE_INFO[regionCode].ffName;
          const ffValue = isFeatureEnabled((f) => !!f[ffName], false) || false;

          if (!ffValue) {
            countryInfo.idRegion = MIGRATION_SITE_INFO[regionCode].oldSiteId;
          }
        }

        return countryInfo;
      });
    },
    [isFeatureEnabled],
  );

  const locales: I18nLanguage[] = localization?.language?.list ?? [];
  const currencies: Currency[] = localization?.currency?.list ?? [];
  const fullList: Country[] = localization?.country?.fullList || [];
  const shortLists: Country[] = localization?.country?.shortLists || [];

  const countryList = useMemo(() => updateSiteIdIfNecessary(fullList), [fullList, updateSiteIdIfNecessary]);
  const countryShortList: Country[] = useMemo(
    () => updateSiteIdIfNecessary(shortLists),
    [shortLists, updateSiteIdIfNecessary],
  );

  return {
    // Localization should probably be removed, currently it's included to make sure i18n.tsx works,
    // that needs to be redone as well
    localization: {
      ...localization,
      country: {
        ...localization?.country,
        fullList: countryList,
        shortLists: countryShortList,
      },
    },
    locales,
    currencies,
    countryList,
    countryShortList,
  };
};

export default useLocalizationQuery;
