export enum CMSFieldTextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum CMSFieldTextType {
  Plain = 'plain',
  Html = 'html',
  Markdown = 'markdown',
}

export enum CMSSimpleTextFieldType {
  Tag = 'tag',
  Title = 'title',
  Subtitle = 'subtitle',
}

export const CMSSimpleTextFieldTypeMapping = {
  [CMSSimpleTextFieldType.Tag]: 'tagField',
  [CMSSimpleTextFieldType.Title]: 'titleField',
  [CMSSimpleTextFieldType.Subtitle]: 'subtitleField',
};

export enum CMSBlockBackgroundType {
  Color = 'color',
  Gradient = 'gradient',
  Image = 'image',
}

export enum CMSFieldType {
  Tag = 'tagField',
  Title = 'titleField',
  Subtitle = 'subtitleField',
  SimpleTextField = 'simpleTextField',
  CTA = 'ctaField',
  Chips = 'chipsField',
  Icon = 'iconField',
  Image = 'imageField',
}
export interface CMSFieldTextStyle {
  alignment?: CMSFieldTextAlign;
  textColor?: string;
  fontSizeForMobile?: string;
  fontSizeForDesktop?: string;
  fontSizeForTablet?: string;
  textType?: CMSFieldTextType;
  uppercaseText?: boolean;
}
export interface CMSContextualAction {
  style: 'primary' | 'secondary' | 'ghost';
  actionType: 'copy' | 'redirect';
  label: string;
  value: string;
}

export interface CMSContextualRedirection {
  title: string;
  text: CMSFieldTextType;
  trackingLabel: string;
  actions: CMSContextualAction[];
}

export interface CMSField {
  fieldType: CMSFieldType | string;
  columnAlignment?: 'left' | 'middle' | 'right';
  data: {
    text?: string;
    fieldStyle?: CMSFieldTextStyle;
    simpleTextFieldType?: CMSSimpleTextFieldType;
    chips?: CMSChipBlock[];
    url?: string;
    deeplink?: string;
    ctaType?: string;
    backgroundColor?: string;
    iconName?: 'infobox' | 'three-dots';
    color?: string;
    contextualRedirection?: CMSContextualRedirection;
  };
}
export interface CMSChipBlock {
  contentTypeUid?: string;
  locale?: string;
  uid: string;
  tags?: string[];
  title?: string;
  deeplink?: string;
  text?: string;
  displayTitle: string;
  url: string;
  category?: {
    category: string;
  };
  textColor: string;
}
export interface CMSBlockBackgroundStyle {
  backgroundType: CMSBlockBackgroundType;
  color?: string;
  gradient?: {
    type: string;
    startColor: string;
    endColor: string;
    angle: number;
  };
  image?: {
    uid: string;
    contentType: string;
    fileSize: string;
    tags: string[];
    filename: string;
    url: string;
    isDir: boolean;
    parentUid: string;
    title: string;
  };
}

export interface CampaignHeroBlock {
  uid: string;
  title: string;
  contentTypeUid: string;
  sectionIdx: string;
  url: string;
  backgroundStyle?: CMSBlockBackgroundStyle;
  blockStyle?: string;
  fields: CMSField[];
  locale?: string;
  tags?: string[];
}
