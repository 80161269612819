import React from 'react';
import { useTranslation } from 'next-i18next';
import { useContextSelector } from 'use-context-selector';
import { SearchContext } from '@context/search.context';

const ClearInputButton: React.FC<{ className: string }> = ({ className }) => {
  const { t } = useTranslation('default-layout');
  const setSearchQuery = useContextSelector(SearchContext, (v) => v.setSearchQuery);

  return (
    <button
      type="button"
      onClick={() => setSearchQuery('')}
      className={className}
      data-cy="clear_button"
    >
      {t('HEADER.SEARCH.CLEAR_BTN')}
    </button>
  );
};

export default ClearInputButton;
