import { Product } from '@interfaces/models/product';
import { Picture } from '@interfaces/models/picture';
import Environment from '@config/index';
import imageQuality from '@config/image';

type BuildImgUrlPayload = {
  path: Picture['path'];
  options: IImagesOptions | ICmsImageOptions;
  imageType: ImageType;
};

// ImageType defines critical properties to decide image url
export type ImageType = 'CMS' | 'brandNav' | 'product' | 'default';

export const CF_RESIZING_PREFIX = '/cdn-cgi/image';
export const VC_RESIZING_PREFIX = '/images/resized';

export class ImagesService {
  public static buildImgUrl({ path, options, imageType }: BuildImgUrlPayload) {
    if (imageType === 'CMS') {
      return this.buildCmsUrl(path, options as ICmsImageOptions);
    }

    if (imageType === 'brandNav') {
      const url = `${Environment.vccmsimguri}${path}`;
      return this.buildCmsUrl(url, options as ICmsImageOptions);
    }

    return this.buildUrl(path, options as IImagesOptions, imageType === 'product');
  }

  public static buildUrl(path: Picture['path'], options: IImagesOptions = {}, isProductImage: boolean = false): string {
    if (!path) {
      return '';
    }
    let url: Picture['path'] = Environment.imagesBaseUrl;
    const optionsParams: string = Object.getOwnPropertyNames(options).reduce(
      (prev, curr) => `${prev}${curr}=${options[curr]},`,
      '',
    );

    let pathImg: Picture['path'];
    if (isProductImage) {
      pathImg = path.includes('/produit/') ? path : `/produit/${path}`;
    }

    const fullPath = pathImg || path;
    url += this.getResizingPrefix(fullPath) + `/`;
    url += `${optionsParams}q=${options?.q || imageQuality},f=auto,`;
    url += fullPath;

    return url;
  }

  private static parseProductIdFromPath(path: Product['path']) {
    const idMatch = path.match(/(\d+)(?:-\d+)?(?:_\d+)?\.jpg$/);
    if (idMatch === null) {
      // cannot find id, use old way
      return 0;
    }
    return +idMatch[1];
  }

  public static getResizingPrefix(imagePath: Picture['path']) {
    if (!Environment.imagesResizedPrefixEnabled) {
      return CF_RESIZING_PREFIX;
    }

    if (!imagePath.startsWith('/produit/')) {
      return VC_RESIZING_PREFIX;
    }

    //here we know that /images/resized prefix enabled and path starts with /produit/
    //apply /images/resized prefix if productId from Path is more than configured value
    if (this.parseProductIdFromPath(imagePath) >= Environment.imagesResizedPrefixStartingProduitId) {
      return VC_RESIZING_PREFIX;
    }

    return CF_RESIZING_PREFIX;
  }

  // contentstack image doc:
  // https://www.contentstack.com/docs/developers/apis/image-delivery-api/
  public static buildCmsUrl(url: Picture['path'], options: ICmsImageOptions = {}): string {
    if (!url) {
      return '';
    }

    const defaultOptions = {
      quality: '70',
      fit: 'bounds',
      format: 'webp',
      dpr: 2,
      disable: 'upscale', // This ensures that even if the specified height or width is much bigger than the actual image, it will not be rendered disproportionately.
    };
    const optionKeysToRename = {
      w: 'width',
      h: 'height',
      q: 'quality',
    };
    const validOptions = Object.keys(options).reduce((acc, item) => {
      if (options[item]) {
        const keyName = optionKeysToRename[item] || item;
        acc[keyName] = options[item];
      }

      return acc;
    }, {});

    const imageOptions = Object.assign({}, defaultOptions, validOptions);
    const optionsParams = Object.keys(imageOptions)
      .map((option) => `${option}=${imageOptions[option]}`)
      .join('&');

    const imageUrl = url;
    return `${imageUrl}?${optionsParams}`;
  }

  public static improveSEOProductPathImg(imgPath: Picture['path'], productPath: Product['path']) {
    if (!imgPath) {
      return '/notFound/placeholder.jpg';
    }
    // We had the name of the band and the name of the product to the path of the image
    // The NetApp has a rule to redirect /produit/xxx-yyy-zzz-0123456-1.jpg to /produit/0123456-1.jpg
    let finalSEOParam: string = '';
    if (productPath) {
      const pathProduct: string[] = productPath.split('/');
      const SEOAdding: string = pathProduct.pop();
      finalSEOParam = SEOAdding.replace(/[0-9]*\.shtml/, '');
    }

    const pathImg: Picture['path'][] = imgPath.split('/produit/');
    return `/produit/${finalSEOParam}${pathImg[1] ? pathImg[1] : pathImg}`;
  }
}

export const ImageRatios = ['2:3', '4:3', '16:9', '9:16', '21:9'];
export type ImageRatioType = (typeof ImageRatios)[number];

export interface IImagesOptions {
  w?: number | string;
  h?: number | string;
  q?: number | string;
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
}

export interface ICmsImageOptions {
  w?: number | string;
  h?: number | string;
  q?: number | string;
  fit?: 'bounds' | 'crop';
  crop?: ImageRatioType;
}

export type ImagesOptions = IImagesOptions | ICmsImageOptions;
