import { invertMap } from '@helpers/utils/general';
import { CatalogPageURLFilters, ElasticSearchFilterKeys } from '@interfaces/models/elasticSearch';

export const catalogURLParamsToFilterMap = new Map<CatalogPageURLFilters, Partial<ElasticSearchFilterKeys>>([
  ['gender', 'universe.id'],
  ['subcategory', 'categoryLvl2.id'],
  ['category', 'categoryLvl1.id'],
  ['categoryParent', 'categoryLvl0.id'],
  ['material', 'materialLvl1.id'],
  ['materialParent', 'materialLvl0.id'],
  ['color', 'color.id'],
  ['brand', 'brand.id'],
  ['condition', 'condition.id'],
  ['region', 'region.id'],
  ['model', 'model.id'],
  ['dsEligible', 'directShippingEligible'],
  ['localCountries', 'directShippingCountries'],
  ['seller.isOfficialStore', 'isOfficialStore'],
  ['mechanism', 'watchMechanism.id'],
  ['dealsEligible', 'dealEligible'],
]);

export const filtersToCatalogURLParamsMap = invertMap<Partial<ElasticSearchFilterKeys>, CatalogPageURLFilters>(
  catalogURLParamsToFilterMap,
);

export const filtersToCatalogURLParamRename = (filterName: ElasticSearchFilterKeys): CatalogPageURLFilters => {
  return (filtersToCatalogURLParamsMap.get(filterName) ?? filterName) as CatalogPageURLFilters;
};

export const catalogURLParamToFiltersRename = (urlParameter: CatalogPageURLFilters): ElasticSearchFilterKeys => {
  return (catalogURLParamsToFilterMap.get(urlParameter) ?? urlParameter) as ElasticSearchFilterKeys;
};
