import React from 'react';
import dynamic from 'next/dynamic';
import { WelcomeVoucherProps } from '@components/catalog/welcome-voucher/welcome-voucher';
import useSmartBanner from '@hooks/use-smart-banner';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';

const SmartBanner = dynamic(() => import('@components/molecules/smart-banner/smart-banner'));

const SmartBannerWrapper: React.FC<WelcomeVoucherProps> = () => {
  const { shouldShowSmartBanner, closeBanner, osList } = useSmartBanner();

  if (!shouldShowSmartBanner) {
    return null;
  }

  return (
    <ConditionalWrapper
      condition={shouldShowSmartBanner}
      wrap={(children) => (
        <div
          style={{ height: '5rem' }}
          className="hasSmartBanner"
        >
          {children}
        </div>
      )}
    >
      <SmartBanner
        onCloseBannerClick={closeBanner}
        showBanner={shouldShowSmartBanner}
        osList={osList}
      />
    </ConditionalWrapper>
  );
};

export default SmartBannerWrapper;
