import useLocalStorage from '@hooks/use-local-storage';
import { ElasticSearch } from '@interfaces/models/elasticSearch';

export interface RecentSearch {
  id: string;
  url: string;
  label: string;
  title: string;
  createdAt: number;
  payload: {
    filters: ElasticSearch['filters'];
    mySizesEnabled: boolean;
    q: ElasticSearch['q'];
  } | null;
  newItemsCount?: number;
  savedSearchId?: string;
}

export const getRecentSearchRedirectUrl = (recentSearch: RecentSearch, isSmartRecencyEnabled = false) => {
  const { url: urlString } = recentSearch;
  const url = new URL(window.location.origin + urlString);
  if (isSmartRecencyEnabled) {
    url.searchParams.set('smartRecency', '1'); // enable smart recency
  } else {
    url.searchParams.set('sortBy', '3'); // set sortBy to recency
  }

  url.searchParams.set('searchDate', String(recentSearch.createdAt));
  return url.toString();
};

const useRecentSearches = () => {
  const [recentSearches, setRecentSearches] = useLocalStorage<RecentSearch[]>('localsave/recentSearches', [], false);

  const addOrEditRecentSearchEntry = (newSearch: RecentSearch) => {
    setRecentSearches((prevState: RecentSearch[]) => {
      return [
        newSearch,
        ...prevState.filter((recentSearch: RecentSearch) => recentSearch.id !== newSearch.id && !!recentSearch.title),
      ].filter((_, index) => index < 15);
    });
  };

  const saveRecentSearch = ({ recentSearchId, savedSearchId }: { recentSearchId: string; savedSearchId: string }) => {
    setRecentSearches((prevState: RecentSearch[]) => {
      return prevState.map((recentSearch: RecentSearch) => {
        if (recentSearch.id === recentSearchId) {
          return {
            ...recentSearch,
            savedSearchId,
          };
        }
        return recentSearch;
      });
    });
  };

  const unsaveRecentSearch = (recentSearchId: string) => {
    setRecentSearches((prevState: RecentSearch[]) => {
      return prevState.map((recentSearch: RecentSearch) => {
        if (recentSearch.id === recentSearchId) {
          const { savedSearchId, ...rest } = recentSearch;
          return rest;
        }
        return recentSearch;
      });
    });
  };

  const removeRecentSearchEntry = (id: string) =>
    setRecentSearches((prevState: RecentSearch[]) => {
      return prevState.filter((recentSearch: RecentSearch) => recentSearch.id !== id);
    });

  return {
    recentSearches,
    setRecentSearches,
    addOrEditRecentSearchEntry,
    removeRecentSearchEntry,
    saveRecentSearch,
    unsaveRecentSearch,
  };
};

export default useRecentSearches;
