import React, { AnchorHTMLAttributes, FCWithChildren } from 'react';
import ConditionalWrapper from '@components/common/conditional-wrapper/conditional-wrapper';
import NextLink from 'next/link';
import Environment from '@config/environment';

const isClientSideNavigationEnabledGlobally = Environment.feature.isClientSideNavigationEnabledGlobally;

type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href: string;
  // Force a specific link to do client side navigation, even if it's disabled globally
  forceClientSideNavigation?: boolean;
  disableClientSideNavigation?: boolean;
  onClick?: (e: React.MouseEvent | React.KeyboardEvent) => void;
};

// Note: Only pages, which have been migrated to fenx should be linked to with <Link>, otherwise enabling global client
// side navigation will result in 404!
const Link: FCWithChildren<LinkProps> = (props) => {
  const {
    href,
    onClick,
    forceClientSideNavigation = false,
    disableClientSideNavigation = false,
    className,
    children,
    ...rest
  } = props;

  return (
    <ConditionalWrapper
      condition={(isClientSideNavigationEnabledGlobally && !disableClientSideNavigation) || forceClientSideNavigation}
      wrap={(children) => (
        <NextLink
          href={href}
          passHref
        >
          {children}
        </NextLink>
      )}
    >
      <a
        href={href}
        className={className}
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onClick(e);
          }
        }}
        rel="noopener"
        tabIndex={0}
        {...rest}
      >
        {children}
      </a>
    </ConditionalWrapper>
  );
};

export default Link;
