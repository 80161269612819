import { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card';

export const SLIDER_BREAKPOINTS = {
  mobile: 576,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1280,
};

export const SLIDER_CONFIG: {
  [key in ProductCardProps['cardType']]: {
    slidesPerView: number;
    mobileSlidesPerView: number;
    tabletSlidesPerView: number;
    desktopSlidesPerView: number;
    largeDesktopSlidesPerView: number;
    mobileSlidesPerGroup?: number;
    tabletSlidesPerGroup?: number;
    desktopSlidesPerGroup?: number;
    largeDesktopSlidesPerGroup?: number;
    desktopSpeed?: number;
    largeDesktopSpeed?: number;
  };
} = {
  // default
  standard: {
    slidesPerView: 2.3,
    mobileSlidesPerView: 2.42,
    tabletSlidesPerView: 3,
    desktopSlidesPerView: 4,
    largeDesktopSlidesPerView: 5,
  },
  small: {
    slidesPerView: 1.5,
    mobileSlidesPerView: 1.5,
    tabletSlidesPerView: 2.3,
    desktopSlidesPerView: 3.1,
    largeDesktopSlidesPerView: 5,
    mobileSlidesPerGroup: 1,
    tabletSlidesPerGroup: 2,
    desktopSlidesPerGroup: 3,
    largeDesktopSlidesPerGroup: 5,
    desktopSpeed: 750,
    largeDesktopSpeed: 900,
  },
};
