import defaultAxios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { AxiosCacheInstance, AxiosStorage, buildStorage, setupCache } from 'axios-cache-interceptor';

// Cache TTL in minutes
const CACHE_TTL = 60;

const CACHE = new Map();

const STORAGE: AxiosStorage = buildStorage({
  find: (key: string) => {
    return CACHE.get(key);
  },
  set: (key: string, value) => {
    CACHE.set(key, value);
  },
  remove: (key: string) => {
    CACHE.delete(key);
  },
});

const axios = defaultAxios.create({
  timeout: 5000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(typeof window === 'undefined' && {
      'Accept-Encoding': 'gzip',
    }),
  },
});

const onRequest = (config: InternalAxiosRequestConfig) => {
  return config;
};

const onResponse = (response: AxiosResponse) => {
  return response;
};

axios.interceptors.request.use(onRequest);
axios.interceptors.response.use(onResponse);

const cachedAxios: AxiosCacheInstance = setupCache(axios, {
  ttl: 60 * 1000 * CACHE_TTL,
  storage: STORAGE,
  cachePredicate: {
    statusCheck: (status) => status === 200,
  },
});

export default cachedAxios;
