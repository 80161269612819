import React from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@components/atoms/button/button';
import Dialog from '@components/atoms/dialog/dialog';
import styles from './confirm-dialog.module.scss';

interface ConfirmDialogProps {
  setOpen: (isOpen: boolean) => void;
  open: boolean;
  title: string;
  content: string;
  onCancel: () => void;
  onConfirm: () => void;
  cancelBtnTxt?: string;
  confirmBtnTxt?: string;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  setOpen,
  open,
  title,
  content,
  onCancel,
  onConfirm,
  cancelBtnTxt,
  confirmBtnTxt,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      customClass={styles.dialog}
      focusTrapActive={false}
      isOpen={open}
      hasCloseIcon
      hasCloseTxt
      closeDialog={setOpen}
      fullScreenOnMobile={false}
      styles={{
        content: { padding: '2rem 1.5rem 1.5rem', maxWidth: '25.5rem' },
      }}
    >
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.content}>{content}</div>
        <div className={styles.buttonwrap}>
          <Button
            className={styles.button}
            variant="secondary"
            size="large"
            onClick={onCancel}
          >
            {cancelBtnTxt || t('PLP.MYSIZES_DIALOG.CONFIRM_CANCEL')}
          </Button>

          <Button
            className={styles.button}
            onClick={onConfirm}
            variant="primary"
            size="large"
          >
            {confirmBtnTxt || t('PLP.MYSIZES_DIALOG.CONFIRM_BUTTON')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
