import Environment from '@config/index';
import { axios, getAxiosConfig, ServerAxiosRequestData } from '@config/axios';
import { Deal } from '@interfaces/models/deal';

const BASE_URL = Environment.apiBaseUrl;

export const getActiveDeal = async (serverAxiosRequestData?: ServerAxiosRequestData): Promise<Deal> => {
  const axiosConfig = getAxiosConfig(serverAxiosRequestData);
  const {
    data: { data },
  } = await axios.get(`${BASE_URL}/deals/active`, axiosConfig);
  return data || [];
};
