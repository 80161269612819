import { useQuery } from '@tanstack/react-query';
import { ProductSalesCondition } from '@interfaces/models/product-sales-condition';
import { GlobalQueryKeys } from '@enums/react-query-keys';
import { usePreferences } from '@context/preferences.context';
import { getProductSalesCondition } from '@api/static';

const useProductSalesConditionQuery = () => {
  const { siteId, locale } = usePreferences();

  const { data: productSalesCondition } = useQuery<ProductSalesCondition>({
    queryKey: [GlobalQueryKeys.PRODUCT_SALES_CONDITION, siteId, locale],
    queryFn: () => getProductSalesCondition({ siteId, locale }),
    initialData: {
      categorySizeStandard: {},
      sizeStandard: 'US',
    },
    staleTime: Infinity,
  });

  return productSalesCondition;
};

export default useProductSalesConditionQuery;
