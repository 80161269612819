export function sendAppEvent(eventName: string, someMessage: string, shouldSendAndroidMessage = false) {
  if (shouldSendAndroidMessage) {
    // @ts-ignore
    window?.Android?.[eventName](someMessage);
  } else {
    // @ts-ignore
    window?.Android?.[eventName]();
  }

  // @ts-ignore
  window?.webkit?.messageHandlers?.[eventName]?.postMessage(someMessage);
}
