import React, { FCWithChildren, HTMLProps, ReactEventHandler } from 'react';
import clsx from 'clsx';
import { useAuthentication } from '@context/authentication.context';
import useUser from '@hooks/user/use-user';
import styles from './authentication-guard.module.scss';

/**
 * The onclick event handler passthrough. Allows us to still catch the event
 * for reuse later.
 */
type AuthenticationGuardProps = {
  hasClass?: boolean;
  shouldPreventDefault?: boolean;
  onClick?: ReactEventHandler<HTMLButtonElement>;
  onAuth?: () => void;
} & HTMLProps<HTMLSpanElement>;

const AuthenticationGuard: FCWithChildren<AuthenticationGuardProps> = (props) => {
  const { hasClass, children, onClick, shouldPreventDefault = false, onAuth, ...rest } = props;

  const { setAuthOpen, setOnAuth } = useAuthentication();
  const { isAuthenticated } = useUser();

  const checkAuth = (event) => {
    if (!isAuthenticated) {
      setAuthOpen(true);

      if (typeof onAuth !== 'undefined') {
        setOnAuth(() => onAuth);
      }

      if (shouldPreventDefault) {
        event.preventDefault();
      } else {
        event.stopPropagation();
      }

      // if the handler is defined, we call it.
      if (typeof onClick !== 'undefined') {
        onClick(event);
      }
    }
  };

  return (
    <span
      role="button"
      onClickCapture={checkAuth}
      className={clsx({ [styles.authContainer]: hasClass })}
      {...rest}
    >
      {children}
    </span>
  );
};

export default AuthenticationGuard;
