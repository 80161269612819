import React, { forwardRef, ReactElement, useCallback, useImperativeHandle, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import clsx from 'clsx';

import { WizardStepProps } from '@components/catalog/my-sizes/wizard-step/wizard-step';

import 'swiper/css/bundle';
import styles from './wizard.module.scss';

type WizardProps = {
  steps: ReactElement<WizardStepProps>[];
  onDone?: (response: unknown) => void;
  slideChangeCallback?: (activeIndex: number) => void;
} & React.HTMLAttributes<HTMLDivElement>;

type RefType = {
  prev: () => void;
  next: () => void;
};

const Wizard = forwardRef<RefType, WizardProps>((props, ref) => {
  const { className, steps, slideChangeCallback, ...rest } = props;

  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) {
      return;
    }
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) {
      return;
    }
    sliderRef.current.swiper.slideNext();
  }, []);

  useImperativeHandle(ref, () => ({ prev: handlePrev, next: handleNext }), []);

  return (
    <div
      className={clsx(styles.wizard, className)}
      {...rest}
    >
      <Swiper
        // @ts-ignore:next-line

        ref={sliderRef}
        slidesPerView={1}
        allowTouchMove={false}
        onSlideChange={(e) => slideChangeCallback(e.activeIndex)}
      >
        {steps.map((step) => (
          <SwiperSlide key={step.key}>{step}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});

Wizard.displayName = 'Wizard';

export default Wizard;
