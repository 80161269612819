import React, { useEffect, useState, createRef } from 'react';
import { useRouter } from 'next/router';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { AnalyticEvent } from '@interfaces/models/internal/analytics-event/product-event';
import Button from '@components/atoms/button/button';
import { useAuthentication } from '@context/authentication.context';
import VCImage from '@components/atoms/vc-images/vc-image';
import { useAnalytics } from '@context/analytics.context';
import Icon from '@components/atoms/icon/icon';
import { usePreferences } from '@context/preferences.context';
import { FAV_DEFAULT_SORT_ORDER } from '@api/favorites';
import dynamic from 'next/dynamic';
import Link from '@components/atoms/link/link';
import AuthenticationGuard from '@components/atoms/authentication/authentication-guard/authentication-guard';
import useUser from '@hooks/user/use-user';
import useNotificationCountQueryV2 from '@hooks/notifications-v2/use-notification-count-query-v2';
import useWindowSize from '@hooks/use-window-size';
import styles from './top-navigation.module.scss';

const NotificationsV2 = dynamic(() => import('@components/notifications-v2/notifications-v2'), {
  ssr: false,
});

const PreviewBox = dynamic(() => import('@components/atoms/preview-box/preview-box'), {
  ssr: false,
});

const ProfileAccess = dynamic(() => import('@components/molecules/profile-access/profile-access'), {
  ssr: false,
});

const TopNavigation: React.FC = () => {
  const { t } = useTranslation('default-layout');
  const { pathname, query } = useRouter();
  const { sendEvent } = useAnalytics();
  const { setAuthOpen } = useAuthentication();
  const { language } = usePreferences();
  const { sendAnalyticEvent: sendProductAnalyticEvent } = useAnalyticEvents('navigation');
  const { user, isAuthenticated } = useUser();
  const { isWiderThanLg } = useWindowSize();

  const { notificationCount: notificationCountToDisplay } = useNotificationCountQueryV2();

  const [isProfileBoxActive, setIsProfileBoxActive] = useState<boolean>(false);
  const [isNotificationsBoxActive, setIsNotificationsBoxActive] = useState<boolean>(false);

  const notificationsButtonRef = createRef<HTMLDivElement>();
  const profileButtonRef = createRef<HTMLDivElement>();

  const isOnFavoritePage = pathname.startsWith('/favorites');
  const favoritesLink = !!user?.id ? `/favorites/${user?.id}/?sortBy=${FAV_DEFAULT_SORT_ORDER}` : `/favorites/`;

  const sendAnalyticEvent = (event: AnalyticEvent) => {
    sendProductAnalyticEvent(event.value, event.data);
  };

  const authDialogHandler = (): void => {
    setAuthOpen(false);
  };

  const onNotificationsButtonClick = (): void => {
    setIsNotificationsBoxActive((prevState) => !prevState);
    sendProductAnalyticEvent('navigation_header', {
      label: 'notifications',
      value: '5',
    });
    sendEvent({
      type: 'event',
      payload: {
        event: 'headerClick',
        button_name: 'notifications',
      },
    });
  };

  // Open notification center based on query param (TCRM-3809)
  useEffect(() => {
    if (query.openNotificationCenter === 'true') {
      onNotificationsButtonClick();
    }
  }, [query.openNotificationCenter]);

  const onProfileButtonClick = (): void => {
    setIsProfileBoxActive((prevState) => !prevState);
    sendProductAnalyticEvent('navigation_header', {
      label: 'account',
      value: '3',
    });
    sendEvent({
      type: 'event',
      payload: {
        event: 'headerClick',
        button_name: 'me',
      },
    });
  };

  const getSellButtonLink = (): string => {
    switch (language) {
      case 'en':
        return '/sell-clothes-online.shtml';
      case 'fr':
        return '/vendre-ses-vetements.shtml';
      case 'de':
        return '/kleidung-verkaufen.shtml';
      case 'es':
        return '/vender-ropa-segunda-mano.shtml';
      case 'it':
        return '/vendita-online-abbigliamento.shtml';
      default:
        return '/sell-clothes-online.shtml';
    }
  };

  useEffect(() => {
    if (!isAuthenticated && isProfileBoxActive) {
      setIsProfileBoxActive(false);
    }
  }, [isAuthenticated, isProfileBoxActive]);

  const handleNotificationsBoxClose = (): void => {
    setIsNotificationsBoxActive(false);
  };

  return (
    <div className={clsx(styles.topNavigation, isAuthenticated && styles['topNavigation--isAuthenticated'])}>
      {/* Sell an item button */}
      <a
        tabIndex={0}
        href={getSellButtonLink()}
        data-cy="sell_an_item"
        data-vc-dd-action-name="sell_an_item"
        className={clsx(
          styles.topNavigation__button,
          styles['topNavigation__button--sellAnItem'],
          'vc-btn vc-btn--primary vc-btn--small',
        )}
        onClick={() =>
          sendAnalyticEvent({
            value: 'navigation_header',
            data: { label: 'sell', value: '2' },
          })
        }
      >
        {t('HEADER.SELL_AN_ITEM_BUTTON')}
      </a>

      {isAuthenticated && (
        <>
          {/* Profile button */}
          <div
            ref={profileButtonRef}
            className={styles.topNavigation__item}
          >
            <Button
              disableDefaultStyling
              className={styles.topNavigation__button}
              type="button"
              aria-labelledby="user-access-item-icon-title-profile"
              aria-haspopup="true"
              aria-expanded={isProfileBoxActive}
              onClick={onProfileButtonClick}
              data-cy="top-navigation-profile-button"
            >
              <span
                className="visuallyhidden"
                id="user-access-item-icon-title-profile"
              >
                {t('HEADER.NAVIGATION.NOTIFICATION_TITLE')}
              </span>

              <div
                className={styles.topNavigation__item}
                {...(user ? { 'data-cy': 'profile_img' } : {})}
              >
                <figure className={styles.topNavigation__image}>
                  <VCImage
                    src={`${user?.picture?.path || '/missing_avatar.gif'}`}
                    alt={user?.firstname?.slice(0, 1) || 'User profile photo'}
                    width={48}
                    height={48}
                  />
                </figure>
              </div>
            </Button>
            {isProfileBoxActive && (
              <PreviewBox
                isActive={isProfileBoxActive}
                closePreviewBox={() => setIsProfileBoxActive(false)}
                outsideClickRef={profileButtonRef}
              >
                <ProfileAccess />
              </PreviewBox>
            )}
          </div>

          {/* Favorites */}
          <div
            className={clsx(styles.topNavigation__button, styles['topNavigation__button--favorites'])}
            data-cy="top-navigation-favorites-button"
          >
            <AuthenticationGuard shouldPreventDefault={true}>
              <Link
                href={favoritesLink}
                onClick={() =>
                  sendProductAnalyticEvent('navigation_header', {
                    label: 'favourites',
                    value: '4',
                  })
                }
              >
                <Icon
                  className={styles.topNavigation__icon}
                  name={isOnFavoritePage ? 'heart-full' : 'heart-line'}
                />
                <span
                  className="visuallyhidden"
                  id="user-access-item-icon-title-favorite"
                >
                  {t('HEADER.NAVIGATION.FAVORITE_TITLE')}
                </span>
              </Link>
            </AuthenticationGuard>
          </div>

          {/* Notifications button */}
          <div
            ref={notificationsButtonRef}
            className={styles.topNavigation__item}
          >
            <Button
              disableDefaultStyling
              className={styles.topNavigation__button}
              type="button"
              aria-labelledby="user-access-item-icon-title-notifications"
              data-cy="topNavigation__item__icon__title--notif"
              aria-haspopup="true"
              aria-expanded={isNotificationsBoxActive}
              onClick={onNotificationsButtonClick}
            >
              <span
                className="visuallyhidden"
                data-cy="user_access_notification_title"
                id="user-access-item-icon-title-notifications"
              >
                {t('HEADER.NAVIGATION.NOTIFICATION_TITLE')}
              </span>

              {notificationCountToDisplay > 0 && (
                <span className={styles.topNavigation__count}>
                  {notificationCountToDisplay > 99 ? '99+' : notificationCountToDisplay}
                </span>
              )}

              <Icon
                className={styles.topNavigation__icon}
                name={isNotificationsBoxActive ? 'bell-full' : 'bell-line'}
              />
            </Button>
            {isNotificationsBoxActive && isWiderThanLg && (
              <PreviewBox
                isActive={isNotificationsBoxActive}
                title={t('HEADER.PREVIEW_BOX.NOTIFICATION_TITLE')}
                closePreviewBox={handleNotificationsBoxClose}
                outsideClickRef={notificationsButtonRef}
                className={isNotificationsBoxActive && 'notifications-box'}
                hideArrow={true}
              >
                <NotificationsV2 />
              </PreviewBox>
            )}
          </div>
        </>
      )}

      {!isAuthenticated && (
        <>
          {/* Login button */}
          <Button
            disableDefaultStyling
            id="user-login"
            data-cy="user-login"
            className={styles.topNavigation__button}
            onClick={() => authDialogHandler()}
          >
            {t('HEADER.CONNECT_BUTTON')}
          </Button>

          {/* Registration button */}
          <Button
            disableDefaultStyling
            id="user-register"
            data-cy="user-register"
            data-vc-dd-action-name="user_register"
            className={clsx(styles.topNavigation__button, styles['topNavigation__button--signUp'])}
            onClick={() => authDialogHandler()}
          >
            {t('HEADER.SUBSCRIBE_BUTTON')}
          </Button>
        </>
      )}
    </div>
  );
};

export default React.memo(TopNavigation);
