import Environment from '@config/index';
import imageQuality from '@config/image';

import { ImagesService } from '@services/image-service';
import { ImageComponent, IMAGE_SIZES, withImageProps } from '../vc-image';

/**
 * Size attribute based on a type :
 * - main: main picture on PDP
 * - thumbnail: on gallery preview
 * - snippet: for catalog pages
 */
const PRODUCT_IMAGE_SIZES = {
  main: `(max-width: ${IMAGE_SIZES.MOBILE}px) 480px,
  (max-width: ${IMAGE_SIZES.TABLET}px) 128px,
  (max-width: ${IMAGE_SIZES.DESKTOP}px) 256px,
  500px`,
  thumbnail: `64px`,
  snippet: `
  (min-resolution: 2dppx) 125px,
  (max-width: ${IMAGE_SIZES.MOBILE}px) 125px,
  (max-width: ${IMAGE_SIZES.TABLET}px) 190px,
  (max-width: ${IMAGE_SIZES.DESKTOP}px) 256px,
  256px`,
};

const getImageWidth = (width: number, sizesOverwrite?: number[]): number => {
  if (!sizesOverwrite || !sizesOverwrite.length) {
    return width;
  }
  let minDiff = Infinity;
  let closestWidth;
  for (let i = 0; i < sizesOverwrite.length; i++) {
    const diff = Math.abs(sizesOverwrite[i] - width);
    if (diff < minDiff) {
      minDiff = diff;
      closestWidth = sizesOverwrite[i];
    }
  }
  return closestWidth;
};

function productImageLoader({ src, width, quality }, sizesOverwrite): string {
  const imgWidth = getImageWidth(width, sizesOverwrite);
  const imageResizePrefix = ImagesService.getResizingPrefix(src);
  const url = `${Environment.imagesBaseUrl}${imageResizePrefix}/w=${imgWidth || 500},q=${
    quality || imageQuality
  },f=auto,${src}`;

  return url;
}

export default withImageProps(ImageComponent, productImageLoader, PRODUCT_IMAGE_SIZES);
