import React, { FCWithChildren } from 'react';
import ReactModal, { OnAfterOpenCallback } from 'react-modal';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import FocusTrap from 'focus-trap-react';
import Button from '@components/atoms/button/button';
import useWindowSize from '@hooks/use-window-size';
import parentStyles from './dialog.module.scss';

const defaultStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.32)',
    zIndex: 1000,
    maxWidth: '100%',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    position: 'absolute',
    borderRadius: '1px',
    overflowY: 'auto',
  },
};

export type DialogProps = {
  isOpen: boolean;
  closeDialog?: (arg: boolean) => void;
  hasCloseIcon?: boolean;
  onAfterOpen?: OnAfterOpenCallback;
  onAfterClose?: OnAfterOpenCallback;
  contentLabel?: string;
  styles?: ReactModal.Styles;
  focusTrapActive?: boolean;
  hasCloseTxt?: boolean;
  fullScreenOnMobile?: boolean;
  customClass?: string;
  dataCyIdentifierForContainer?: string;
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#root');

const Dialog: FCWithChildren<DialogProps> = (props): React.JSX.Element => {
  const {
    isOpen,
    onAfterOpen,
    onAfterClose,
    children,
    styles,
    hasCloseIcon,
    closeDialog,
    fullScreenOnMobile = true,
    focusTrapActive = true,
    hasCloseTxt = false,
    customClass,
    dataCyIdentifierForContainer = 'modal_inner_container',
  } = props;

  const { t } = useTranslation();
  const { isWiderThanMd } = useWindowSize();

  const combinedStyles: ReactModal.Styles = {
    overlay: {
      ...defaultStyles.overlay,
      ...styles?.overlay,
    },
    content: {
      ...defaultStyles.content,
      ...styles?.content,
    },
  };

  const onClose = () => {
    closeDialog(false);
  };

  return (
    <>
      {combinedStyles && (
        <FocusTrap active={isOpen && focusTrapActive}>
          <ReactModal
            isOpen={isOpen}
            onAfterOpen={onAfterOpen}
            onRequestClose={onClose}
            onAfterClose={onAfterClose}
            style={{
              ...combinedStyles,
              content: {
                ...combinedStyles.content,
                borderRadius: isWiderThanMd ? '1px' : 0,
              },
            }}
            className={clsx(
              parentStyles.popup,
              { [parentStyles['popup--mobileFullScreen']]: fullScreenOnMobile },
              customClass,
            )}
            ariaHideApp={false}
            closeTimeoutMS={300}
          >
            {hasCloseIcon ? (
              <div
                className={clsx('vc-modal__innerContainer', {
                  'vc-modal__innerContainer__fullScreenOnMobile': fullScreenOnMobile,
                })}
                data-cy={dataCyIdentifierForContainer}
              >
                <Button
                  disableDefaultStyling
                  aria-label={t('MODAL.BUTTON.CLOSE')}
                  className="vc-modal__closeBtn"
                  onClick={onClose}
                  tabIndex={0}
                  data-cy="close_modal_button"
                >
                  {hasCloseTxt && <span className={parentStyles.closeTxt}>{t('MODAL.BUTTON.CLOSE')}</span>}
                  <span className="vc-modal__closeBtn__crossLeft" />
                  <span className="vc-modal__closeBtn__crossRight" />
                </Button>
                {children}
              </div>
            ) : (
              children
            )}
          </ReactModal>
        </FocusTrap>
      )}
    </>
  );
};

export default Dialog;
