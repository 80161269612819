import Environment from '@config/index';
import { axios } from '@config/axios';
import { ElasticSearchResponse } from '@interfaces/api/responses/elasticSearch';
import { ElasticSearch } from '@interfaces/models/elasticSearch';
import { FacetSearchResponse } from '@interfaces/api/responses/facet-search-response';
import { FacetSearchPayload } from '@interfaces/api/payloads/facet-search-payload';
import { BrandSearchPayload } from '@interfaces/api/payloads/brand-search-payload';
import { BrandSearchResponse } from '@interfaces/api/responses/brand-search-response';
import { ApiResponse } from '@interfaces/api';
import { AxiosRequestHeaders } from 'axios';
import { SearchInnerFeedResponse } from '@interfaces/api/responses/search-inner-feed-response';

export enum ProductSearchHeaders {
  userToken = 'x-usertoken',
  searchQueryId = 'x-search-query-id',
  searchSessionId = 'x-search-session-id',
}

const ELASTIC_SEARCH_FIELDS = [
  'name',
  'description',
  'brand',
  'model',
  'country',
  'price',
  'discount',
  'link',
  'sold',
  'likes',
  'editorPicks',
  'shouldBeGone',
  'seller',
  'directShipping',
  'local',
  'pictures',
  'colors',
  'size',
  'stock',
  'universeId',
  'createdAt',
];

export default class SearchService {
  private static elasticSearchApiBasePath: string = Environment.apiProductSearchBaseUrl;

  static async brandSearch(payload?: Partial<BrandSearchPayload>): Promise<BrandSearchResponse> {
    const { data } = await axios.post<BrandSearchPayload, ApiResponse<BrandSearchResponse>>(
      `${SearchService.elasticSearchApiBasePath}/v1/autocomplete`,
      {
        limit: {
          brands: 5,
          suggestions: 5,
        },
        brands: false,
        suggestions: false,
        ...payload,
      },
    );
    return data;
  }

  static async productSearch(
    payload: Partial<ElasticSearch>,
    options?: {
      headers?: AxiosRequestHeaders;
      includeFacets?: boolean;
    },
  ): Promise<ElasticSearchResponse> {
    const { facets, fields, ...rest } = payload;
    const includeSubcategory: boolean = !!payload?.filters?.['categoryLvl0.id'];
    const includeSubSubcategory: boolean = !!payload?.filters?.['categoryLvl1.id'];
    const includeSubmaterials: boolean = !!payload?.filters?.['materialLvl0.id'];
    const includeModels: boolean = !!payload?.filters?.['brand.id'];

    const customConfig = options?.headers ? { headers: options.headers } : undefined;

    const { data } = await axios.post<ElasticSearch, ApiResponse<ElasticSearchResponse>>(
      `${SearchService.elasticSearchApiBasePath}/v1/product/search`,
      {
        pagination: {
          offset: 0,
          limit: 48,
        },
        fields: [...ELASTIC_SEARCH_FIELDS, ...(fields || [])],
        ...(options?.includeFacets && {
          facets: {
            fields: Array.from(
              new Set([
                ...[
                  'brand',
                  'universe',
                  'country',
                  'stock',
                  'color',
                  'categoryLvl0',
                  'priceRange',
                  'price',
                  'condition',
                  'region',
                  'editorPicks',
                  'watchMechanism',
                  'discount',
                  'sold',
                  'directShippingEligible',
                  'directShippingCountries',
                  'localCountries',
                  'sellerBadge',
                  'isOfficialStore',
                  'materialLvl0',
                  'size0',
                  'size1',
                  'size2',
                  'size3',
                  'size4',
                  'size5',
                  'size6',
                  'size7',
                  'size8',
                  'size9',
                  'size10',
                  'size11',
                  'size12',
                  'size13',
                  'size14',
                  'size15',
                  'size16',
                  'size17',
                  'size18',
                  'size19',
                  'size20',
                  'size21',
                  'size22',
                  'size23',
                ],
                ...(includeModels ? ['model'] : []),
                ...(includeSubcategory ? ['categoryLvl1'] : []),
                ...(includeSubSubcategory ? ['categoryLvl2'] : []),
                ...(includeSubmaterials ? ['materialLvl1'] : []),
                ...(!!facets?.fields ? facets.fields : []),
              ]),
            ),
            stats: ['price'],
          },
        }),
        ...rest,
      },
      customConfig,
    );

    return data;
  }

  static async facetSearch(payload: FacetSearchPayload): Promise<FacetSearchResponse> {
    const { data } = await axios.post<FacetSearchPayload, ApiResponse<FacetSearchResponse>>(
      `${SearchService.elasticSearchApiBasePath}/v1/product/search/facet`,
      {
        ...payload,
      },
    );

    return data;
  }

  static async plpInnerFeedSearch(
    payload: ElasticSearch,
    recentlyViewedProductIDs: string[],
    headers?: Partial<{ [key in ProductSearchHeaders]: string }>,
  ): Promise<SearchInnerFeedResponse> {
    const { data } = await axios.post<ElasticSearch, ApiResponse<SearchInnerFeedResponse>>(
      `${SearchService.elasticSearchApiBasePath}/v1/product/search/inner-feed`,
      {
        fields: ELASTIC_SEARCH_FIELDS,
        recentlyViewedProductIDs,
        ...payload,
      },
      {
        headers,
      },
    );

    return data;
  }
}
