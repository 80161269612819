import { useState } from 'react';
import ContentStackService from '@services/content-stack-service';
import { usePreferences } from '@context/preferences.context';
import { CMS_PAGES, ContentStackContainers, ContentStackPageNames } from '@enums/contentStack';
import logger from '@helpers/utils/logger/client';

interface ILivePreviewEvent {
  content_type_uid: string;
  hash: string;
}

interface ILivePreviewAckEvent {
  entryUid: string;
  contentTypeUid: string;
}

interface ILivePreviewEventData {
  data: ILivePreviewAckEvent | ILivePreviewEvent;
  from: string;
  type: string;
}

interface IEvent {
  data: ILivePreviewEventData;
}

const useContentStackLivepreview = (callBack: (p: unknown) => void) => {
  let entryId: string;
  const [isLivePreviewModeEnabled, setLivePreviewModeState] = useState<boolean>(false);
  const isProductionMode = process.env.REACT_APP_ENVIRONMENT === 'prod';
  const { locale, siteId, currency, language, country } = usePreferences();

  const getLivePreviewContent = async (contentType, hash) => {
    // filters are sent as query params in the URL
    const filters = window.location.search
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : undefined;

    try {
      const livepreviewResponse = await ContentStackService.getDataForCMSLivePreview({
        entryId,
        contentType,
        hash,
        locale: locale.toLocaleLowerCase(),
        filters,
        preferences: {
          country,
          siteId,
          currency,
          language,
          locale,
          userId: null,
        },
      });

      if (!livepreviewResponse) {
        return {};
      }

      if (CMS_PAGES.includes(livepreviewResponse?.contentTypeId)) {
        return livepreviewResponse;
      }

      return {
        [ContentStackContainers[contentType]]: livepreviewResponse,
        contentTypeId: ContentStackPageNames.LIVEPREVIEW_PAGE,
      };
    } catch (err) {
      logger.error(err, 'Error occurred at action: getLivePreviewContent - use-contentstack-livepreview.ts');
    }
  };

  const onEventListener = async (e: IEvent) => {
    const { from, type } = e?.data;

    if (from === 'live-preview') {
      if (type === 'init-ack') {
        const data = e?.data?.data as ILivePreviewAckEvent;
        entryId = data?.entryUid;
        if (!isLivePreviewModeEnabled) {
          setLivePreviewModeState(true);
        }
      } else {
        const { hash, content_type_uid: contentTypeUid } = e?.data?.data as ILivePreviewEvent;
        if (hash && type === 'client-data-send') {
          const previewData = await getLivePreviewContent(contentTypeUid, hash);
          callBack(previewData);
        }
      }
    }
  };

  const addLivePreviewEventListener = () => {
    if (isProductionMode) {
      return;
    }

    const cmsDomain = 'https://eu-app.contentstack.com';
    const parentLocation = document.referrer;
    if (!parentLocation || !parentLocation.includes(cmsDomain)) {
      return;
    }

    const currentOrigin = window?.location?.origin;
    const localOrigin = 'http://localhost:3000';
    const allowedDomain = 'vestiairecollective.com';

    if (currentOrigin === localOrigin || currentOrigin.endsWith(allowedDomain)) {
      window?.parent?.postMessage(
        {
          type: 'init',
          from: 'live-preview',
          data: {
            config: {
              shouldReload: false,
              href: window?.location?.href,
            },
          },
        },
        cmsDomain,
      );
      window?.addEventListener('message', (e) => onEventListener(e));
    } else {
      logger.error('CMS Live Preview Unauthorized Origin:::', currentOrigin);
    }
  };

  const removeLivePreviewEventListener = () => {
    if (isProductionMode) {
      return;
    }
    if (isLivePreviewModeEnabled) {
      setLivePreviewModeState(false);
      window?.removeEventListener('message', (e) => onEventListener(e));
    }
  };

  return {
    isLivePreviewModeEnabled,
    addLivePreviewEventListener,
    removeLivePreviewEventListener,
  };
};

export default useContentStackLivepreview;
