import React, { useEffect, useMemo } from 'react';
import Head, { DefaultAlternateVersions } from '@components/atoms/head/head';
import { useAnalytics } from '@context/analytics.context';
import { ContentsLocalizedPath } from '@interfaces/models/contentsLocalizedPath';
import { getPageMeta, ICMSTracking, IContentTypeEntries } from '@constants/metas/default';
import { CMS_LANDING_PAGES, ContentStackComponentKeys, ContentStackPageNames } from '@enums/contentStack';
import { usePreferences } from '@context/preferences.context';
import CMSPageContainer from './components/cms-page-containter';

const PAGE_VIEW_ANALYTICS = {
  [ContentStackPageNames.HOME_PAGE]: {
    screen_subcategory: 'homepage',
    screen_name: '/homepage',
    env_template: 'index_home',
  },
  [ContentStackPageNames.GLOBAL_HOME_PAGE]: {
    screen_subcategory: 'homepage',
    screen_name: '/homepage',
    env_template: 'index_home',
  },
  [ContentStackPageNames.EDITORIAL_PAGE]: { env_template: 'edito' },
};

const extractTracking = (trackingStr: string): string => {
  let url = '';
  if (trackingStr) {
    url = trackingStr.substring(trackingStr.indexOf('(') + 1, trackingStr.lastIndexOf(')')).trim();
  }

  // Set default value as 'Empty' instead of '' to avoid mobile side crash issue
  return url || 'Empty';
};

const isCMSLandingPage = (contentTypeId: ContentStackPageNames) => {
  return contentTypeId && CMS_LANDING_PAGES.includes(contentTypeId);
};

const getCMSScreenCategory = (contentTypeId: ContentStackPageNames) => {
  if (isCMSLandingPage(contentTypeId)) {
    return 'landing_' + contentTypeId;
  }

  return contentTypeId;
};

interface CMSPageProps {
  contentTypeEntries: IContentTypeEntries;
  alternateVersions?: ContentsLocalizedPath[];
}

const CMSPage: React.FC<CMSPageProps> = ({ contentTypeEntries, alternateVersions }): React.JSX.Element => {
  const { updateDataLayer } = useAnalytics();
  const { language } = usePreferences();

  const pageMeta = useMemo(() => {
    let metadata;
    const pageType = contentTypeEntries?.contentTypeId;

    if (CMS_LANDING_PAGES.includes(pageType)) {
      let descriptionBlock;
      const descriptionBlockKey = ContentStackComponentKeys.DESCRIPTION;

      if (pageType === ContentStackPageNames.CUSTOM_PAGE) {
        descriptionBlock = contentTypeEntries?.[ContentStackComponentKeys.MODULAR_BLOCK]?.find((modularBlock) => {
          return modularBlock[descriptionBlockKey];
        })?.[descriptionBlockKey]?.[descriptionBlockKey]?.[0];
      } else {
        descriptionBlock = contentTypeEntries?.[descriptionBlockKey]?.[0];
      }

      metadata = {
        title: contentTypeEntries?.displayTitle,
        description: descriptionBlock?.multi_line,
      };
    }

    if (pageType === ContentStackPageNames.EDITORIAL_PAGE) {
      metadata = {
        title: contentTypeEntries?.displayTitle,
        description: contentTypeEntries?.blockSubtitle,
      };
    }

    return getPageMeta(contentTypeEntries?.seo || {}, language, pageType, metadata);
  }, [contentTypeEntries]);

  useEffect(() => {
    if (!contentTypeEntries) {
      return null;
    }
    const { contentTypeId: contentTypeID, tracking } = contentTypeEntries || {};

    updateDataLayer({
      env_template: isCMSLandingPage(contentTypeID) ? 'channels' : contentTypeID,
      screen_name:
        ((tracking as ICMSTracking)?.tracking && extractTracking((tracking as ICMSTracking)?.tracking)) ||
        (tracking as string) ||
        'Empty',
      screen_category: getCMSScreenCategory(contentTypeID),
      screen_subcategory: '',
      ...(PAGE_VIEW_ANALYTICS?.[contentTypeEntries?.contentTypeId] || {}),
    });
  }, [contentTypeEntries, updateDataLayer]);

  return (
    <>
      <Head
        metadata={pageMeta}
        alternateVersions={alternateVersions || DefaultAlternateVersions}
      />
      <CMSPageContainer {...{ contentTypeEntries }} />
    </>
  );
};

export default CMSPage;
