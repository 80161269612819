import { useQuery, useQueryClient } from '@tanstack/react-query';
import { NotificationQueryKeys } from '@enums/react-query-keys';
import { NotificationFeedCounterResponseV2 } from '@interfaces/api/responses/notification-feed-counter-response';
import { getNotificationCountV2, resetNotificationsFeedCountersV2 } from '@api/notifications';
import useUser from '@hooks/user/use-user';
import logger from '@helpers/utils/logger/client';
import { useBucket } from '@context/bucket.context';

const DEFAULT_NOTIFICATION_FEED_COUNTERS = {
  // TODO: Remove this when new NC's implementation is rolled out 100%
  activity: 0,
  alerts: 0,
  all: 0,
  community: 0,

  // New
  messages: 0,
  updates: 0,
};

const useNotificationCountQueryV2 = () => {
  const queryClient = useQueryClient();
  const { user, isAuthenticated } = useUser();
  const { isFeatureEnabled } = useBucket();
  const notificationsV2Revamp = isFeatureEnabled((f) => !!f.notificationCenterPhaseTwoScreenRevamp, true);

  const { data: notificationFeedCounters, refetch: refetchNotificationCount } = useQuery<
    NotificationFeedCounterResponseV2['unseen']
  >({
    queryKey: [NotificationQueryKeys.NOTIFICATION_FEED_COUNTER_V2, user?.id],
    queryFn: () => getNotificationCountV2(),
    refetchInterval: 90000,
    refetchIntervalInBackground: !!notificationsV2Revamp,
    enabled: isAuthenticated && !!notificationsV2Revamp,
    initialData: DEFAULT_NOTIFICATION_FEED_COUNTERS,
  });

  // TODO: This count should be sum of (all + messages) from unseen object, once the notification migration has done, has to clean up this.
  const notificationCount: number = notificationFeedCounters.all;

  // Whenever notification popin closes, we need reset / update the feed counters
  const resetAndUpdateNotificationFeed = async (): Promise<void> => {
    try {
      await resetNotificationsFeedCountersV2();
      await refetchNotificationCount();
    } catch (error) {
      logger.error('Error during resetting notification feed counters:', error);
    }
  };

  // TODO: Remove when notificationsV2Revamp is fully enabled
  // Whenever notification popin opens, we need reset the feed counters back to 0
  const resetNotificationFeedCounters = () => {
    queryClient.setQueryData(
      [NotificationQueryKeys.NOTIFICATION_FEED_COUNTER, user?.id],
      () => DEFAULT_NOTIFICATION_FEED_COUNTERS,
    );
  };

  return {
    notificationFeedCounters,
    notificationCount,
    resetAndUpdateNotificationFeed,
    refetchNotificationCount,
    resetNotificationFeedCounters,
  };
};

export default useNotificationCountQueryV2;
