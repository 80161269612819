import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import { Scrollbar } from 'swiper';
import { Swiper, SwiperSlide, SwiperProps, SwiperSlideProps } from 'swiper/react';
import 'swiper/css/scrollbar';

import Button from '@components/atoms/button/button';
import ProductCard, { ProductCardProps } from '@components/organisms/catalog/components/product-card/product-card';
import { Product } from '@interfaces/models/product';

import styles from './product-slider.module.scss';

const swiperConfig = {
  '576': {
    slidesPerView: 2.2,
    spaceBetween: 18,
    centeredSlides: false,
  },
  '768': {
    slidesPerView: 3,
    spaceBetween: 18,
    centeredSlides: false,
  },
  '1024': {
    slidesPerView: 4,
    spaceBetween: 18,
    centeredSlides: false,
  },
};

type ProductSliderProps = {
  products: Array<Product>;
  swiperProps?: SwiperProps;
  swiperSlideProps?: SwiperSlideProps;
  productCardProps?: Omit<ProductCardProps, 'product' | 'index'>;
};

const ProductSlider: React.FC<ProductSliderProps> = ({
  products,
  swiperProps,
  swiperSlideProps,
  productCardProps,
}): React.JSX.Element => {
  const swiperRef = useRef(null);

  const swiperSlides = useMemo(() => {
    return products.map((product, index) => (
      <SwiperSlide
        key={product.id}
        className={styles.swiperSlide}
        {...swiperSlideProps}
      >
        <ProductCard
          customClass={styles['swiperSlide--item']}
          {...{ index, product }}
          {...productCardProps}
        />
      </SwiperSlide>
    ));
  }, [products, swiperSlideProps, productCardProps]);

  return (
    <div className={styles.swiperWrap}>
      <Swiper
        draggable
        centeredSlides
        centeredSlidesBounds
        onInit={(core) => {
          swiperRef.current = core.el;
        }}
        spaceBetween={12}
        slidesPerView={2.2}
        modules={[Scrollbar]}
        scrollbar={{ draggable: true }}
        breakpoints={swiperConfig}
        {...swiperProps}
      >
        {swiperSlides}
      </Swiper>

      <Button
        disableDefaultStyling
        className={clsx(styles.swiperButton, styles.prevButton)}
        onClick={() => swiperRef.current.swiper.slidePrev()}
      >
        {/* This span is used only to internationalize above button and is not shown on page */}
        <span hidden>click prev</span>
        <div className={clsx(styles.prevIcon, styles.controlsIcon)} />
      </Button>
      <Button
        disableDefaultStyling
        className={clsx(styles.swiperButton, styles.nextButton)}
        onClick={() => swiperRef.current.swiper.slideNext()}
      >
        {/* This span is used only to internationalize above button and is not shown on page */}
        <span hidden>click next</span>
        <div className={clsx(styles.nextIcon, styles.controlsIcon)} />
      </Button>
    </div>
  );
};

export default ProductSlider;
