import React from 'react';
import dayjs from 'dayjs';
import CMSImages from '@components/atoms/vc-images/components/cms-image';
import { PostBlock } from '@interfaces/models/EditoPost';
import sanitizer from '@helpers/sanitizer';
import styles from '../post-card.module.scss';

interface PostContentProps {
  blockImage: PostBlock['blockImage'];
  modularBlocks: PostBlock['modularBlocks'];
  title: PostBlock['displayTitle'];
  blockSubtitle?: PostBlock['blockSubtitle'];
  subTitle?: PostBlock['blockSubtitle'];
  date: PostBlock['date'];
  category: PostBlock['category'];
  categoryTitle: PostBlock['categoryTitle'];
  categoryUri: PostBlock['categoryUri'];
}

const PostContent = ({
  blockImage,
  modularBlocks,
  title,
  subTitle,
  blockSubtitle,
  date,
  category,
  categoryTitle,
}: PostContentProps) => {
  const { filename: imgXName, url: imgPath, title: imgTitle } = blockImage || {};
  const modularTextBlock = modularBlocks?.find((modularBlockType) => modularBlockType?.Text);
  const { textColor, backgroundColor: bgColor } = modularTextBlock?.Text || {};
  const postDate = date ? dayjs(date).format('MMM DD, YYYY') : null;
  const textStyle = textColor ? { color: textColor } : null;

  return (
    <>
      <figure>
        <CMSImages
          src={imgPath}
          wrapperProps={{ className: styles.post__img }}
          sizes="
          (min-width: 1200px) 327px,
          (min-width: 100px) 256px
          "
          srcSetOpts={[
            { width: '256', height: '213', descriptor: '320w' },
            { width: '327', height: '272', descriptor: '420w' },
            { width: '356', height: '296', descriptor: '780w' },
            { width: '1246', height: '910', descriptor: '1246w' },
          ]}
          alt={imgTitle}
          data-src={imgXName}
          width={256}
          height={213}
        />
      </figure>

      <span className={styles.post__tag}>{categoryTitle || category}</span>
      <div
        className={styles.post__text}
        style={{ backgroundColor: bgColor && bgColor }}
      >
        {title && (
          <h3
            className={styles.post__text__title}
            style={textStyle}
          >
            {title}
          </h3>
        )}

        {blockSubtitle ||
          (subTitle && (
            <div
              className={styles.post__text__intro}
              style={textStyle}
              dangerouslySetInnerHTML={{ __html: sanitizer(blockSubtitle || subTitle, {}, true) }}
            />
          ))}
      </div>

      <p
        className={styles.post__date}
        suppressHydrationWarning
      >
        {postDate}
      </p>
    </>
  );
};

export default PostContent;
