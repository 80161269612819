import React, { FC, MouseEvent, KeyboardEvent } from 'react';
import { CMSField as FieldProps, CMSFieldType as FieldType } from '@interfaces/models/cms/campaign-hero';
import Chip, { ChipFieldProps as ChipProps } from '@components/atoms/cms/chip/chip';
import useOnReveal from '@hooks/use-on-reveal';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';

import { generateCMSFieldStyles } from '@helpers/utils/cms/cms-field-styles';
import { useFieldStylesAndClasses } from '../cms-block-fields';
import styles from '../cms-block-fields.module.scss';

interface RevealChipsEventOnScrollProps {
  children: React.ReactNode;
  onReveal: () => void;
}

const RenderChipsContent: FC<{
  fieldType: FieldType;
  data: FieldProps['data'];
  sectionIdx: string;
  onClick?: (e: MouseEvent | KeyboardEvent, chip: ChipProps) => void;
}> = ({ fieldType, data, sectionIdx, onClick }): React.JSX.Element => {
  const { chips, fieldStyle } = data;
  const isHtml = fieldStyle.textType === 'html';
  const { fieldStyles, fieldClass } = useFieldStylesAndClasses(FieldType.Chips, data);

  const RevealChipsEventOnScroll: FC<RevealChipsEventOnScrollProps> = ({ children, onReveal }) => {
    const { containerRef } = useOnReveal(onReveal);
    return <span ref={containerRef}>{children}</span>;
  };

  const findChipIndexByProperties = (chipsArray: ChipProps[], searchObject: ChipProps) => {
    if (chipsArray.length) {
      return chipsArray.findIndex((chip) => Object.keys(searchObject).every((key) => chip[key] === searchObject[key]));
    }
    return -1;
  };

  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');

  const sendChipsViewEvent = (chip: ChipProps, chipIndex: number) => {
    const event = {
      category: 'cms_content_view',
      action: chip.contentTypeUid,
      label: chipIndex !== -1 ? `${String(chipIndex + 1)}_${sectionIdx}` : sectionIdx,
      property: `${chip.uid}_${chip.title}`,
    };
    sendAnalyticEvent('cms_content_view', event);
  };

  return (
    <div
      className={fieldClass}
      style={fieldStyles}
      data-cy={`${fieldType}-chip`}
    >
      <div className={styles['field--chipsField__container']}>
        {chips.map((chip) => (
          <RevealChipsEventOnScroll
            key={chip.uid}
            onReveal={() => sendChipsViewEvent(chip, findChipIndexByProperties(chips, chip))}
          >
            <Chip
              chip={chip}
              style={generateCMSFieldStyles(fieldType, chip)}
              className={styles['field--chipsField-chip']}
              onChipClick={(e: MouseEvent | KeyboardEvent) => onClick?.(e, chip)}
              dataCyIdentifier={`chipsField-chip-${chip.uid}`}
              isHtml={isHtml}
            />
          </RevealChipsEventOnScroll>
        ))}
      </div>
    </div>
  );
};

export const ChipsField: FC<
  FieldProps & {
    sectionIdx: string;
    onClick?: (e: MouseEvent | KeyboardEvent, chip: ChipProps) => void;
  }
> = ({ data, sectionIdx, onClick }): React.JSX.Element => {
  return (
    <RenderChipsContent
      data={data}
      fieldType={FieldType.Chips}
      sectionIdx={sectionIdx}
      onClick={onClick}
    />
  );
};
