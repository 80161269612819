import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useOnReveal from '@hooks/use-on-reveal';
import BannerContent from '@components/molecules/banner-content/banner-content';
import useAnalyticEvents from '@hooks/analytics/use-analytic-events';
import { ContentStackPageNames } from '@enums/contentStack';
import { BannerContent as IBannerContent } from '@interfaces/models/banner';
import styles from './banner.module.scss';

type BannerProps = {
  uid: string;
  contentTypeUid: string;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  banners: IBannerContent[];
  category?: {
    category?: string;
  };
  sectionIdx?: string;
  pageType?: ContentStackPageNames;
};

const Banner: React.FC<BannerProps> = ({
  uid,
  backgroundColor,
  banners,
  textColor,
  contentTypeUid: contentTypeUID,
  title: label,
  category,
  sectionIdx,
  pageType,
}): React.JSX.Element => {
  const isHomePage = [ContentStackPageNames.HOME_PAGE, ContentStackPageNames.GLOBAL_HOME_PAGE].includes(pageType);
  const { sendAnalyticEvent } = useAnalyticEvents('cms_component');
  const [activeContentIdx, setActiveContentIdx] = useState<number>(0);

  const bannerBlocks: IBannerContent[] = useMemo(() => {
    return isHomePage ? [...banners] : banners;
  }, [banners, isHomePage]);

  const activeBanner = bannerBlocks?.[activeContentIdx];
  const bannerBGColor = {
    backgroundColor: activeBanner?.backgroundColor || backgroundColor,
  };

  const { containerRef } = useOnReveal(
    useCallback(() => {
      sendAnalyticEvent('cms_content_view', {
        category: 'cms_content_view',
        action: contentTypeUID,
        property: `${uid}_${label}`,
        label: sectionIdx ?? '',
      });
    }, [contentTypeUID, label, category?.category, sectionIdx, uid, sendAnalyticEvent]),
    0.5,
    false,
  );

  /*
    For homepage and web-ds-ed, info banner should be in page header
    For talking-fashion page, info banner could be anywhere on the page except header
    Find doc here: https://docs.google.com/spreadsheets/d/1dG0r2OIUsNsXEljak27BBxqap0rZd2HMjkcS7fcYvE8/edit#gid=0
  */
  const bannerTop = pageType !== ContentStackPageNames.TALKING_FASHION_PAGE_WEB_PAGE;

  useEffect(() => {
    let timerId;

    if (bannerBlocks?.length > 1) {
      timerId = setInterval(() => {
        if (activeContentIdx + 1 >= bannerBlocks.length) {
          setActiveContentIdx(0);
        } else {
          setActiveContentIdx((prevIdx) => prevIdx + 1);
        }
      }, 5000);
    }

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  });

  const bannerContent = bannerBlocks?.map((bannerItem, idx) => {
    const { uid = '' } = bannerItem?._metadata || {};
    const elementKey = 'banner-content-' + uid + `-${idx}`;
    const isActiveBanner = idx === activeContentIdx;

    return (
      <BannerContent
        key={elementKey}
        {...bannerItem}
        {...{ isActiveBanner }}
        textColor={bannerItem?.textColor || textColor}
        sectionIdx={sectionIdx}
        idx={idx}
        contentTypeUID={contentTypeUID}
        category={category?.category}
      />
    );
  });

  if (!bannerContent?.length) {
    return null;
  }

  return (
    <div
      ref={containerRef}
      className={bannerTop ? 'vc-banner__container' : 'infoBanner'}
      style={bannerBGColor}
      id={`HP_${contentTypeUID}`}
    >
      <div className={styles.banner}>{bannerContent}</div>
    </div>
  );
};

export default Banner;
