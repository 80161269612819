import React, { useEffect, useMemo, useState } from 'react';
import { IContentTypeEntries } from '@constants/metas/default';

import { CMS_PAGE_SECTIONS } from '@enums/contentStack';
import useContentStackLivepreview from '@hooks/use-contentstack-livepreview';
import CMSContentContainer from './cms-content-container';

interface CMSPageContainerProps {
  contentTypeEntries: IContentTypeEntries;
  elementStartingPosition?: number | undefined;
}

const CMSPageContainer: React.FC<CMSPageContainerProps> = ({ contentTypeEntries }): React.JSX.Element => {
  const [contentStackEntry, setContentStackEntry] = useState(contentTypeEntries);
  const { isLivePreviewModeEnabled, addLivePreviewEventListener, removeLivePreviewEventListener } =
    useContentStackLivepreview(setContentStackEntry);

  useEffect(() => {
    addLivePreviewEventListener();
    return () => removeLivePreviewEventListener();
  }, []);

  const cmsPageContent = useMemo(() => {
    if (!contentStackEntry) {
      return null;
    }

    const pageType = contentStackEntry?.contentTypeId;
    const sections = CMS_PAGE_SECTIONS(isLivePreviewModeEnabled)?.[pageType];
    if (!sections?.length) {
      return null;
    }

    return (
      <div
        data-pageref={contentStackEntry?.id || contentStackEntry?.uid}
        data-contenttype={contentStackEntry?.contentTypeId}
        data-locale={`${contentStackEntry?.locale}`}
      >
        <CMSContentContainer
          contentTypeEntry={contentStackEntry}
          {...{ sections }}
        />
      </div>
    );
  }, [isLivePreviewModeEnabled, contentStackEntry]);

  return <>{cmsPageContent}</>;
};

export default CMSPageContainer;
