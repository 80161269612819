import { AvailableCurrencies } from '@interfaces/models/currency';
import { Language } from '@interfaces/models/language';
import { Picture } from '@interfaces/models/picture';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { User } from '@interfaces/models/user';
import { UserCivility } from '@interfaces/models/userCivility';
import { convertFromCents, formatPrice } from '@helpers/utils/price-formatter-helper';
import { ElasticSearchProduct } from '@interfaces/api/responses/elasticSearch';
import { Country } from '@interfaces/models/country';
import { ProductTag } from '@interfaces/models/product';
import { SimilarProduct } from '@interfaces/models/SimilarProduct';

type EditorPicks = {
  [k in ProductTag]?: boolean;
};

const formatTags = (editorPicks: EditorPicks): CatalogProduct['tags'] => {
  // exceptionalPieces tag should not be displayed on PLP (should only be accessible through filters)
  return Object.keys(editorPicks).filter((tagKey: string) => {
    if (tagKey === 'exceptionalPieces' || tagKey === 'hot') {
      return false;
    }
    return editorPicks[tagKey];
  }) as CatalogProduct['tags'];
};

const isNewProduct = (catalogHit: ElasticSearchProduct, lastSearchedDate?: number | null): boolean => {
  if (!lastSearchedDate || !catalogHit.createdAt) {
    return false;
  }
  return catalogHit.createdAt > lastSearchedDate;
};

const mapCatalogHitToProduct = (
  catalogHit: ElasticSearchProduct,
  country: string,
  currency: AvailableCurrencies,
  language: string,
  lastSearchedDate: number | null = null,
): CatalogProduct => {
  const formattedPrice = formatPrice(convertFromCents(catalogHit.price?.cents), currency, language);

  const formattedRegularPrice = catalogHit.discount?.originalPrice?.cents
    ? formatPrice(convertFromCents(catalogHit.discount?.originalPrice?.cents), currency, language)
    : null;

  // Seller mappings
  const civility: UserCivility = {
    name: 'mr',
    localizedName: 'mr',
    idGender: 1,
  };

  const defaultLanguage: Language = {
    name: 'en',
    localizedName: 'en',
    code: 'en',
  };

  const seller: User = {
    id: catalogHit?.seller?.id ? String(catalogHit.seller.id) : '0',
    type: 'user',
    firstname: catalogHit.seller?.firstname,
    civility,
    language: defaultLanguage,
    sellerRating: {
      badge: catalogHit.seller?.badge,
    },
    isOfficialStore: !!catalogHit.seller?.isOfficialStore,
  };

  // Flags mapping
  const flags = [];
  if (catalogHit.directShippingEligible) {
    flags.push('direct-shipping');
  }

  if (catalogHit.country === country) {
    flags.push('local-country');
  }

  if (catalogHit.editorPicks && catalogHit.editorPicks?.dealEligible) {
    flags.push('deal-eligible');
  }

  const sizeId = catalogHit?.size?.id;
  const sizeIdString = sizeId ? sizeId.toString() : '';

  return {
    name: catalogHit.name,
    id: String(catalogHit.id),
    price: {
      currency: currency as AvailableCurrencies,
      cents: catalogHit.price?.cents,
      formatted: formattedPrice,
    },
    discountPercentage: catalogHit.discount?.percent,
    type: 'product',
    pictures: [...(catalogHit?.pictures || [])].map<Picture>((path) => ({
      path,
      width: 400,
      height: 500,
    })),
    colors: catalogHit.colors,
    likeCount: catalogHit.likes,
    isoCountry: catalogHit.country as Country['isoCode'],
    path: catalogHit.link ?? `/${catalogHit.id}.shtml`, // some items have been incorrectly migrated, and are thus missing a link field [DSXP-460]
    brand: {
      name: catalogHit.brand?.name,
      localizedName: catalogHit.brand?.name,
      id: catalogHit.brand?.id ? String(catalogHit.brand?.id) : undefined,
      type: 'brand',
    },
    tags: formatTags(catalogHit.editorPicks ?? {}),
    sold: catalogHit.sold,
    inStock: !!catalogHit.stock,
    shouldBeGone: !!catalogHit.shouldBeGone,
    flags,
    size: {
      id: sizeIdString,
      type: 'size',
      size: catalogHit?.size?.label,
    },
    seller,
    regularPrice: {
      currency: currency,
      cents: catalogHit.discount?.originalPrice?.cents,
      formatted: formattedRegularPrice,
    },
    personalized: false,
    universeId: catalogHit.universeId,
    isNewProduct: isNewProduct(catalogHit, lastSearchedDate),
    hasDutiesApplied: catalogHit.dutyFree === false,
  };
};

export const mapSimilarProductToProduct = (similarProduct: SimilarProduct): CatalogProduct => {
  return {
    name: similarProduct.name,
    id: similarProduct.id,
    price: similarProduct.price,
    type: 'product',
    pictures: similarProduct.pictures,
    likeCount: similarProduct.likeCount,
    isoCountry: similarProduct.country,
    path: similarProduct.path ?? `/${similarProduct.id}.shtml`, // some items have been incorrectly migrated, and are thus missing a link field [DSXP-460]
    brand: similarProduct.brand,
    tags: similarProduct.tags,
    sold: similarProduct.sold,
    inStock: similarProduct.inStock,
    shouldBeGone: similarProduct.shouldBeGone,
    flags: [],
    size: similarProduct?.size ?? {
      id: '',
      type: 'size',
      size: '',
    },
    regularPrice: similarProduct.regularPrice,
    personalized: false,
    isNewProduct: false,
    hasDutiesApplied: false,
  };
};

export default mapCatalogHitToProduct;
