import React from 'react';
import CatalogProductFeed from '@components/catalog/catalog-product-feed/catalog-product-feed';
import { CatalogProduct } from '@interfaces/models/catalogProduct';
import { Product } from '@interfaces/models/product';

type CatalogListProps = {
  containerClsx?: React.CSSProperties | string;
  productType?: string;
  isLoading: boolean;
  products: Array<Product | CatalogProduct>;
};

const CatalogList: React.FC<CatalogListProps> = (props): React.JSX.Element => {
  const { containerClsx, productType, products, isLoading } = props;

  return (
    <CatalogProductFeed
      {...{ products, containerClsx, isLoading }}
      productCardProps={{
        pageType: 'cms_page',
        moduleType: productType,
      }}
    />
  );
};

export default CatalogList;
