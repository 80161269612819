import React from 'react';
import ReactPlayer, { FilePlayerProps } from 'react-player/file';

const VideoPlayer: React.FC<FilePlayerProps> = ({ config, url, ...rest }) => {
  if (!url) {
    return null;
  }

  return (
    <ReactPlayer
      width="100%"
      height="100%"
      {...{ url }}
      controls
      {...rest}
      config={{ ...(config || {}), forceVideo: true }}
    />
  );
};

export default VideoPlayer;
