import { useQuery } from '@tanstack/react-query';
import { GlobalQueryKeys } from '@enums/react-query-keys';

const useInitialRequestUrlQuery = () => {
  const { data: initialRequestUrl } = useQuery<string, Error, URL>({
    queryKey: [GlobalQueryKeys.INITIAL_REQUEST_URL],
    staleTime: Infinity,
    select: (url: string) => new URL(url),
  });

  return {
    initialRequestUrl,
  };
};

export default useInitialRequestUrlQuery;
