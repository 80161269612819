import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { useQuery } from '@tanstack/react-query';
import { GlobalQueryKeys } from '@enums/react-query-keys';
import { Guide, SizeGuide as ISizeGuide, SizeGuideCategory, SizeGuideStandard } from '@interfaces/models/sizeGuide';
import { Product } from '@interfaces/models/product';
import { usePreferences } from '@context/preferences.context';
import { getSizeGuide } from '@api/static';
import styles from './size-guide.module.scss';

export type ParsedSizeGuide = {
  title: string;
  parsedSizeGuide: Guide[];
};

type SizeGuideProps = {
  universeId: Product['universe']['id'];
  productCategoryId: Product['category']['id'];
  sizeGuideStandard: Product['size']['standard'];
  sizeGuideSize?: Product['size']['size'];
  onTitleChange?: (title: string) => void;
};

const parseSizeGuide = (
  sizeGuideData: ISizeGuide,
  universeId: Product['universe']['id'],
  productCategoryId: Product['category']['id'],
) => {
  const sizeGuide: ParsedSizeGuide = {
    title: '',
    parsedSizeGuide: [],
  };

  if (!sizeGuideData) {
    return sizeGuide;
  }

  const allCategories =
    !universeId && productCategoryId ? Object.values(sizeGuideData)?.flatMap((universe) => universe.categories) : [];

  const categories: SizeGuideCategory[] = universeId ? sizeGuideData[universeId]?.categories : allCategories;

  categories.forEach((category: SizeGuideCategory) => {
    if (category.sizeType === productCategoryId || category.content.idCategory.includes(Number(productCategoryId))) {
      sizeGuide.parsedSizeGuide = category.content.guide;
      sizeGuide.title = category.content.title;
    }
  });

  return sizeGuide;
};

const SizeGuide: React.FC<SizeGuideProps> = (props): React.JSX.Element => {
  const { universeId, productCategoryId, sizeGuideStandard, sizeGuideSize, onTitleChange } = props;

  const { t } = useTranslation();
  const { siteId, locale } = usePreferences();

  const { data: sizeGuide } = useQuery<ISizeGuide>({
    queryKey: [GlobalQueryKeys.SIZE_GUIDE, siteId, locale],
    queryFn: () => getSizeGuide({ siteId, locale }),
  });

  const { title, parsedSizeGuide } = parseSizeGuide(sizeGuide, universeId, productCategoryId);
  const sizeGuideStandards: SizeGuideStandard[] = Object.keys(parsedSizeGuide[0] ?? []) as SizeGuideStandard[];

  useEffect(() => {
    onTitleChange?.(title);
  }, [title]);

  return (
    <>
      <div className={styles.sizeGuide__description}>{t('SIZE_GUIDE.POPIN.SG_POPIN_DESCRIPTION')}</div>
      {parsedSizeGuide.length > 0 && (
        <div className={styles.sizeGuide__table}>
          <div className={clsx('header', styles.sizeGuide__table__row)}>
            {sizeGuideStandards.map((sizeGuideStandard: SizeGuideStandard) => (
              <div
                key={sizeGuideStandard}
                className={clsx('header', styles.sizeGuide__table__cell)}
              >
                {sizeGuideStandard}
              </div>
            ))}
          </div>
          {/* Loop through all the rows */}
          {parsedSizeGuide.map((row: Guide, rowIndex: number) => (
            <div
              // eslint-disable-next-line react/no-array-index-key -- there's no other key can be used here
              key={rowIndex}
              className={clsx(
                styles.sizeGuide__table__row,
                sizeGuideSize && sizeGuideSize === row[sizeGuideStandard] && styles['highlight-row'],
              )}
            >
              {/* Loop through all the columns for each row */}
              {sizeGuideStandards.map((currentStandard: SizeGuideStandard) => (
                <div
                  key={`${sizeGuideStandard}-${currentStandard}`}
                  className={clsx(
                    styles.sizeGuide__table__cell,
                    sizeGuideStandard === currentStandard &&
                      sizeGuideSize === row[currentStandard] &&
                      styles['highlight-cell'],
                  )}
                >
                  {row[currentStandard]}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SizeGuide;
